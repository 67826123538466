import React from 'react';
import { FileText, Info, AlertCircle, CheckCircle, Building2, Receipt, Calculator, Clock, FileCheck, BarChart2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const EInvoicingGuideNL: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>E-Invoicing Guide for Dutch Businesses - Hermes BI</title>
        <meta name="description" content="Comprehensive guide to e-invoicing in the Netherlands. Learn about requirements, implementation, and upcoming changes for small businesses, ZZP'ers, and accountants." />
        <meta name="keywords" content="e-invoicing Netherlands, e-factuur, electronic invoicing, PEPPOL, UBL-OHNL, Dutch business, ZZP, accountant" />
        <meta property="og:title" content="E-Invoicing Guide for Dutch Businesses" />
        <meta property="og:description" content="Comprehensive guide to e-invoicing in the Netherlands" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Article Header */}
        <header className="pt-24 pb-12 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between mb-4">
              {/* Create Invoice button */}
              <Link 
                to="/invoice_processor/invoice-generator" 
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                <FileText className="h-4 w-4 mr-2" />
                Create Invoice
              </Link>
              {/* Language toggle */}
              <div className="flex items-center">
                <Link 
                  to="/invoice_processor/e-facturatie-gids-nl" 
                  className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  Nederlands
                </Link>
                <span className="mx-2 text-gray-400">|</span>
                <span className="text-gray-600">English</span>
              </div>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              E-Invoicing in the Netherlands: A Guide for Small Businesses
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              Everything you need to know about electronic invoicing, from requirements to implementation
            </p>
          </div>
        </header>

        {/* Quick Overview Cards */}
        <div className="bg-white dark:bg-gray-900 py-8 border-b border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                {
                  icon: Clock,
                  text: "Mandatory by 2028",
                  subtext: "For all B2B transactions",
                  animation: "animate-float-1"
                },
                {
                  icon: Building2,
                  text: "10% Adoption",
                  subtext: "Current SME usage",
                  animation: "animate-float-2"
                },
                {
                  icon: FileCheck,
                  text: "PEPPOL Network",
                  subtext: "Standard platform",
                  animation: "animate-float-3"
                },
                {
                  icon: BarChart2,
                  text: "Real-time by 2030",
                  subtext: "Tax reporting",
                  animation: "animate-float-4"
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`flex flex-col items-center ${item.animation}`}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-400/20 rounded-full blur-xl transform scale-150 animate-pulse" />
                    <div className="relative bg-white dark:bg-gray-800 p-4 rounded-full border border-gray-200 dark:border-gray-700 shadow-lg">
                      <item.icon className="h-8 w-8 text-blue-500 dark:text-blue-400" />
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    <span className="block font-medium text-gray-900 dark:text-white">
                      {item.text}
                    </span>
                    <span className="text-xs text-gray-600 dark:text-gray-300">
                      {item.subtext}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Introduction */}
          <section className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none mb-12">
            <p className="lead">
              As the business landscape evolves, the Netherlands is at the forefront of digital transformation in financial processes. 
              For small business owners, ZZP'ers, and accountants, e-invoicing represents both a challenge and an opportunity. 
              With mandatory requirements for government suppliers already in place and new changes coming in 2025 and 2028, 
              understanding e-invoicing is no longer optional—it's essential for future-proofing your business.
            </p>
            <p>
              In this comprehensive guide, we'll walk you through everything you need to know about e-invoicing: what it is, 
              whether your business needs it, how to get started, and how to prepare for upcoming changes. We'll help you make 
              informed decisions about implementing e-invoicing in your business operations.
            </p>
          </section>

          {/* Table of Contents */}
          <nav className="mb-12 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Contents</h2>
            <ul className="space-y-2">
              <li>
                <a href="#what-is-e-invoicing" className="text-blue-600 dark:text-blue-400 hover:underline">
                  1. What is E-Invoicing?
                </a>
              </li>
              <li>
                <a href="#do-i-need-it" className="text-blue-600 dark:text-blue-400 hover:underline">
                  2. Do I Need E-Invoicing?
                </a>
              </li>
              <li>
                <a href="#getting-started" className="text-blue-600 dark:text-blue-400 hover:underline">
                  3. Getting Started with E-Invoicing
                </a>
              </li>
              <li>
                <a href="#software-requirements" className="text-blue-600 dark:text-blue-400 hover:underline">
                  4. Software Requirements
                </a>
              </li>
              <li>
                <a href="#upcoming-changes" className="text-blue-600 dark:text-blue-400 hover:underline">
                  5. Upcoming Changes (2025 and Beyond)
                </a>
              </li>
            </ul>
          </nav>

          {/* What is E-Invoicing Section */}
          <section id="what-is-e-invoicing" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">1. What is E-Invoicing?</h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
              <p>
                E-invoicing goes far beyond simply sending a PDF invoice via email. It's a comprehensive digital process that transforms 
                how businesses handle their entire invoicing workflow. At its core, an e-invoice is a structured data file that contains 
                all invoice information in a standardized format, allowing for automatic processing by accounting systems without any 
                manual data entry.
              </p>
              
              <p>
                Think of it as the difference between sending a letter and using instant messaging. While both communicate information, 
                instant messaging allows for immediate processing, automated responses, and integration with other digital systems. Similarly, 
                e-invoicing enables your invoices to be automatically processed, validated, and recorded in accounting systems.
              </p>
              
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6 my-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Key Features</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium text-gray-900 dark:text-white">Structured Format</span>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Uses standardized formats like PEPPOL BIS and UBL-OHNL, ensuring consistency and compatibility across different systems. 
                        These formats are like a universal language that all e-invoicing systems can understand and process.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium text-gray-900 dark:text-white">Automated Processing</span>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Direct integration with accounting systems means invoices are automatically recorded, matched with purchase orders, 
                        and prepared for payment. This automation significantly reduces processing time and eliminates manual data entry errors.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium text-gray-900 dark:text-white">Secure Transmission</span>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Sent through secure networks like PEPPOL, ensuring data integrity and confidentiality. Unlike email attachments, 
                        e-invoices can't be tampered with during transmission and provide a clear audit trail.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 my-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">The Difference from Digital Invoices</h3>
                <p className="text-gray-600 dark:text-gray-100 mb-4">
                  It's important to understand that not all digital invoices are e-invoices. Here's how they differ:
                </p>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="bg-white dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Digital Invoice (e.g., PDF)</h4>
                    <ul className="text-sm text-gray-600 dark:text-gray-300 space-y-2">
                      <li>• Static document format</li>
                      <li>• Requires manual processing</li>
                      <li>• Can be altered after creation</li>
                      <li>• Limited automation possibilities</li>
                    </ul>
                  </div>
                  <div className="bg-white dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">E-Invoice</h4>
                    <ul className="text-sm text-gray-600 dark:text-gray-300 space-y-2">
                      <li>• Structured data format</li>
                      <li>• Automatic processing</li>
                      <li>• Tamper-proof</li>
                      <li>• Full automation support</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Do I Need E-Invoicing Section */}
          <section id="do-i-need-it" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">2. Do I Need E-Invoicing?</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p>
                  The answer to whether you need e-invoicing depends on your business type and clients. Let's break it down to help 
                  you make an informed decision:
                </p>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Mandatory Cases
                  </h3>
                  <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-100">
                      You <strong>must</strong> implement e-invoicing if:
                    </p>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-red-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Government Suppliers</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            If you supply goods or services to Dutch central government agencies, e-invoicing has been mandatory since 2017. 
                            This includes ministries, departments, and agencies.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-red-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Local Government</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            Many municipalities and local government entities now require e-invoices. Check with your specific government 
                            clients for their requirements.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-red-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Future Requirements (2028)</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            All businesses engaging in cross-border B2B transactions within the EU will need to use e-invoicing by 2028.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Optional But Beneficial
                  </h3>
                  <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-100">
                      You should <strong>consider</strong> e-invoicing if:
                    </p>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">B2B Transactions</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            While currently voluntary for domestic B2B transactions, e-invoicing can significantly streamline your operations 
                            and reduce costs. Many larger businesses prefer or require e-invoices from their suppliers.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">International Business</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            If you do business across borders, especially within the EU, implementing e-invoicing now will prepare you for 
                            future requirements and make international transactions smoother.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">High Invoice Volume</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            If you process many invoices monthly, e-invoicing can significantly reduce processing time and costs.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg mt-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Making Your Decision</h3>
                <div className="space-y-4">
                  <p className="text-gray-600 dark:text-gray-100">
                    Consider these factors when deciding whether to implement e-invoicing now:
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Business Growth</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          If you're planning to expand, especially internationally or with government contracts, implementing e-invoicing now 
                          will prevent hurdles later.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Cost-Benefit Analysis</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          While there are initial setup costs, e-invoicing typically pays for itself through reduced processing time, 
                          fewer errors, and faster payments.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Client Requirements</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Survey your major clients about their e-invoicing plans. If key clients are moving towards e-invoicing, 
                          it's wise to align with their timeline.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="mt-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">Our Recommendation</h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Given the upcoming mandates and the clear benefits, we recommend that most businesses start planning their e-invoicing 
                    implementation now, even if not immediately required. This allows for a smoother transition and ensures you're not 
                    rushed when it becomes mandatory. Start with a small pilot project to test the waters and gradually expand based on 
                    your experience.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Getting Started Section */}
          <section id="getting-started" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">3. Getting Started with E-Invoicing</h2>
            <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Implementation Steps</h3>
              <ol className="space-y-4">
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">1</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Choose Software</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Select e-invoicing software that suits your business needs</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">2</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Connect to PEPPOL</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Set up connection with a PEPPOL access point provider</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">3</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Test the System</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Verify everything works correctly with test transactions</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">4</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Go Live</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Start sending e-invoices to your customers</p>
                  </div>
                </li>
              </ol>
            </div>
          </section>

          {/* Software Requirements Section */}
          <section id="software-requirements" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">4. Software Requirements</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p>
                  To implement e-invoicing in your business, you'll need software that complies with Dutch and European 
                  e-invoicing standards. The exact combination of tools will depend on your business needs, but here's 
                  a comprehensive overview of the different types of software you might need:
                </p>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Core Software Types</h3>
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">E-Invoicing Software</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Accounting or dedicated e-invoicing software that supports standardized formats like PEPPOL BIS 3.0, 
                          UBL-OHNL, or SETU OHNL. Look for solutions that integrate with your existing accounting system.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">PEPPOL Access Point</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Software or service that connects you to the PEPPOL network. Essential for B2G transactions 
                          and increasingly important for B2B transactions.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Government Platforms</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          For B2G transactions, you'll need access to either Digipoort (for high volume) or the 
                          Government's Supplier Portal (for low volume).
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Supporting Tools</h3>
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">XML/EDI Tools</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Tools for converting invoices to standardized XML formats. These might be standalone or 
                          integrated into your e-invoicing software.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Archiving Solution</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Secure storage system for maintaining e-invoices for the required 7-year period 
                          (10 years for real estate transactions).
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Choosing the Right Solution</h3>
                <div className="space-y-4">
                  <p className="text-gray-600 dark:text-gray-100">
                    When selecting e-invoicing software, consider these key factors:
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Business Size and Volume</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Small businesses might prefer all-in-one solutions, while larger organizations may need 
                          more specialized tools. Consider your monthly invoice volume when choosing.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Integration Requirements</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Ensure the solution integrates with your existing accounting software and other business systems.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Standards Compliance</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Verify that the software supports all required formats (PEPPOL BIS 3.0, UBL-OHNL) and 
                          complies with Dutch and EU regulations.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Scalability</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Choose a solution that can grow with your business and adapt to future requirements.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="mt-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">Starting Small</h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    For ZZP'ers and small businesses just starting with e-invoicing, many accounting software providers 
                    offer basic e-invoicing capabilities as part of their standard packages. Start with these basic 
                    solutions and upgrade as your needs grow. Many providers offer free trial periods to help you 
                    evaluate their services.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Upcoming Changes Section */}
          <section id="upcoming-changes" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">5. Upcoming Changes (2025 and Beyond)</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p>
                  The European Union is working towards a standardized and digitalized VAT system, with e-invoicing playing 
                  a central role. The Netherlands will undergo significant changes in e-invoicing over the coming years, 
                  primarily driven by the EU's VAT in the Digital Age (ViDA) initiative.
                </p>
              </div>

              <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <Clock className="h-6 w-6 text-yellow-500 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Key Milestones</h3>
                </div>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="font-bold text-yellow-800 dark:text-yellow-200 mr-2">2028:</span>
                    <div className="text-gray-600 dark:text-gray-300">
                      <p>• Mandatory e-invoicing for all B2B transactions in the EU</p>
                      <p>• Only structured e-invoices (PEPPOL BIS 3.0 or UBL-OHNL formats) will be accepted</p>
                      <p>• Full integration with PEPPOL network required</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="font-bold text-yellow-800 dark:text-yellow-200 mr-2">2030:</span>
                    <div className="text-gray-600 dark:text-gray-300">
                      <p>• Introduction of Digital Reporting Requirements (DRR)</p>
                      <p>• Real-time invoice data reporting to tax authorities</p>
                      <p>• Enhanced fraud prevention measures</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="font-bold text-yellow-800 dark:text-yellow-200 mr-2">2035:</span>
                    <div className="text-gray-600 dark:text-gray-300">
                      <p>• Full harmonization of national e-invoicing systems with EU standards</p>
                      <p>• Complete integration of all e-invoicing platforms</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Major Changes</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">B2B E-Invoicing Mandate</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Currently optional for B2B transactions, but will become mandatory by 2028, requiring all businesses 
                          to use structured e-invoices.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Digital Reporting</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Real-time reporting of invoice data to tax authorities will be required from 2030, 
                          necessitating significant IT system updates.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">PEPPOL Network</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          PEPPOL will become the standard network for e-invoicing, requiring all businesses to connect 
                          through certified access points.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Current State</h3>
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white mb-2">Adoption Rate</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Only 10% of SMEs have fully embraced e-invoicing, with most still using PDF invoices. This indicates 
                        a significant need for businesses to start preparing for the upcoming changes.
                      </p>
                    </div>
                    <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
                      <h4 className="font-medium text-gray-900 dark:text-white mb-2">Why Act Now?</h4>
                      <ul className="text-sm text-gray-600 dark:text-gray-100 space-y-2">
                        <li>• Avoid last-minute rush and compliance issues</li>
                        <li>• Take advantage of early adoption benefits</li>
                        <li>• Spread implementation costs over time</li>
                        <li>• Gain experience with the system</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">How to Prepare</h3>
                <div className="space-y-4">
                  <p className="text-gray-600 dark:text-gray-100">
                    Given that most Dutch businesses are not yet ready for these changes, here's what you should do:
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Assess Your Systems</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Review your current invoicing process and identify gaps in meeting future requirements.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Plan Your Implementation</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Create a timeline for adopting e-invoicing solutions and connecting to the PEPPOL network.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Budget for Changes</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Allocate resources for software upgrades, training, and potential system integration costs.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Start Small</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Begin with a pilot program to test e-invoicing with a few key business partners before full implementation.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Additional Resources */}
          <section className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Additional Resources
            </h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">
              For more detailed information about e-invoicing requirements and implementation in the Netherlands, 
              consult these official government resources:
            </p>
            <ul className="space-y-4">
              <li>
                <a 
                  href="https://www.government.nl/topics/e-invoicing"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <div>
                    <span className="font-medium">Dutch Government E-Invoicing Guide</span>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Official government information about e-invoicing requirements, implementation methods, and support services. 
                      Includes details about the central government e-invoicing help desk and various submission methods.
                    </p>
                  </div>
                </a>
              </li>
              <li>
                <a 
                  href="https://business.gov.nl/regulation/invoice-requirements/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <div>
                    <span className="font-medium">Business.gov.nl - Invoice Requirements</span>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Comprehensive overview of Dutch invoice requirements, including legal requirements, retention periods, 
                      and specific rules for electronic and digital invoicing. Essential reading for all business owners.
                    </p>
                  </div>
                </a>
              </li>
            </ul>

            
          </section>

          {/* Add a floating "Back to top" button */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="fixed bottom-8 right-8 bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-200"
            aria-label="Back to top"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 10l7-7m0 0l7 7m-7-7v18" 
              />
            </svg>
          </button>
        </main>

        {/* Footer */}
        <footer className="bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              This guide is provided for informational purposes only and should not be considered as legal advice. 
              Always consult with a qualified tax advisor or accountant for specific guidance.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default EInvoicingGuideNL; 