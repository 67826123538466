import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../utils/database';
import { Session } from '@supabase/supabase-js';

interface User {
  email: string;
  name: string;
  picture: string;
  sub: string;
}

interface AuthContextType {
  user: User | null;
  session: Session | null;
  isLoading: boolean;
  login: (response: any) => Promise<void>;
  logout: () => Promise<void>;
  deleteAccount: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let mounted = true;

    // Get initial session
    const initializeAuth = async () => {
      try {
        console.log('Initializing auth...');
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Error getting session:', error);
          return;
        }

        console.log('Got session:', session);
        
        if (mounted && session) {
          setSession(session);
          const userData: User = {
            email: session.user.email || '',
            name: session.user.user_metadata.full_name || '',
            picture: session.user.user_metadata.avatar_url || '',
            sub: session.user.id,
          };
          setUser(userData);
          localStorage.setItem('user', JSON.stringify(userData));
        }
      } catch (error) {
        console.error('Error in initializeAuth:', error);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    initializeAuth();

    // Set up auth state change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session?.user?.id);

      if (mounted) {
        if (session) {
          try {
            const userData: User = {
              email: session.user.email || '',
              name: session.user.user_metadata.full_name || '',
              picture: session.user.user_metadata.avatar_url || '',
              sub: session.user.id
            };
            setUser(userData);
            setSession(session);
            localStorage.setItem('user', JSON.stringify(userData));
          } catch (error) {
            console.error('Error handling auth state change:', error);
          }
        } else {
          setUser(null);
          setSession(null);
          localStorage.removeItem('user');
        }
      }
    });

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    if (session?.expires_at) {
      const timeUntilExpiry = (session.expires_at * 1000) - Date.now();
      const refreshBuffer = 5 * 60 * 1000; // 5 minutes before expiry
      
      if (timeUntilExpiry < refreshBuffer) {
        supabase.auth.refreshSession().then(({ data, error }) => {
          if (error) {
            console.error('Error refreshing session:', error);
            return;
          }
          if (data.session) {
            setSession(data.session);
          }
        });
      }
    }
  }, [session]);

  const handleSignOut = async () => {
    try {
      // Clear local state first
      setUser(null);
      setSession(null);
      localStorage.removeItem('user');
      localStorage.removeItem('redirectTo');

      // Then attempt to sign out from Supabase
      try {
        await supabase.auth.signOut();
      } catch (signOutError) {
        console.warn('Non-critical error during sign out:', signOutError);
        // Continue even if sign out fails - we've already cleared local state
      }

      // Always navigate home
      navigate('/');
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  const login = async (response: any) => {
    try {
      console.log('Starting Google OAuth login...');
      
      // Store current location for redirect after login
      const currentPath = location.pathname;
      if (currentPath !== '/login') {
        localStorage.setItem('redirectTo', currentPath);
      }

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}${currentPath}`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
          skipBrowserRedirect: false
        },
      });

      if (error) {
        console.error('OAuth error:', error);
        throw error;
      }

      console.log('OAuth response:', data);
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      console.log('Starting logout...');
      await handleSignOut();
      console.log('Logout successful');
    } catch (error) {
      console.error('Error during logout:', error);
      throw error;
    }
  };

  const deleteAccount = async () => {
    try {
      console.log('Starting account deletion...');
      
      if (!session?.user?.id) {
        throw new Error('No authenticated session');
      }

      // First, delete user's company details
      const { error: deleteDetailsError } = await supabase
        .from('user_company_details')
        .delete()
        .eq('user_id', session.user.id);

      if (deleteDetailsError) {
        console.error('Error deleting company details:', deleteDetailsError);
        throw deleteDetailsError;
      }

      // Delete the user's account
      const { error: deleteUserError } = await supabase.rpc('delete_user');
      
      if (deleteUserError) {
        console.error('Error deleting user account:', deleteUserError);
        throw deleteUserError;
      }

      // Clear local state and redirect
      await handleSignOut();
      console.log('Account deletion successful');
    } catch (error) {
      console.error('Error during account deletion:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, session, isLoading, login, logout, deleteAccount }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 