import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { CompanyDetails, saveCompanyDetails, getCompanyDetails } from '../utils/database';

export const useCompanyDetails = () => {
  const { user, session, isLoading: isAuthLoading } = useAuth();
  const [companyDetails, setCompanyDetails] = useState<Omit<CompanyDetails, 'created_at' | 'updated_at' | 'id'> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (isAuthLoading || !session?.user) {
        setIsLoading(false);
        return;
      }

      try {
        setError(null);
        const details = await getCompanyDetails();
        if (details) {
          const { created_at, updated_at, id, ...rest } = details;
          setCompanyDetails(rest);
        }
      } catch (err) {
        console.error('Error fetching company details:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch company details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [session, isAuthLoading]);

  const updateCompanyDetails = async (details: Omit<CompanyDetails, 'created_at' | 'updated_at' | 'id' | 'user_id'>) => {
    if (!session?.user) {
      throw new Error('User must be logged in to update company details');
    }

    setError(null);
    try {
      await saveCompanyDetails({
        ...details,
        user_id: session.user.id
      });
      setCompanyDetails({ ...details, user_id: session.user.id });
    } catch (err) {
      console.error('Error saving company details:', err);
      setError(err instanceof Error ? err.message : 'Failed to save company details');
      throw err;
    }
  };

  return {
    companyDetails,
    isLoading: isLoading || isAuthLoading,
    error,
    updateCompanyDetails
  };
}; 