import React, { useState, useRef, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Menu, X, Moon, Sun, ChevronRight, Home, User, LogOut, ChevronDown, FileText, BookOpen } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../contexts/AuthContext';

interface NavigationItem {
  title: string;
  path?: string;
  icon?: typeof BookOpen;
  items?: Array<{
    title: string;
    path: string;
  }>;
}

// Navigation structure
const navigationItems: NavigationItem[] = [
  { title: 'Process Invoice', path: '/invoice_processor' },
  { title: 'Generate Invoice', path: '/invoice_processor/invoice-generator' },
  {
    title: 'Documentation & Help',
    icon: BookOpen,
    items: [
      { title: 'Dutch Invoice Requirements', path: '/invoice_processor/invoice-rules-nl' },
      { title: 'CAMT.053 Bank Statement Guide', path: '/invoice_processor/camt053-guide' },
      { title: 'MT940 Bank Format Guide', path: '/invoice_processor/mt940-guide' },
      { title: 'E-Invoicing Implementation Guide', path: '/invoice_processor/e-invoicing-guide' },
    ]
  }
];

const Layout: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { user, logout } = useAuth();

  // Handle click outside of profile menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target as Node)) {
        setProfileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Close profile menu when location changes
  useEffect(() => {
    setProfileMenuOpen(false);
  }, [location]);

  // Get current page name for breadcrumb
  const getCurrentPageName = () => {
    const path = location.pathname.split('/').pop();
    switch(path) {
      case 'privacy':
        return 'Privacy Policy';
      case 'tos':
        return 'Terms of Service';
      case 'invoice-rules-nl':
        return 'Invoice Rules';
      case 'invoice-generator':
        return 'Invoice Generator';
      case 'camt053-guide':
        return 'CAMT.053 Guide';
      case 'mt940-guide':
        return 'MT940 Guide';
      case 'e-invoicing-guide':
        return 'E-Invoicing Guide';
      default:
        return 'Home';
    }
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content={darkMode ? '#1f2937' : '#ffffff'} />
        
        {/* Default meta tags that can be overridden by individual pages */}
        <title>Hermes BI - Invoice Tools</title>
        <meta name="description" content="Professional invoice management tools for Dutch businesses" />
        <meta name="keywords" content="invoice processor, invoice generator, Dutch business, BTW, VAT" />
        
        {/* Open Graph defaults */}
        <meta property="og:site_name" content="Hermes BI" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/logo512_clear.png" />
        
        {/* Twitter Card defaults */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@HermesBI" />
        
        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
      </Helmet>

      {/* Header */}
      <header className="bg-white dark:bg-gray-800 shadow-sm fixed w-full z-10 transition-all duration-300 ease-in-out">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-8">
              {/* Logo and Product Name */}
              <Link to="/" className="flex items-center">
                <img 
                  src="/logo512_clear.png" 
                  alt="Hermes BI Logo" 
                  className="h-8 w-8 mr-2" 
                />
                <span className="text-xl font-bold text-gray-900 dark:text-white">Hermes BI</span>
              </Link>

              {/* Product Navigation */}
              <nav className="hidden md:flex items-center space-x-6">
                {navigationItems.map((item) => 
                  item.items ? (
                    // Dropdown for documentation
                    <div
                      key={item.title}
                      className="relative group"
                      onMouseEnter={() => setOpenDropdown(item.title)}
                      onMouseLeave={() => setOpenDropdown(null)}
                    >
                      <button
                        className="flex items-center space-x-1 text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 font-medium"
                      >
                        {item.icon && <item.icon className="h-4 w-4 mr-1" />}
                        <span>{item.title}</span>
                        <ChevronDown className="h-4 w-4" />
                      </button>
                      
                      {/* Dropdown Menu */}
                      <div
                        className={`absolute left-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 transition-all duration-200 ${
                          openDropdown === item.title
                            ? 'opacity-100 visible transform translate-y-0'
                            : 'opacity-0 invisible transform -translate-y-2'
                        }`}
                      >
                        <div className="py-1">
                          {item.items.map((subItem) => (
                            <Link
                              key={subItem.path}
                              to={subItem.path}
                              className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                              {subItem.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : item.path ? (
                    // Regular navigation item
                    <Link
                      key={item.path}
                      to={item.path}
                      className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 font-medium"
                    >
                      {item.title}
                    </Link>
                  ) : null
                )}
              </nav>
            </div>

            <div className="flex items-center space-x-4">
              {/* Legal Links */}
              <nav className="hidden md:flex space-x-6">
                <Link 
                  to="/invoice_processor/privacy"
                  className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                >
                  Privacy
                </Link>
                <Link 
                  to="/invoice_processor/tos"
                  className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                >
                  Terms
                </Link>
              </nav>

              {/* Theme Toggle */}
              <button
                onClick={() => setDarkMode(!darkMode)}
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition duration-300"
                aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
              >
                {darkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
              </button>

              {user ? (
                <div className="relative" ref={profileMenuRef}>
                  <button 
                    onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                    className="flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    {user.picture ? (
                      <img src={user.picture} alt={user.name} className="h-8 w-8 rounded-full" />
                    ) : (
                      <User className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                    )}
                    <span className="text-sm text-gray-700 dark:text-gray-300">{user.name}</span>
                  </button>
                  
                  {profileMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg py-1 z-10">
                      <Link
                        to="/profile"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                        onClick={() => setProfileMenuOpen(false)}
                      >
                        <User className="h-4 w-4 mr-2" />
                        Profile Settings
                      </Link>
                      <button
                        onClick={() => {
                          setProfileMenuOpen(false);
                          logout();
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <LogOut className="h-4 w-4 mr-2" />
                        Sign Out
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  to="/login"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Sign In
                </Link>
              )}

              {/* Mobile Menu Button */}
              <button
                onClick={() => setMobileMenuOpen(true)}
                className="md:hidden text-gray-600 dark:text-gray-300"
                aria-label="Open navigation menu"
                aria-expanded={mobileMenuOpen}
                aria-controls="mobile-menu"
              >
                <Menu className="h-6 w-6" />
              </button>
            </div>
          </div>

          {/* Breadcrumb */}
          <div className="mt-4 flex items-center text-sm text-gray-500 dark:text-gray-400">
            <Link to="/" className="hover:text-blue-600 dark:hover:text-blue-400 flex items-center">
              <Home className="h-4 w-4" />
              <span className="ml-1">Hermes BI</span>
            </Link>
            <ChevronRight className="h-4 w-4 mx-2" />
            <Link to="/invoice_processor" className="hover:text-blue-600 dark:hover:text-blue-400">
              Invoice Processor
            </Link>
            {location.pathname !== '/invoice_processor' && (
              <>
                <ChevronRight className="h-4 w-4 mx-2" />
                <span className="text-gray-600 dark:text-gray-300">{getCurrentPageName()}</span>
              </>
            )}
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      <div 
        className={`fixed inset-0 z-50 transform transition-transform duration-300 ${
          mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="absolute inset-0 bg-black opacity-50" onClick={() => setMobileMenuOpen(false)}></div>
        <div className="absolute right-0 h-full w-64 bg-white dark:bg-gray-800 shadow-xl overflow-y-auto">
          <div className="p-6">
            <button 
              onClick={() => setMobileMenuOpen(false)}
              className="absolute top-4 right-4 text-gray-600 dark:text-gray-300"
              aria-label="Close navigation menu"
            >
              <X className="h-6 w-6" />
            </button>
            <nav className="mt-8">
              {/* Tools Section */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold text-gray-900 dark:text-white mb-2">
                  Tools
                </h3>
                <ul className="space-y-2 pl-6">
                  {navigationItems
                    .filter((item): item is NavigationItem & { path: string } => !!item.path)
                    .map(item => (
                      <li key={item.path}>
                        <Link
                          to={item.path}
                          className="block text-sm text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>

              {/* Documentation Section */}
              {navigationItems
                .filter((item): item is NavigationItem & { items: NonNullable<NavigationItem['items']> } => !!item.items)
                .map(category => {
                  const Icon = category.icon;
                  return (
                    <div key={category.title} className="mb-6">
                      <h3 className="flex items-center text-sm font-semibold text-gray-900 dark:text-white mb-2">
                        {Icon && <Icon className="h-4 w-4 mr-2" />}
                        {category.title}
                      </h3>
                      <ul className="space-y-2 pl-6">
                        {category.items.map(item => (
                          <li key={item.path}>
                            <Link
                              to={item.path}
                              className="block text-sm text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                              onClick={() => setMobileMenuOpen(false)}
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
            </nav>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="pt-16">
        <Outlet />
      </div>

      {/* Footer */}
      <footer className="bg-gray-800 dark:bg-gray-900 text-white py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Hermes BI</h3>
              <p className="text-gray-400">Automated invoice processing for modern businesses</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/invoice_processor" className="text-gray-400 hover:text-white transition duration-300">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/invoice_processor/privacy" className="text-gray-400 hover:text-white transition duration-300">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/invoice_processor/tos" className="text-gray-400 hover:text-white transition duration-300">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact</h3>
              <p className="text-gray-400">
                Email: support@hermes-bi.com<br />
                Location: Amsterdam, Netherlands
              </p>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} Hermes Business Intelligence. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout; 