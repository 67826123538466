import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { invoiceTranslations, Language } from './translations';

// Register a default font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
});

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Roboto',
    fontSize: 10
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
    borderBottom: '1px solid #eee',
    paddingBottom: 20
  },
  logo: {
    width: 150,
    height: 60,
    objectFit: 'contain'
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#2c3e50',
    marginBottom: 15
  },
  section: {
    marginBottom: 20
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  column: {
    flexDirection: 'column',
    marginBottom: 10,
    minWidth: '200'
  },
  text: {
    fontSize: 10,
    lineHeight: 1.6,
    color: '#2c3e50'
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 11,
    marginBottom: 8
  },
  table: {
    width: 'auto',
    marginBottom: 30,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    borderBottomStyle: 'solid',
    minHeight: 25,
    alignItems: 'center'
  },
  tableHeader: {
    backgroundColor: '#f8f9fa'
  },
  tableCell: {
    padding: 8,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: '#e0e0e0',
    borderRightStyle: 'solid'
  },
  totals: {
    marginTop: 20,
    alignItems: 'flex-end',
    paddingRight: 8
  },
  totalRow: {
    fontSize: 10,
    marginBottom: 5
  },
  totalAmount: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#2c3e50',
    marginTop: 8
  },
  bankDetails: {
    marginTop: 40,
    padding: 15,
    backgroundColor: '#f8f9fa',
    borderRadius: 4
  },
  bankDetailsTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#2c3e50'
  }
});

interface InvoicePDFTemplateProps {
  logo: string | null;
  invoiceNumber: string;
  invoiceDate: string;
  deliveryDate: string;
  dueDate: string;
  supplierDetails: any;
  customerDetails: any;
  items: any[];
  bankDetails: any;
  totals: {
    subtotal: number;
    vat: number;
    total: number;
  };
  language?: Language;
}

// Add a helper function for number formatting
const formatCurrency = (amount: number, language: Language) => {
  return new Intl.NumberFormat(language === 'nl' ? 'nl-NL' : 'en-US', {
    style: 'currency',
    currency: 'EUR'
  }).format(amount);
};

const InvoicePDFTemplate: React.FC<InvoicePDFTemplateProps> = ({
  logo,
  invoiceNumber,
  invoiceDate,
  deliveryDate,
  dueDate,
  supplierDetails,
  customerDetails,
  items,
  bankDetails,
  totals,
  language = 'en'
}) => {
  const t = invoiceTranslations[language];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          {logo && <Image style={styles.logo} src={logo} />}
          <View>
            <Text style={styles.title}>{t.invoice}</Text>
            <Text style={styles.text}>{t.invoiceNumber}: {invoiceNumber}</Text>
            <Text style={styles.text}>{t.invoiceDate}: {invoiceDate}</Text>
            <Text style={styles.text}>{t.deliveryDate}: {deliveryDate}</Text>
            <Text style={styles.text}>{t.dueDate}: {dueDate}</Text>
          </View>
        </View>

        {/* Company Details */}
        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={[styles.text, styles.bold]}>{t.from}</Text>
            <Text style={styles.text}>{supplierDetails.name}</Text>
            <Text style={styles.text}>{supplierDetails.address}</Text>
            <Text style={styles.text}>{supplierDetails.postcode} {supplierDetails.city}</Text>
            <Text style={styles.text}>{supplierDetails.country}</Text>
            <Text style={styles.text}>KVK: {supplierDetails.kvkNumber}</Text>
            <Text style={styles.text}>VAT: {supplierDetails.vatNumber}</Text>
          </View>
          <View style={styles.column}>
            <Text style={[styles.text, styles.bold]}>{t.billTo}</Text>
            <Text style={styles.text}>{customerDetails.name}</Text>
            <Text style={styles.text}>{customerDetails.address}</Text>
            <Text style={styles.text}>{customerDetails.postcode} {customerDetails.city}</Text>
            <Text style={styles.text}>{customerDetails.country}</Text>
            {customerDetails.vatNumber && (
              <Text style={styles.text}>VAT: {customerDetails.vatNumber}</Text>
            )}
          </View>
        </View>

        {/* Items Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={[styles.tableCell, { flex: 3 }]}>{t.description}</Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>{t.quantity}</Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>{t.unitPrice}</Text>
            <Text style={[styles.tableCell, { flex: 1 }]}>{t.vatRate}</Text>
            <Text style={[styles.tableCell, { flex: 1, borderRightWidth: 0 }]}>{t.amount}</Text>
          </View>
          {items.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 3 }]}>{item.description}</Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>{item.quantity}</Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>
                {formatCurrency(item.unitPrice, language)}
              </Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>{item.vatRate}%</Text>
              <Text style={[styles.tableCell, { flex: 1, borderRightWidth: 0 }]}>
                {formatCurrency(item.quantity * item.unitPrice, language)}
              </Text>
            </View>
          ))}
        </View>

        {/* Totals */}
        <View style={styles.totals}>
          <Text style={styles.totalRow}>
            {t.subtotalExVat}: {formatCurrency(totals.subtotal, language)}
          </Text>
          <Text style={styles.totalRow}>
            {t.vat}: {formatCurrency(totals.vat, language)}
          </Text>
          <Text style={styles.totalAmount}>
            {t.totalIncVat}: {formatCurrency(totals.total, language)}
          </Text>
        </View>

        {/* Bank Details */}
        <View style={styles.bankDetails}>
          <Text style={styles.bankDetailsTitle}>{t.paymentDetails}</Text>
          <Text style={styles.text}>{t.bank}: {bankDetails.bankName}</Text>
          <Text style={styles.text}>IBAN: {bankDetails.iban}</Text>
          {bankDetails.bic && <Text style={styles.text}>BIC: {bankDetails.bic}</Text>}
          <Text style={styles.text}>{t.paymentTerms}: {t.paymentTermsDays}</Text>
          <Text style={styles.text}>{t.dueDate}: {dueDate}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDFTemplate; 