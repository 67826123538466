import React from 'react';
import { FileText, Info, AlertCircle, CheckCircle, Building2, Receipt, Calculator, Clock, FileCheck, BarChart2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const EFacturatieGidsNL: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>E-facturatie Gids voor Nederlandse Bedrijven - Hermes BI</title>
        <meta name="description" content="Complete gids over e-facturatie in Nederland. Leer over de vereisten, implementatie en aankomende wijzigingen voor kleine bedrijven, ZZP'ers en accountants." />
        <meta name="keywords" content="e-facturatie Nederland, e-factuur, elektronisch factureren, PEPPOL, UBL-OHNL, Nederlands bedrijf, ZZP, accountant" />
        <meta property="og:title" content="E-facturatie Gids voor Nederlandse Bedrijven" />
        <meta property="og:description" content="Complete gids over e-facturatie in Nederland" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Article Header */}
        <header className="pt-24 pb-12 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between mb-4">
              {/* Create Invoice button */}
              <Link 
                to="/invoice_processor/invoice-generator" 
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                <FileText className="h-4 w-4 mr-2" />
                Factuur Maken
              </Link>
              {/* Language toggle */}
              <div className="flex items-center">
                <span className="text-gray-600">Nederlands</span>
                <span className="mx-2 text-gray-400">|</span>
                <Link 
                  to="/invoice_processor/e-invoicing-guide" 
                  className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  English
                </Link>
              </div>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              E-facturatie in Nederland: Een Gids voor het MKB
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              Alles wat u moet weten over elektronisch factureren: van vereisten tot implementatie
            </p>
          </div>
        </header>

        {/* Quick Overview Cards */}
        <div className="bg-white dark:bg-gray-900 py-8 border-b border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                {
                  icon: Clock,
                  text: "Verplicht in 2028",
                  subtext: "Voor B2B transacties",
                  animation: "animate-float-1"
                },
                {
                  icon: Building2,
                  text: "10% Adoptie",
                  subtext: "Huidig MKB gebruik",
                  animation: "animate-float-2"
                },
                {
                  icon: FileCheck,
                  text: "PEPPOL Netwerk",
                  subtext: "Standaard platform",
                  animation: "animate-float-3"
                },
                {
                  icon: BarChart2,
                  text: "Real-time in 2030",
                  subtext: "Belastingrapportage",
                  animation: "animate-float-4"
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`flex flex-col items-center ${item.animation}`}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-400/20 rounded-full blur-xl transform scale-150 animate-pulse" />
                    <div className="relative bg-white dark:bg-gray-800 p-4 rounded-full border border-gray-200 dark:border-gray-700 shadow-lg">
                      <item.icon className="h-8 w-8 text-blue-500 dark:text-blue-400" />
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    <span className="block font-medium text-gray-900 dark:text-white">
                      {item.text}
                    </span>
                    <span className="text-xs text-gray-600 dark:text-gray-300">
                      {item.subtext}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Introduction */}
          <section className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none mb-12">
            <p className="lead">
              Nu het bedrijfslandschap evolueert, loopt Nederland voorop in de digitale transformatie van financiële processen. 
              Voor MKB'ers, ZZP'ers en accountants biedt e-facturatie zowel uitdagingen als kansen. 
              Met de huidige verplichtingen voor overheidsleveranciers en nieuwe veranderingen die eraan komen in 2028 en 2030, 
              is begrip van e-facturatie niet langer optioneel—het is essentieel voor de toekomstbestendigheid van uw bedrijf.
            </p>
            <p>
              In deze uitgebreide gids nemen we u stap voor stap mee door alles wat u moet weten over e-facturatie: wat het is, 
              of uw bedrijf het nodig heeft, hoe u kunt beginnen en hoe u zich kunt voorbereiden op aankomende veranderingen. 
              We helpen u weloverwogen beslissingen te nemen over de implementatie van e-facturatie in uw bedrijfsvoering.
            </p>
          </section>

          {/* Table of Contents */}
          <nav className="mb-12 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Inhoud</h2>
            <ul className="space-y-2">
              <li>
                <a href="#wat-is-e-facturatie" className="text-blue-600 dark:text-blue-400 hover:underline">
                  1. Wat is E-facturatie?
                </a>
              </li>
              <li>
                <a href="#heb-ik-het-nodig" className="text-blue-600 dark:text-blue-400 hover:underline">
                  2. Heb ik E-facturatie Nodig?
                </a>
              </li>
              <li>
                <a href="#aan-de-slag" className="text-blue-600 dark:text-blue-400 hover:underline">
                  3. Aan de Slag met E-facturatie
                </a>
              </li>
              <li>
                <a href="#software-vereisten" className="text-blue-600 dark:text-blue-400 hover:underline">
                  4. Software Vereisten
                </a>
              </li>
              <li>
                <a href="#aankomende-wijzigingen" className="text-blue-600 dark:text-blue-400 hover:underline">
                  5. Aankomende Wijzigingen (2025 en verder)
                </a>
              </li>
            </ul>
          </nav>

          {/* What is E-facturatie Section */}
          <section id="wat-is-e-facturatie" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">1. Wat is E-facturatie?</h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
              <p>
                E-facturatie gaat veel verder dan het simpelweg versturen van een PDF-factuur via e-mail. Het is een volledig 
                digitaal proces dat de hele facturatieworkflow transformeert. In essentie is een e-factuur een gestructureerd 
                databestand dat alle factuurinformatie bevat in een gestandaardiseerd formaat, waardoor automatische verwerking 
                door boekhoudkundige systemen mogelijk is zonder handmatige invoer.
              </p>
              
              <p>
                Vergelijk het met het verschil tussen een brief versturen en gebruik maken van een berichtendienst. Hoewel beide 
                informatie overbrengen, maakt een berichtendienst directe verwerking, geautomatiseerde reacties en integratie met 
                andere digitale systemen mogelijk. Op dezelfde manier zorgt e-facturatie ervoor dat uw facturen automatisch kunnen 
                worden verwerkt, gevalideerd en vastgelegd in boekhoudkundige systemen.
              </p>
              
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6 my-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Belangrijkste Kenmerken</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium text-gray-900 dark:text-white">Gestructureerd Formaat</span>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Maakt gebruik van gestandaardiseerde formaten zoals PEPPOL BIS en UBL-OHNL, die zorgen voor consistentie 
                        en compatibiliteit tussen verschillende systemen. Deze formaten fungeren als een universele taal die alle 
                        e-facturatiesystemen kunnen begrijpen en verwerken.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium text-gray-900 dark:text-white">Geautomatiseerde Verwerking</span>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Directe integratie met boekhoudkundige systemen betekent dat facturen automatisch worden geregistreerd, 
                        gekoppeld aan inkooporders en voorbereid voor betaling. Deze automatisering verkort de verwerkingstijd 
                        aanzienlijk en elimineert fouten bij handmatige invoer.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <div>
                      <span className="font-medium text-gray-900 dark:text-white">Veilige Verzending</span>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Verzending via beveiligde netwerken zoals PEPPOL, wat zorgt voor data-integriteit en vertrouwelijkheid. 
                        In tegenstelling tot e-mailbijlagen kunnen e-facturen niet worden gemanipuleerd tijdens verzending en 
                        bieden ze een duidelijke audittrail.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 my-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Het Verschil met Digitale Facturen</h3>
                <p className="text-gray-600 dark:text-gray-100 mb-4">
                  Het is belangrijk om te begrijpen dat niet alle digitale facturen e-facturen zijn. Dit zijn de verschillen:
                </p>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="bg-white dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Digitale Factuur (bijv. PDF)</h4>
                    <ul className="text-sm text-gray-600 dark:text-gray-300 space-y-2">
                      <li>• Statisch documentformaat</li>
                      <li>• Vereist handmatige verwerking</li>
                      <li>• Kan na creatie worden aangepast</li>
                      <li>• Beperkte automatiseringsmogelijkheden</li>
                    </ul>
                  </div>
                  <div className="bg-white dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">E-factuur</h4>
                    <ul className="text-sm text-gray-600 dark:text-gray-300 space-y-2">
                      <li>• Gestructureerd dataformaat</li>
                      <li>• Automatische verwerking</li>
                      <li>• Fraudebestendig</li>
                      <li>• Volledige automatiseringsondersteuning</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Do I Need E-facturatie Section */}
          <section id="heb-ik-het-nodig" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">2. Heb ik E-facturatie Nodig?</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p>
                  Of u e-facturatie nodig heeft, hangt af van uw bedrijfstype en klanten. Laten we dit stapsgewijs bekijken 
                  om u te helpen een weloverwogen beslissing te nemen:
                </p>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Verplichte Gevallen
                  </h3>
                  <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-100">
                      U <strong>moet</strong> e-facturatie implementeren als:
                    </p>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-red-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Overheidsleveranciers</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            Als u goederen of diensten levert aan Nederlandse overheidsinstanties, is e-facturatie sinds 2017 
                            verplicht. Dit geldt voor ministeries, departementen en overheidsinstanties.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-red-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Lokale Overheid</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            Veel gemeenten en lokale overheidsinstanties vereisen nu e-facturen. Controleer bij uw specifieke 
                            overheidsklanten wat hun vereisten zijn.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-red-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Toekomstige Vereisten (2028)</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            Alle bedrijven die B2B-transacties uitvoeren binnen de EU zullen vanaf 2028 e-facturatie moeten gebruiken.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Optioneel maar Voordelig
                  </h3>
                  <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-100">
                      U zou e-facturatie moeten <strong>overwegen</strong> wanneer:
                    </p>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">B2B Transacties</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            Hoewel momenteel vrijwillig voor binnenlandse B2B-transacties, kan e-facturatie uw bedrijfsprocessen 
                            aanzienlijk stroomlijnen en kosten besparen. Veel grotere bedrijven geven de voorkeur aan of vereisen 
                            e-facturen van hun leveranciers.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Internationale Handel</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            Als u internationaal zaken doet, vooral binnen de EU, zal het nu implementeren van e-facturatie u 
                            voorbereiden op toekomstige vereisten en internationale transacties soepeler laten verlopen.
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">Hoog Factuurvolume</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">
                            Als u maandelijks veel facturen verwerkt, kan e-facturatie de verwerkingstijd en kosten aanzienlijk 
                            verminderen.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg mt-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Uw Beslissing Maken</h3>
                <div className="space-y-4">
                  <p className="text-gray-600 dark:text-gray-100">
                    Overweeg deze factoren bij het beslissen of u nu e-facturatie wilt implementeren:
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Bedrijfsgroei</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Als u van plan bent om te groeien, vooral internationaal of met overheidscontracten, zal het nu 
                          implementeren van e-facturatie latere obstakels voorkomen.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Kosten-batenanalyse</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Hoewel er initiële opstartkosten zijn, verdient e-facturatie zichzelf meestal terug door verminderde 
                          verwerkingstijd, minder fouten en snellere betalingen.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Klantvereisten</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Informeer bij uw belangrijkste klanten naar hun plannen voor e-facturatie. Als belangrijke klanten 
                          overstappen op e-facturatie, is het verstandig om mee te gaan in hun tijdlijn.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="mt-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">Ons Advies</h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Gezien de aankomende verplichtingen en de duidelijke voordelen, raden wij de meeste bedrijven aan om nu al 
                    te beginnen met het plannen van hun e-facturatie implementatie, ook al is het nog niet direct verplicht. 
                    Dit zorgt voor een soepelere overgang en voorkomt haastwerk wanneer het verplicht wordt. Begin met een 
                    klein pilotproject om ervaring op te doen en breid geleidelijk uit op basis van uw ervaringen.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Getting Started Section */}
          <section id="aan-de-slag" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">3. Aan de Slag met E-facturatie</h2>
            <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Implementatiestappen</h3>
              <ol className="space-y-4">
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">1</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Kies Software</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Selecteer e-facturatiesoftware die past bij uw bedrijfsbehoeften. Let op integratiemogelijkheden 
                      met uw huidige boekhoudsysteem.
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">2</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Verbind met PEPPOL</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Regel een aansluiting bij een gecertificeerde PEPPOL-toegangspuntaanbieder voor veilige 
                      verzending van e-facturen.
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">3</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Test het Systeem</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Controleer of alles correct werkt met testtransacties voordat u live gaat. Test zowel het 
                      verzenden als ontvangen van e-facturen.
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">4</span>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">Start Gefaseerd</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Begin met een kleine groep klanten of leveranciers en breid geleidelijk uit naarmate u meer 
                      ervaring opdoet.
                    </p>
                  </div>
                </li>
              </ol>

              <div className="mt-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
                <h4 className="font-medium text-gray-900 dark:text-white mb-2">Belangrijke Aandachtspunten</h4>
                <ul className="text-sm text-gray-600 dark:text-gray-300 space-y-2">
                  <li>• Zorg voor goede training van uw medewerkers</li>
                  <li>• Informeer uw klanten en leveranciers tijdig</li>
                  <li>• Houd rekening met een overgangsperiode</li>
                  <li>• Documenteer uw nieuwe processen zorgvuldig</li>
                </ul>
              </div>
            </div>
          </section>

          {/* Software Requirements Section */}
          <section id="software-vereisten" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">4. Software Vereisten</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p>
                  Om e-facturatie in uw bedrijf te implementeren, heeft u software nodig die voldoet aan de Nederlandse en 
                  Europese e-facturatiestandaarden. De exacte combinatie van tools hangt af van uw bedrijfsbehoeften. 
                  Hieronder vindt u een overzicht van de verschillende soorten software die u mogelijk nodig heeft:
                </p>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Basis Software Types</h3>
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">E-facturatie Software</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Boekhoudsoftware of specifieke e-facturatiesoftware die gestandaardiseerde formaten ondersteunt zoals 
                          PEPPOL BIS 3.0, UBL-OHNL of SETU OHNL. Zoek naar oplossingen die integreren met uw bestaande 
                          boekhoudsysteem.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">PEPPOL Toegangspunt</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Software of dienst die u verbindt met het PEPPOL-netwerk. Essentieel voor B2G-transacties en steeds 
                          belangrijker voor B2B-transacties.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Overheidsplatforms</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Voor B2G-transacties heeft u toegang nodig tot Digipoort (voor hoog volume) of het 
                          Leveranciersportaal van de overheid (voor laag volume).
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Ondersteunende Tools</h3>
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">XML/EDI Tools</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Tools voor het omzetten van facturen naar gestandaardiseerde XML-formaten. Deze kunnen standalone zijn 
                          of geïntegreerd in uw e-facturatiesoftware.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Archiveringssysteem</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Beveiligd opslagsysteem voor het bewaren van e-facturen gedurende de wettelijk verplichte periode van 
                          7 jaar (10 jaar voor onroerend goed transacties).
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">De Juiste Oplossing Kiezen</h3>
                <div className="space-y-4">
                  <p className="text-gray-600 dark:text-gray-100">
                    Houd rekening met deze belangrijke factoren bij het kiezen van e-facturatiesoftware:
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Bedrijfsomvang en Volume</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Kleine bedrijven hebben mogelijk voldoende aan een alles-in-één oplossing, terwijl grotere organisaties 
                          specifiekere tools nodig hebben. Houd rekening met uw maandelijkse factuurvolume bij het maken van uw keuze.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Integratie-eisen</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Zorg ervoor dat de oplossing integreert met uw bestaande boekhoudsoftware en andere bedrijfssystemen.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Standaardcompliantie</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Controleer of de software alle vereiste formaten ondersteunt (PEPPOL BIS 3.0, UBL-OHNL) en voldoet 
                          aan Nederlandse en EU-regelgeving.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Schaalbaarheid</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Kies een oplossing die kan meegroeien met uw bedrijf en zich kan aanpassen aan toekomstige vereisten.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="mt-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">Klein Beginnen</h4>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Voor ZZP'ers en kleine bedrijven die net beginnen met e-facturatie bieden veel boekhoudpakketten 
                    basis e-facturatiemogelijkheden als onderdeel van hun standaardpakket. Begin met deze basisoplossingen 
                    en breid uit naarmate uw behoeften groeien. Veel aanbieders bieden gratis proefperiodes om hun diensten 
                    te evalueren.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Upcoming Changes Section */}
          <section id="aankomende-wijzigingen" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">5. Aankomende Wijzigingen (2025 en verder)</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p>
                  De Europese Unie werkt aan een gestandaardiseerd en gedigitaliseerd btw-stelsel, waarbij e-facturatie een 
                  centrale rol speelt. Nederland zal de komende jaren belangrijke veranderingen doormaken op het gebied van 
                  e-facturatie, voornamelijk door het VAT in the Digital Age (ViDA)-initiatief van de EU.
                </p>
              </div>

              <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <Clock className="h-6 w-6 text-yellow-500 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Belangrijke Mijlpalen</h3>
                </div>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="font-bold text-yellow-800 dark:text-yellow-200 mr-2">2028:</span>
                    <div className="text-gray-600 dark:text-gray-300">
                      <p>• Verplichte e-facturatie voor alle B2B-transacties in de EU</p>
                      <p>• Alleen gestructureerde e-facturen (PEPPOL BIS 3.0 of UBL-OHNL formaten) worden geaccepteerd</p>
                      <p>• Volledige integratie met PEPPOL-netwerk vereist</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="font-bold text-yellow-800 dark:text-yellow-200 mr-2">2030:</span>
                    <div className="text-gray-600 dark:text-gray-300">
                      <p>• Invoering van Digitale Rapportageverplichtingen (DRR)</p>
                      <p>• Real-time factuurgegevens rapportage aan de Belastingdienst</p>
                      <p>• Verbeterde fraudepreventie maatregelen</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="font-bold text-yellow-800 dark:text-yellow-200 mr-2">2035:</span>
                    <div className="text-gray-600 dark:text-gray-300">
                      <p>• Volledige harmonisatie van nationale e-facturatiesystemen met EU-standaarden</p>
                      <p>• Complete integratie van alle e-facturatieplatforms</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Belangrijke Wijzigingen</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">B2B E-facturatie Verplichting</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Momenteel optioneel voor B2B-transacties, maar wordt verplicht vanaf 2028. Alle bedrijven moeten dan 
                          gestructureerde e-facturen gebruiken.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Digitale Rapportage</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Vanaf 2030 is real-time rapportage van factuurgegevens aan de Belastingdienst verplicht, wat 
                          aanzienlijke updates van IT-systemen vereist.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">PEPPOL Netwerk</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          PEPPOL wordt het standaard netwerk voor e-facturatie, waardoor alle bedrijven via gecertificeerde 
                          toegangspunten moeten aansluiten.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Huidige Status</h3>
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white mb-2">Adoptiegraad</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-100">
                        Slechts 10% van de MKB'ers heeft e-facturatie volledig omarmd, terwijl de meeste nog steeds PDF-facturen 
                        gebruiken. Dit wijst op een grote noodzaak voor bedrijven om zich voor te bereiden op de aankomende 
                        veranderingen.
                      </p>
                    </div>
                    <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg">
                      <h4 className="font-medium text-gray-900 dark:text-white mb-2">Waarom Nu Actie Ondernemen?</h4>
                      <ul className="text-sm text-gray-600 dark:text-gray-100 space-y-2">
                        <li>• Voorkom last-minute drukte en complianceproblemen</li>
                        <li>• Profiteer van de voordelen van vroege adoptie</li>
                        <li>• Spreid implementatiekosten over de tijd</li>
                        <li>• Doe ervaring op met het systeem</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Hoe Voor te Bereiden</h3>
                <div className="space-y-4">
                  <p className="text-gray-600 dark:text-gray-100">
                    Aangezien de meeste Nederlandse bedrijven nog niet klaar zijn voor deze veranderingen, raden wij het volgende aan:
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Beoordeel Uw Systemen</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Evalueer uw huidige facturatieproces en identificeer hiaten in het voldoen aan toekomstige vereisten.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Plan Uw Implementatie</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Maak een tijdlijn voor het adopteren van e-facturatieoplossingen en de aansluiting op het PEPPOL-netwerk.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Budgetteer voor Veranderingen</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Reserveer middelen voor software-upgrades, training en mogelijke systeemintegratiekosten.
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Begin Klein</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Start met een pilotprogramma om e-facturatie te testen met enkele belangrijke zakenpartners voordat u 
                          volledig implementeert.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Additional Resources */}
          <section className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Aanvullende Bronnen
            </h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">
              Voor meer gedetailleerde informatie over e-facturatievereisten en -implementatie in Nederland 
              raadpleegt u deze officiële overheidsbronnen:
            </p>
            <ul className="space-y-4">
              <li>
                <a 
                  href="https://www.government.nl/topics/e-invoicing"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <div>
                    <span className="font-medium">Nederlandse Overheid E-facturatie Gids</span>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Officiële overheidsinformatie over e-facturatie vereisten, implementatiemethoden en ondersteunende diensten. 
                      Inclusief details over de centrale overheid e-facturatie helpdesk en verschillende indieningsmethoden.
                    </p>
                  </div>
                </a>
              </li>
              <li>
                <a 
                  href="https://business.gov.nl/regulation/invoice-requirements/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <div>
                    <span className="font-medium">Business.gov.nl - Factuurvereisten</span>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      Uitgebreid overzicht van Nederlandse factuurvereisten, inclusief wettelijke vereisten, bewaartermijnen 
                      en specifieke regels voor elektronische en digitale facturering. Essentiële informatie voor alle ondernemers.
                    </p>
                  </div>
                </a>
              </li>
            </ul>
          </section>

          {/* Add a floating "Back to top" button */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="fixed bottom-8 right-8 bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-200"
            aria-label="Terug naar boven"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 10l7-7m0 0l7 7m-7-7v18" 
              />
            </svg>
          </button>
        </main>

        {/* Footer */}
        <footer className="bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              Deze gids is alleen bedoeld ter informatie en moet niet worden beschouwd als juridisch advies. 
              Raadpleeg altijd een gekwalificeerde belastingadviseur of accountant voor specifieke begeleiding.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default EFacturatieGidsNL; 