import React from 'react';
import { FileText, Info, AlertCircle, CheckCircle, Database, FileCode, Download } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const CAMT053Guide: React.FC = () => {
  return (
    <>
      <style>
        {`
          .code-line {
            font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
            line-height: 1.5;
            padding: 0.25rem 0;
          }
          .code-line:hover {
            background-color: rgba(55, 65, 81, 0.05);
          }
          .dark .code-line:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          .mt940-tag {
            color: #0891b2;
            font-weight: 500;
          }
          .dark .mt940-tag {
            color: #22d3ee;
          }
          .xml-tag {
            color: #0891b2;
          }
          .dark .xml-tag {
            color: #22d3ee;
          }
          .xml-content {
            color: #059669;
          }
          .dark .xml-content {
            color: #34d399;
          }
          .xml-attr {
            color: #9333ea;
          }
          .dark .xml-attr {
            color: #c084fc;
          }
        `}
      </style>
      <Helmet>
        <title>CAMT.053 Bank Statement Format Guide - Hermes BI</title>
        <meta name="description" content="Comprehensive guide to CAMT.053 bank statement format. Learn about XML structure, implementation, and best practices for handling bank statements in the Netherlands." />
        <meta name="keywords" content="CAMT.053, bank statements, XML format, ISO 20022, Dutch banking, electronic banking" />
        
        <meta property="og:title" content="CAMT.053 Bank Statement Format Guide" />
        <meta property="og:description" content="Comprehensive guide to CAMT.053 bank statement format" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Article Header */}
        <header className="pt-24 pb-12 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Understanding CAMT.053 Bank Statement Format
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              A comprehensive guide to working with CAMT.053 XML bank statements in the Netherlands
            </p>
          </div>
        </header>

        {/* Add animated icons section */}
        <div className="bg-white dark:bg-gray-900 py-8 border-b border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                {
                  icon: FileCode,
                  text: "ISO 20022 XML",
                  subtext: "Standardized format",
                  animation: "animate-float-1"
                },
                {
                  icon: Database,
                  text: "End-of-Day Statement",
                  subtext: "Complete daily overview",
                  animation: "animate-float-2"
                },
                {
                  icon: Download,
                  text: "Bank Integration",
                  subtext: "Direct bank connection",
                  animation: "animate-float-3"
                },
                {
                  icon: FileText,
                  text: "Rich Data Structure",
                  subtext: "Detailed transactions",
                  animation: "animate-float-4"
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`flex flex-col items-center ${item.animation}`}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-400/20 rounded-full blur-xl transform scale-150 animate-pulse" />
                    <div className="relative bg-white dark:bg-gray-800 p-4 rounded-full border border-gray-200 dark:border-gray-700 shadow-lg hover:shadow-xl transition-shadow duration-300">
                      <item.icon className="h-8 w-8 text-blue-500 dark:text-blue-400" />
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    <span className="block text-sm font-medium text-gray-900 dark:text-white">
                      {item.text}
                    </span>
                    <span className="block text-xs text-gray-500 dark:text-gray-400 mt-1">
                      {item.subtext}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Introduction */}
          <section className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none mb-12">
            <p className="lead">
              CAMT.053 is the new standard format for bank statements that all Dutch and European banks are transitioning to by the end of 2025. If you're an accountant or business owner who regularly downloads bank statements for accounting and tax purposes, understanding this format is crucial for your future workflow.
            </p>
          </section>

          {/* Table of Contents */}
          <nav className="mb-12 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Contents</h2>
            <ul className="space-y-2">
              <li>
                <a href="#essentials" className="text-blue-600 dark:text-blue-400 hover:underline">
                  1. Essential Information for Business Users
                </a>
              </li>
              <li>
                <a href="#structure" className="text-blue-600 dark:text-blue-400 hover:underline">
                  2. CAMT.053 Message Structure
                </a>
              </li>
              <li>
                <a href="#format-comparison" className="text-blue-600 dark:text-blue-400 hover:underline">
                  3. Comparison with Other Formats
                </a>
              </li>
              <li>
                <a href="#practical-use" className="text-blue-600 dark:text-blue-400 hover:underline">
                  4. Practical Use in Accounting
                </a>
              </li>
              <li>
                <a href="#downloading" className="text-blue-600 dark:text-blue-400 hover:underline">
                  5. Downloading and Processing Statements
                </a>
              </li>
            </ul>
          </nav>

          {/* Essential Information Section */}
          <section id="essentials" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">1. Essential Information for Business Users</h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none space-y-6">
              <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Key Points to Know</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span>CAMT.053 is replacing MT940 and similar formats for bank statements</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span>Mandatory transition by the end of 2025 for all European banks</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span>Contains more detailed transaction information than older formats</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span>Better support for automated processing and reconciliation</span>
                  </li>
                </ul>
              </div>

              <p>
                CAMT.053 provides end-of-day statements with comprehensive transaction details, making it easier to:
              </p>
              <ul className="space-y-3">
                <li className="flex items-start">
                
                  <span>- Reconcile accounts automatically</span>
                </li>
                <li className="flex items-start">
                  
                  <span>- Track payment references and remittance information</span>
                </li>
                <li className="flex items-start">
                  
                  <span>- Identify transaction types and categories</span>
                </li>
                <li className="flex items-start">
                    
                  <span>- Monitor cash flow more effectively</span>
                </li>
              </ul>
            </div>
          </section>

          {/* MT940 Structure Section */}
          <section id="structure" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">2. CAMT.053 Message Structure</h2>
            <div className="space-y-6">
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">XML Structure</h3>
                <div className="space-y-4">
                  <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Key Elements</h4>
                    <ul className="space-y-3 text-gray-600 dark:text-gray-300">
                      <li className="flex items-start">
                        <span className="xml-tag mr-2">&lt;GrpHdr&gt;</span>
                        <span>Group Header - Contains message identification</span>
                      </li>
                      <li className="flex items-start">
                        <span className="xml-tag mr-2">&lt;Stmt&gt;</span>
                        <span>Statement - Contains the actual statement information</span>
                      </li>
                      <li className="flex items-start">
                        <span className="xml-tag mr-2">&lt;Bal&gt;</span>
                        <span>Balance - Opening and closing balances</span>
                      </li>
                      <li className="flex items-start">
                        <span className="xml-tag mr-2">&lt;Ntry&gt;</span>
                        <span>Entry - Individual transaction details</span>
                      </li>
                    </ul>
                  </div>

                  <div className="p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg mt-4">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Format Example</h4>
                    <pre className="overflow-x-auto bg-gray-800 text-gray-100 rounded-lg p-4 text-sm">
{`<?xml version="1.0" encoding="UTF-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.053.001.02">
  <BkToCstmrStmt>
    <GrpHdr>
      <MsgId>CAMT053-2023-09-01</MsgId>
      <CreDtTm>2023-09-01T09:00:00</CreDtTm>
    </GrpHdr>
    <Stmt>
      <Id>123</Id>
      <Acct>
        <Id>
          <IBAN>NL91ABNA0417164300</IBAN>
        </Id>
      </Acct>
      <Bal>
        <Tp><CdOrPrtry><Cd>OPBD</Cd></CdOrPrtry></Tp>
        <Amt Ccy="EUR">1000.00</Amt>
        <CdtDbtInd>CRDT</CdtDbtInd>
      </Bal>
      <Ntry>
        <Amt Ccy="EUR">100.00</Amt>
        <CdtDbtInd>DBIT</CdtDbtInd>
        <RmtInf><Ustrd>Payment to supplier</Ustrd></RmtInf>
      </Ntry>
    </Stmt>
  </BkToCstmrStmt>
</Document>`}
                    </pre>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">
                      This example shows a basic CAMT.053 statement with header, account information, balance, and a transaction entry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* New Format Comparison Section */}
          <section id="format-comparison" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">3. Comparison with Other Formats</h2>
            <div className="space-y-6">
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Key Differences Between Formats</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th className="text-left py-3 px-4 text-gray-900 dark:text-white">Feature</th>
                        <th className="text-left py-3 px-4 text-gray-900 dark:text-white">MT940</th>
                        <th className="text-left py-3 px-4 text-gray-900 dark:text-white">CAMT.053</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 dark:text-gray-300">
                      <tr className="border-b border-gray-200 dark:border-gray-700">
                        <td className="py-3 px-4">Structure</td>
                        <td className="py-3 px-4">Fixed length, tag-based</td>
                        <td className="py-3 px-4">XML-based, flexible structure</td>
                      </tr>
                      <tr className="border-b border-gray-200 dark:border-gray-700">
                        <td className="py-3 px-4">Character Set</td>
                        <td className="py-3 px-4">Limited (SWIFT)</td>
                        <td className="py-3 px-4">UTF-8 (all characters)</td>
                      </tr>
                      <tr className="border-b border-gray-200 dark:border-gray-700">
                        <td className="py-3 px-4">Transaction Details</td>
                        <td className="py-3 px-4">Basic information</td>
                        <td className="py-3 px-4">Rich transaction data</td>
                      </tr>
                      <tr>
                        <td className="py-3 px-4">Reference Data</td>
                        <td className="py-3 px-4">Limited space</td>
                        <td className="py-3 px-4">Extended references</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-6 bg-gray-50 dark:bg-gray-900 p-6 rounded-lg">
                  <h4 className="font-medium text-gray-900 dark:text-white mb-3">When to Choose Each Format</h4>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <h5 className="text-sm font-semibold text-gray-900 dark:text-white mb-2">Choose CAMT.053 when you need:</h5>
                      <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                        <li className="flex items-start">
                          <CheckCircle className="h-4 w-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
                          <span>Separate fields for references and descriptions (better for automatic reconciliation)</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle className="h-4 w-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
                          <span>Detailed batch payment information including individual transactions</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle className="h-4 w-4 text-green-500 mr-2 mt-1 flex-shrink-0" />
                          <span>Consolidated feedback in a single XML file</span>
                        </li>
                      </ul>
                    </div>
                    
                    <div>
                      <h5 className="text-sm font-semibold text-gray-900 dark:text-white mb-2">Consider MT940 if:</h5>
                      <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                        <li className="flex items-start">
                          <Info className="h-4 w-4 text-blue-500 mr-2 mt-1 flex-shrink-0" />
                          <span>You only need batch payment details at principal level</span>
                        </li>
                        <li className="flex items-start">
                          <Info className="h-4 w-4 text-blue-500 mr-2 mt-1 flex-shrink-0" />
                          <span>Your systems have limited capacity for processing larger XML files</span>
                        </li>
                        <li className="flex items-start">
                          <Info className="h-4 w-4 text-blue-500 mr-2 mt-1 flex-shrink-0" />
                          <span>You require specific MT940 reporting delivery deadlines</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Practical Use Section */}
          <section id="practical-use" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">4. Practical Use in Accounting</h2>
            <div className="space-y-6">
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Benefits for Daily Operations</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">For Accountants</h4>
                    <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                      <li>• Automated reconciliation with accounting software</li>
                      <li>• Detailed transaction categorization</li>
                      <li>• Better audit trail with structured data</li>
                      <li>• Reduced manual data entry</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">For Business Owners</h4>
                    <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                      <li>• Improved cash flow visibility</li>
                      <li>• Better payment tracking</li>
                      <li>• Easier tax preparation</li>
                      <li>• More accurate financial reporting</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Downloading Section */}
          <section id="downloading" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">5. Downloading and Processing Statements</h2>
            <div className="space-y-6">
              <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Step-by-Step Guide</h3>
                <ol className="space-y-4">
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">1</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Log into your bank's business portal</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Look for the statement download section</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">2</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Select CAMT.053 format</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">It might be listed as "XML" or "ISO 20022"</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">3</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Choose date range</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Most banks allow up to 18 months of history</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">4</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Import into your accounting software</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Follow your software's specific import procedure</p>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </section>

          {/* Additional Resources */}
          <section className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Additional Resources
            </h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">
              For more detailed information, consult these resources:
            </p>
            <ul className="space-y-3">
              <li>
                <Link 
                  to="/invoice_processor/mt940-guide"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>Learn about MT940 - The Traditional Format</span>
                </Link>
              </li>
              <li>
                <a 
                  href="https://www.iso20022.org/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>ISO 20022 Documentation</span>
                </a>
              </li>
            </ul>

            <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
              <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
                <h4 className="font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                  <Info className="h-5 w-5 text-yellow-500 mr-2" />
                  Bank Availability Note
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  The transition to CAMT.053 is ongoing across European banks, with full adoption expected by the end of 2025. Contact your bank directly to check their current support status and transition timeline for the CAMT.053 format.
                </p>
              </div>
            </div>
          </section>

          {/* Add a floating "Back to top" button */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="fixed bottom-8 right-8 bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-200"
            aria-label="Back to top"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 10l7-7m0 0l7 7m-7-7v18" 
              />
            </svg>
          </button>
        </main>

        {/* Footer */}
        <footer className="bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              This guide is provided for informational purposes only and should not be considered as legal or financial advice. 
              Always consult with your bank or financial advisor for specific guidance.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default CAMT053Guide; 