import React from 'react';
import { Shield, Lock, Eye, Database, Mail, AlertCircle, Share, ShieldCheck, UserCircle } from 'lucide-react';

const Privacy: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          {/* Introduction */}
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center mb-4">
              <Shield className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                Privacy Policy
              </h1>
            </div>
            <p className="text-gray-600 dark:text-gray-300">
              Last updated: {new Date().toLocaleDateString()}
            </p>
            <p className="mt-4 text-gray-600 dark:text-gray-300">
              At Hermes BI, we take your privacy seriously. This Privacy Policy explains how we collect, use, 
              disclose, and safeguard your information when you use our Invoice Processor Google Apps Add-on. 
              Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, 
              please do not access the application.
            </p>
          </div>

          {/* Information We Access */}
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center mb-4">
              <Database className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Information We Access
              </h2>
            </div>
            <div className="space-y-4">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">Gmail Access</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Our add-on requires access to your Gmail to process invoices. We access:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>Emails matching your specified date range and containing keywords in the subject line (e.g., "invoice", "bill").</li>
                <li>Email attachments of these emails, specifically PDF files.</li>
                <li>Basic email metadata (sender, date, subject) of these emails.</li>
              </ul>
            </div>
            <div className="space-y-4 mt-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">Google Drive Access</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Our add-on requires access to your Google Drive to both retrieve invoices and store processed data. We access:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>Files within the Google Drive folder you specify in the settings.</li>
                <li>We also create a subfolder within your specified folder to store the original invoice files we process.</li>
              </ul>
            </div>
          </div>

          {/* How We Use Your Information */}
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center mb-4">
              <Eye className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                How We Use Your Information
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              The information we access is used solely for:
            </p>
            <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
              <li>Processing and analyzing your invoices to extract relevant data using third-party AI services.</li>
              <li>Storing the extracted invoice data in your specified Google Sheet.</li>
              <li>Saving the original invoice files in your designated Google Drive folder.</li>
              <li>Generating a processing log within your Google Sheet for review.</li>
              <li>Removing duplicate invoice entries from your processing sheet (if enabled).</li>
            </ul>
            <p className="mt-4 text-gray-600 dark:text-gray-300">
              <strong>Important Note About AI Processing:</strong> We do not retain, collect, or use any of your data to train or improve AI/ML models. When processing invoices, the text content is temporarily sent to either Google's Gemini API or OpenAI's API (based on your settings) solely for data extraction purposes. These services process your data according to their respective terms:
            </p>
            <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300 mt-2">
              <li>For Google Gemini processing: Data is handled in accordance with the <a href="https://ai.google.dev/gemini-api/terms" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Gemini API Terms of Service</a></li>
              <li>For OpenAI processing: Data is handled in accordance with <a href="https://openai.com/policies/service-terms" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">OpenAI's Terms of Service</a></li>
            </ul>
            <p className="mt-4 text-gray-600 dark:text-gray-300">
              After processing, we do not retain any copies of your data beyond what is stored in your own Google Workspace account (Sheets and Drive).
            </p>
          </div>

          {/* Information Shared with Third Parties */}
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center mb-4">
              <Share className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Information Shared with Third Parties
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              To provide the invoice processing functionality, we share data with the following third-party services:
            </p>
            <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
              <li><strong>Google AI (Gemini):</strong> If you select Google Gemini as your AI provider, the text content of your invoices (extracted from email attachments or the email body) is sent to Google's Gemini API to extract invoice data.</li>
              <li><strong>OpenAI:</strong> If you select OpenAI as your AI provider, the text content of your invoices (extracted from email attachments or the email body) is sent to OpenAI's API to extract invoice data.</li>
              <li><strong>Stripe:</strong> If you choose to upgrade to a paid subscription, we use Stripe to process payments. We share necessary transaction information with Stripe to manage your subscription.</li>
            </ul>
            <p className="text-gray-600 dark:text-gray-300">
              We only share the minimum necessary information with these third parties to perform the requested services.
            </p>
          </div>

          {/* Data Security */}
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center mb-4">
              <ShieldCheck className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Data Security
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              We are committed to ensuring the security of your information.
            </p>
            <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
              <li>We use secure connections to communicate with third-party services.</li>
              <li>API keys for accessing third-party services (OpenAI, Gemini) are securely stored within the Google Apps Script project's script properties and are not directly accessible to users.</li>
              <li>We adhere to Google's security guidelines for Apps Script add-ons.</li>
            </ul>
          </div>

          {/* Your Data and Control */}
          <div className="p-6">
            <div className="flex items-center mb-4">
              <UserCircle className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Your Data and Control
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              You have control over your data within the add-on:
            </p>
            <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
              <li>You choose the specific Gmail search criteria (date range, keywords) for processing.</li>
              <li>You select the Google Drive folder where invoice files are processed and stored.</li>
              <li>You control which Google Sheet the extracted invoice data is written to.</li>
              <li>You can uninstall the add-on at any time, which will revoke its access to your Google account.</li>
            </ul>
          </div>

          {/* Your Rights */}
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex items-center mb-4">
              <AlertCircle className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Your Rights
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              You have the following rights regarding your data:
            </p>
            <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
              <li>Right to access your personal data</li>
              <li>Right to rectification of inaccurate data</li>
              <li>Right to erasure of your data</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object to processing</li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="p-6">
            <div className="flex items-center mb-4">
              <Mail className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Contact Us
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-300">
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <div className="mt-4">
              <a href="mailto:privacy@hermes-bi.com" className="text-blue-600 dark:text-blue-400 hover:underline">
                privacy@hermes-bi.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy; 