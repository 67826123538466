import React from 'react';
import { FileText, Shield, AlertCircle, Mail, Scale, Clock } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const Terms: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service - Hermes BI</title>
        <meta name="description" content="Terms of service for Hermes BI invoice tools and services." />
        <meta name="robots" content="noindex, nofollow" /> {/* Don't index legal pages */}
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
            {/* Agreement to Terms */}
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center mb-4">
                <FileText className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                  Terms of Service
                </h1>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                Last updated: {new Date().toLocaleDateString()}
              </p>
              <p className="mt-4 text-gray-600 dark:text-gray-300">
                By accessing or using the Hermes BI Invoice Processor service, you agree to be bound by these Terms of Service 
                and our Privacy Policy. If you disagree with any part of the terms, you may not access the service.
              </p>
            </div>

            {/* Description of Service */}
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center mb-4">
                <Shield className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  Description of Service
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                Hermes BI Invoice Processor provides an automated service intended to assist with invoice processing through Gmail integration. The service includes:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>Automated detection of potential invoices from Gmail.</li>
                <li>Extraction of data from detected invoices.</li>
                <li>Organization of extracted data into a spreadsheet.</li>
                <li>Storage of processed invoice files in Google Drive.</li>
              </ul>
            </div>

            {/* User Responsibilities */}
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center mb-4">
                <Scale className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  User Responsibilities
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                As a user of our service, you understand and agree to:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>Provide accurate and complete information necessary for the service to function.</li>
                <li>Maintain the security of your Google account credentials.</li>
                <li>Use the service in compliance with all applicable laws and regulations.</li>
                <li>Not misuse or attempt to manipulate the service's intended functionality.</li>
                <li>Promptly report any security issues or unauthorized access related to your account or the service.</li>
                <li>Be responsible for verifying the accuracy of the data extracted by the service.</li>
              </ul>
            </div>

            {/* Service Limitations and Disclaimer of Warranties */}
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center mb-4">
                <Clock className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  Service Limitations and Disclaimer of Warranties
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                Please be aware of the following limitations and disclaimers regarding the service:
              </p>
              <ul className="list-disc pl-6 text-gray-600 dark:text-gray-300">
                <li>The service is provided on an "as is" and "as available" basis. We make no warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                <li>Processing time may vary depending on factors including but not limited to email volume, invoice complexity, and internet connection speeds.</li>
                <li>The service relies on automated technology for invoice detection and data extraction, which may not be accurate or complete in every instance. You are responsible for verifying the accuracy of the extracted data.</li>
                <li>The service may not be able to process all types of invoice formats or layouts.</li>
                <li>Service availability may be interrupted due to maintenance, technical issues, or factors beyond our control. We are not liable for any loss resulting from service interruptions.</li>
                <li>We do not guarantee the security of your data, although we take reasonable measures to protect it. You are responsible for implementing your own security measures.</li>
                <li>Any analytics and reporting features are provided for informational purposes only and should not be considered professional advice.</li>
                <li>We reserve the right to modify or discontinue the service at any time without notice.</li>
              </ul>
            </div>

            {/* Limitation of Liability */}
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center mb-4">
                <AlertCircle className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  Limitation of Liability
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                To the maximum extent permitted by applicable law, Hermes BI, its officers, directors, employees, or agents, shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the service; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our service by any third party; (e) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the service, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose. In no event shall our aggregate liability exceed the amount you paid us, if any, in the past twelve (12) months for the service giving rise to the claim.
              </p>
            </div>

            {/* Intellectual Property */}
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center mb-4">
                <AlertCircle className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  Intellectual Property
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                The service, including its original content, features, and functionality, is owned by Hermes BI or its licensors and is protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
              </p>
            </div>

            {/* Termination */}
            <div className="p-6 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center mb-4">
                <AlertCircle className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  Termination
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                We may terminate or suspend your access to our service immediately, without prior notice or liability, 
                for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, 
                your right to use the service will immediately cease.
              </p>
            </div>

            {/* Contact Information */}
            <div className="p-6">
              <div className="flex items-center mb-4">
                <Mail className="h-6 w-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  Contact Us
                </h2>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                If you have any questions about these Terms of Service, please contact us at:
              </p>
              <div className="mt-4">
                <a href="mailto:legal@hermes-bi.com" className="text-blue-600 dark:text-blue-400 hover:underline">
                  legal@hermes-bi.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms; 