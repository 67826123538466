import React from 'react';
import { FileText, Info, AlertCircle, CheckCircle, Database, FileCode, Download } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const MT940Guide: React.FC = () => {
  return (
    <>
      <style>
        {`
          .code-line {
            font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
            line-height: 1.5;
            padding: 0.25rem 0;
          }
          .code-line:hover {
            background-color: rgba(55, 65, 81, 0.05);
          }
          .dark .code-line:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          .mt940-tag {
            color: #0891b2;
            font-weight: 500;
          }
          .dark .mt940-tag {
            color: #22d3ee;
          }
          .swift-tag {
            color: #0891b2;
          }
          .dark .swift-tag {
            color: #22d3ee;
          }
          .swift-content {
            color: #059669;
          }
          .dark .swift-content {
            color: #34d399;
          }
        `}
      </style>
      <Helmet>
        <title>MT940 Bank Statement Format Guide - Hermes BI</title>
        <meta name="description" content="Comprehensive guide to MT940 bank statement format. Learn about SWIFT message structure, implementation, and best practices for handling bank statements." />
        <meta name="keywords" content="MT940, SWIFT messages, bank statements, electronic banking, financial messaging" />
        
        <meta property="og:title" content="MT940 Bank Statement Format Guide" />
        <meta property="og:description" content="Comprehensive guide to MT940 bank statement format" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Article Header */}
        <header className="pt-24 pb-12 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Understanding MT940 Bank Statement Format
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              A comprehensive guide to working with MT940 SWIFT messages for bank statements
            </p>
          </div>
        </header>

        {/* Add animated icons section */}
        <div className="bg-white dark:bg-gray-900 py-8 border-b border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                {
                  icon: FileCode,
                  text: "SWIFT Standard",
                  subtext: "Standardized format",
                  animation: "animate-float-1"
                },
                {
                  icon: Database,
                  text: "End-of-Day Statement",
                  subtext: "Daily transactions",
                  animation: "animate-float-2"
                },
                {
                  icon: Download,
                  text: "Global Support",
                  subtext: "Universal compatibility",
                  animation: "animate-float-3"
                },
                {
                  icon: FileText,
                  text: "Structured Data",
                  subtext: "Detailed records",
                  animation: "animate-float-4"
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`flex flex-col items-center ${item.animation}`}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-400/20 rounded-full blur-xl transform scale-150 animate-pulse" />
                    <div className="relative bg-white dark:bg-gray-800 p-4 rounded-full border border-gray-200 dark:border-gray-700 shadow-lg hover:shadow-xl transition-shadow duration-300">
                      <item.icon className="h-8 w-8 text-blue-500 dark:text-blue-400" />
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    <span className="block text-sm font-medium text-gray-900 dark:text-white">
                      {item.text}
                    </span>
                    <span className="block text-xs text-gray-500 dark:text-gray-400 mt-1">
                      {item.subtext}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Introduction */}
          <section className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none mb-12">
            <p className="lead">
              MT940 is a standardized SWIFT message type used for transmitting end-of-day bank account statements. It provides a detailed snapshot of all transactions, including credits, debits, and balance updates, making it essential for businesses managing their financial operations.
            </p>
          </section>

          {/* Table of Contents */}
          <nav className="mb-12 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Contents</h2>
            <ul className="space-y-2">
              <li>
                <a href="#structure" className="text-blue-600 dark:text-blue-400 hover:underline">
                  1. MT940 Message Structure
                </a>
              </li>
              <li>
                <a href="#key-features" className="text-blue-600 dark:text-blue-400 hover:underline">
                  2. Key Features and Benefits
                </a>
              </li>
              <li>
                <a href="#applications" className="text-blue-600 dark:text-blue-400 hover:underline">
                  3. Business Applications
                </a>
              </li>
              <li>
                <a href="#comparison" className="text-blue-600 dark:text-blue-400 hover:underline">
                  4. Comparison with Other Formats
                </a>
              </li>
              <li>
                <a href="#implementation" className="text-blue-600 dark:text-blue-400 hover:underline">
                  5. Implementation Guide
                </a>
              </li>
            </ul>
          </nav>

          {/* MT940 Structure Section */}
          <section id="structure" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">1. MT940 Message Structure</h2>
            <div className="space-y-6">
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Essential Message Tags</h3>
                <div className="space-y-4">
                  <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Mandatory Fields</h4>
                    <ul className="space-y-3 text-gray-600 dark:text-gray-300">
                      <li className="flex items-start">
                        <span className="mt940-tag mr-2">:20:</span>
                        <span>Transaction Reference Number</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mt940-tag mr-2">:25:</span>
                        <span>Account Identification</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mt940-tag mr-2">:28C:</span>
                        <span>Statement Number/Sequence Number</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mt940-tag mr-2">:60a:</span>
                        <span>Opening Balance</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mt940-tag mr-2">:62a:</span>
                        <span>Closing Balance</span>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Optional Fields</h4>
                    <ul className="space-y-3 text-gray-600 dark:text-gray-300">
                      <li className="flex items-start">
                        <span className="mt940-tag mr-2">:61:</span>
                        <span>Statement Line (Transaction Details)</span>
                      </li>
                      <li className="flex items-start">
                        <span className="mt940-tag mr-2">:86:</span>
                        <span>Information to Account Owner</span>
                      </li>
                    </ul>
                  </div>

                  <div className="p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg mt-4">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Format Example</h4>
                    <pre className="overflow-x-auto bg-gray-800 text-gray-100 rounded-lg p-4 text-sm">
{`:20:STARTUMS
:25:NL91ABNA0417164300
:28C:123/1
:60F:C230901EUR1000,00
:61:2309010901DR100,00NTRFNONREF//12345
:86:Betaling aan leverancier
:61:2309010901CR500,00NTRFNONREF//67890
:86:Ontvangen betaling van klant
:62F:C230901EUR1400,00`}
                    </pre>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">
                      This example shows a basic MT940 statement with opening balance, transactions, and closing balance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Key Features Section */}
          <section id="key-features" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">2. Key Features and Benefits</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Core Features</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span className="text-gray-600 dark:text-gray-300">Standardized SWIFT format</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span className="text-gray-600 dark:text-gray-300">Comprehensive transaction data</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span className="text-gray-600 dark:text-gray-300">Global bank compatibility</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Business Benefits</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span className="text-gray-600 dark:text-gray-300">Automated reconciliation</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span className="text-gray-600 dark:text-gray-300">Reduced manual errors</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                    <span className="text-gray-600 dark:text-gray-300">Enhanced cash flow visibility</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* Applications Section */}
          <section id="applications" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">3. Business Applications</h2>
            <div className="space-y-6">
              <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Common Use Cases</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Corporate Finance</h4>
                    <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                      <li>• Multi-bank account management</li>
                      <li>• Cash flow tracking</li>
                      <li>• Financial reporting</li>
                      <li>• Account reconciliation</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">Banking Operations</h4>
                    <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                      <li>• Statement generation</li>
                      <li>• Transaction reporting</li>
                      <li>• Compliance monitoring</li>
                      <li>• Account updates</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Comparison Section */}
          <section id="comparison" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">4. Comparison with Other Formats</h2>
            <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th className="text-left py-3 px-4 text-gray-900 dark:text-white">Feature</th>
                      <th className="text-left py-3 px-4 text-gray-900 dark:text-white">MT940</th>
                      <th className="text-left py-3 px-4 text-gray-900 dark:text-white">MT942</th>
                      <th className="text-left py-3 px-4 text-gray-900 dark:text-white">MT950</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 dark:text-gray-300">
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <td className="py-3 px-4">Update Frequency</td>
                      <td className="py-3 px-4">End of Day</td>
                      <td className="py-3 px-4">Intraday</td>
                      <td className="py-3 px-4">End of Day</td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <td className="py-3 px-4">Primary Use</td>
                      <td className="py-3 px-4">Statement Reporting</td>
                      <td className="py-3 px-4">Real-time Monitoring</td>
                      <td className="py-3 px-4">Direct Account Owner Updates</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4">Detail Level</td>
                      <td className="py-3 px-4">Comprehensive</td>
                      <td className="py-3 px-4">Summary</td>
                      <td className="py-3 px-4">Detailed</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          {/* Implementation Guide */}
          <section id="implementation" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">5. Implementation Guide</h2>
            <div className="space-y-6">
              <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Integration Steps</h3>
                <ol className="space-y-4">
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">1</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Bank Setup</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Arrange MT940 service with your bank</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">2</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Software Configuration</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Set up your accounting or ERP system</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">3</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Testing</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Validate statement processing</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">4</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">Go Live</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">Start production use</p>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </section>

          {/* Additional Resources */}
          <section className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Additional Resources
            </h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">
              For more detailed information, consult these resources:
            </p>
            <ul className="space-y-3">
              <li>
                <Link 
                  to="/invoice_processor/camt053-guide"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>Learn about CAMT.053 - The New Standard Format</span>
                </Link>
              </li>
              <li>
                <a 
                  href="https://www.swift.com/standards"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>SWIFT Standards Documentation</span>
                </a>
              </li>
              <li>
                <a 
                  href="https://www.iso20022.org/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>ISO 20022 Migration Information</span>
                </a>
              </li>
            </ul>

            <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
              <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
                <h4 className="font-medium text-gray-900 dark:text-white mb-2 flex items-center">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mr-2" />
                  Future Considerations
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  While MT940 remains widely used, the industry is gradually transitioning to ISO 20022 XML formats. Consider planning for this transition while implementing your MT940 solution.
                </p>
              </div>
            </div>
          </section>

          {/* Add a floating "Back to top" button */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="fixed bottom-8 right-8 bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-200"
            aria-label="Back to top"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 10l7-7m0 0l7 7m-7-7v18" 
              />
            </svg>
          </button>
        </main>

        {/* Footer */}
        <footer className="bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              This guide is provided for informational purposes only and should not be considered as legal or financial advice. 
              Always consult with your bank or financial advisor for specific guidance.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default MT940Guide; 