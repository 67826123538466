import React from 'react';
import { FileText, Info, AlertCircle, CheckCircle, Euro, Building2, Receipt, Calculator } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const InvoiceRulesNL: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Dutch Invoice Requirements Guide - Hermes BI</title>
        <meta name="description" content="Comprehensive guide to Dutch invoice requirements. Learn about legal requirements, VAT rules, and best practices for business invoicing in the Netherlands." />
        <meta name="keywords" content="Dutch invoice rules, Netherlands invoice requirements, BTW rules, VAT requirements, business invoicing Netherlands" />
        
        <meta property="og:title" content="Dutch Invoice Requirements Guide" />
        <meta property="og:description" content="Comprehensive guide to Dutch invoice requirements" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Article Header */}
        <header className="pt-24 pb-12 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between mb-4">
              {/* Create Invoice button */}
              <Link 
                to="/invoice_processor/invoice-generator" 
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                <FileText className="h-4 w-4 mr-2" />
                Create Invoice
              </Link>
              {/* Language toggle */}
              <div className="flex items-center">
                <Link 
                  to="/invoice_processor/factuur-regels-nl" 
                  className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  Nederlands
                </Link>
                <span className="mx-2 text-gray-400">|</span>
                <span className="text-gray-600">English</span>
              </div>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              How to Make an Invoice in the Netherlands
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              A professional guide to creating compliant invoices that meet Dutch tax regulations and business standards.
            </p>
          </div>
        </header>

        {/* Add new animated icons section right after the header */}
        <div className="bg-white dark:bg-gray-900 py-8 border-b border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                {
                  icon: FileText,
                  text: "Compliant Invoices",
                  animation: "animate-float-1"
                },
                {
                  icon: Euro,
                  text: "Dutch VAT Rules",
                  animation: "animate-float-2"
                },
                {
                  icon: Building2,
                  text: "Business Standards",
                  animation: "animate-float-3"
                },
                {
                  icon: Receipt,
                  text: "Record Keeping",
                  animation: "animate-float-4"
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`flex flex-col items-center ${item.animation}`}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-400/20 rounded-full blur-xl transform scale-150 animate-pulse" />
                    <div className="relative bg-white dark:bg-gray-800 p-4 rounded-full border border-gray-200 dark:border-gray-700 shadow-lg">
                      <item.icon className="h-8 w-8 text-blue-500 dark:text-blue-400" />
                    </div>
                  </div>
                  <span className="mt-4 text-sm font-medium text-gray-600 dark:text-gray-300 text-center">
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Article Content */}
        <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Introduction */}
          <section className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none mb-12">
            <p className="lead">
              As an entrepreneur in the Netherlands, issuing invoices correctly is vital. Whether you supply goods or services to other businesses, 
              legal entities, or private individuals, you must comply with Dutch tax legislation and invoicing standards set by the Tax Administration 
              (Belastingdienst) and the Netherlands Enterprise Agency (RVO). 
            </p>
            <p>
              This guide consolidates official requirements and best practices so you can send professional, compliant invoices every time.
            </p>
          </section>

          {/* Table of Contents */}
          <nav className="mb-12 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Contents</h2>
            <ul className="space-y-2">
              <li>
                <a href="#who-needs-an-invoice" className="text-blue-600 dark:text-blue-400 hover:underline">
                  1. Who Needs an Invoice
                </a>
              </li>
              <li>
                <a href="#required-fields" className="text-blue-600 dark:text-blue-400 hover:underline">
                  2. Required Fields
                </a>
              </li>
              <li>
                <a href="#example" className="text-blue-600 dark:text-blue-400 hover:underline">
                  3. Example Invoice
                </a>
              </li>
              <li>
                <a href="#legal-requirements" className="text-blue-600 dark:text-blue-400 hover:underline">
                  4. Legal Requirements
                </a>
              </li>
              <li>
                <a href="#sending-invoices" className="text-blue-600 dark:text-blue-400 hover:underline">
                  5. Sending Invoices & Payment Terms
                </a>
              </li>
              <li>
                <a href="#record-keeping" className="text-blue-600 dark:text-blue-400 hover:underline">
                  6. Retention & Archiving
                </a>
              </li>
              <li>
                <a href="#e-invoicing" className="text-blue-600 dark:text-blue-400 hover:underline">
                  7. Electronic & Digital Invoicing
                </a>
              </li>
              <li>
                <a href="#best-practices" className="text-blue-600 dark:text-blue-400 hover:underline">
                  8. Best Practices
                </a>
              </li>
            </ul>
          </nav>

          {/* Who Needs an Invoice */}
          <section id="who-needs-an-invoice" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">1. Who Needs an Invoice</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p className="text-gray-600 dark:text-gray-100">
                  Under Dutch tax law, you are required to issue invoices for business transactions. The requirement applies 
                  to the following situations:
                </p>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Required Recipients
                  </h3>
                  <ul className="space-y-3">
                    {[
                      {
                        title: "Business-to-Business (B2B)",
                        description: "All transactions with other entrepreneurs and businesses"
                      },
                      {
                        title: "Legal Entities",
                        description: "Non-business entities such as foundations, associations, and non-profits"
                      },
                      {
                        title: "Government Bodies",
                        description: "Must be sent as e-invoices when dealing with Dutch central government"
                      },
                      {
                        title: "Private Individuals (Specific Cases)",
                        description: "Required for certain transactions like new vehicles sold to EU customers"
                      }
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">{item.title}</span>
                          <p className="text-sm text-gray-600 dark:text-gray-100">{item.description}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Special Considerations
                  </h3>
                  <div className="space-y-4">
                    <div className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">E-invoicing Requirements</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Mandatory electronic invoicing for all Dutch government suppliers since 2017.{' '}
                          <Link 
                            to="/invoice_processor/e-invoicing-guide" 
                            className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                          >
                            Learn more about e-invoicing
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-yellow-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Timing Requirements</span>
                        <p className="text-sm text-gray-600 dark:text-gray-100">
                          Invoices must be issued within 15 days after the end of the month in which goods/services were delivered
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Required Fields Section */}
          <section id="required-fields" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">2. Required Fields</h2>
            <div className="space-y-8">
              <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
                <p className="text-gray-600 dark:text-gray-100">
                  Dutch tax law mandates specific information on invoices for VAT compliance and proper bookkeeping. 
                  Missing or incorrect mandatory information may result in rejected VAT claims or administrative penalties.
                </p>
              </div>

              {/* Simplified Invoice Rules Alert */}
              <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-6">
                <div className="flex items-start">
                  <Info className="h-6 w-6 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Simplified Invoice Rules
                    </h3>
                    <p className="text-gray-600 dark:text-gray-100 mb-3">
                      For invoices under €100 (including VAT) or for invoice corrections, only these details are required:
                    </p>
                    <ul className="space-y-2">
                      {[
                        "Your name and address",
                        "VAT amount",
                        "Invoice date",
                        "Description of goods/services supplied",
                        "For corrections: reference to the initial invoice"
                      ].map((item, index) => (
                        <li key={index} className="flex items-start">
                          <CheckCircle className="h-4 w-4 text-green-500 mr-2 flex-shrink-0 mt-1" />
                          <span className="text-gray-600 dark:text-gray-100 text-sm">{item}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-4 bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded p-3">
                      <p className="text-sm text-yellow-800 dark:text-yellow-200">
                        <strong>Note:</strong> Simplified invoices cannot be used for:
                        <br />• Goods supplied to another EU country (intra-community supplies)
                        <br />• Distance sales
                        <br />• Goods and services in an EU country with reverse-charged VAT
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Standard Invoice Requirements */}
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {[
                  {
                    title: "Mandatory Information",
                    icon: FileText,
                    items: [
                      {
                        text: "Invoice number",
                        mandatory: true
                      },
                      {
                        text: "Invoice date",
                        mandatory: true
                      },
                      {
                        text: "Date of supply/delivery",
                        mandatory: true
                      },
                      {
                        text: "Price per piece/unit (excluding VAT)",
                        mandatory: true
                      },
                      {
                        text: "Any price reductions not in unit price",
                        mandatory: true
                      },
                      {
                        text: "Applied VAT rate",
                        mandatory: true
                      },
                      {
                        text: "Total price excluding VAT",
                        mandatory: true
                      },
                      {
                        text: "VAT amount",
                        mandatory: true
                      },
                      {
                        text: "Advance payment date (if different from invoice date)",
                        mandatory: false,
                        condition: "Required for advance payments"
                      }
                    ]
                  },
                  {
                    title: "Product/Service Details",
                    icon: FileText,
                    items: [
                      {
                        text: "Quantity and type of goods supplied",
                        mandatory: true
                      },
                      {
                        text: "Nature and type of services supplied",
                        mandatory: true
                      }
                    ]
                  },
                  {
                    title: "Business Details",
                    icon: Info,
                    items: [
                      {
                        text: "Supplier name and address",
                        mandatory: true
                      },
                      {
                        text: "Supplier VAT identification number",
                        mandatory: true
                      },
                      {
                        text: "Your KVK number",
                        mandatory: true,
                        condition: "If registered with KVK"
                      }
                    ]
                  },
                  {
                    title: "Customer Information",
                    icon: Info,
                    items: [
                      {
                        text: "Customer name and address",
                        mandatory: true
                      },
                      {
                        text: "Customer VAT number",
                        mandatory: false,
                        condition: "Required for EU transactions and reverse-charging"
                      }
                    ]
                  },
                  {
                    title: "Special Cases",
                    icon: AlertCircle,
                    items: [
                      {
                        text: "Special VAT regulation notation",
                        mandatory: false,
                        condition: "When margin scheme, VAT exemption, or reverse-charging applies"
                      },
                      {
                        text: "Intra-community supply notation",
                        mandatory: false,
                        condition: "For EU supplies"
                      }
                    ]
                  }
                ].map((section, index) => (
                  <div
                    key={index}
                    className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6"
                  >
                    <div className="flex items-center mb-4">
                      <section.icon className="h-5 w-5 text-blue-500 mr-2" />
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        {section.title}
                      </h3>
                    </div>
                    <ul className="space-y-3">
                      {section.items.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className="flex items-start text-sm"
                        >
                          {item.mandatory ? (
                            <span className="text-red-500 mr-2 font-bold">*</span>
                          ) : (
                            <span className="text-gray-400 mr-2">○</span>
                          )}
                          <div>
                            <span className={`${item.mandatory ? 'font-medium' : ''} text-gray-600 dark:text-gray-100`}>
                              {item.text}
                            </span>
                            {item.condition && (
                              <p className="text-xs text-gray-500 dark:text-gray-300 mt-0.5">
                                {item.condition}
                              </p>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="text-sm text-gray-500 dark:text-gray-400">
                <p className="flex items-center">
                  <span className="text-red-500 mr-2 font-bold">*</span>
                  Mandatory field - must be included on all standard invoices
                </p>
                <p className="flex items-center mt-1">
                  <span className="text-gray-400 mr-2">○</span>
                  Optional field - recommended for professional invoicing
                </p>
              </div>
            </div>
          </section>

          {/* Example Invoice Section */}
          <section id="example" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">3. Example Invoice</h2>
            <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6 shadow-sm">
              <div className="space-y-6">
                {/* Supplier Details */}
                <div className="border-b border-gray-200 dark:border-gray-700 pb-4">
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">ABC Company B.V.</h3>
                  <p className="text-gray-600 dark:text-gray-100">
                    123 Business Street<br />
                    1234 AB Amsterdam<br />
                    Netherlands
                  </p>
                  <div className="mt-2 text-gray-600 dark:text-gray-100">
                    <p>KVK: 12345678</p>
                    <p>BTW: NL123456789B01</p>
                  </div>
                </div>

                {/* Invoice Details and Customer Info */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h4 className="font-semibold text-gray-900 dark:text-white mb-2">Bill To:</h4>
                    <p className="text-gray-600 dark:text-gray-100">XYZ GmbH</p>
                    <p className="text-gray-600 dark:text-gray-100">456 Client Avenue</p>
                    <p className="text-gray-600 dark:text-gray-100">10115 Berlin</p>
                    <p className="text-gray-600 dark:text-gray-100">Germany</p>
                    <p className="text-gray-600 dark:text-gray-100 mt-2">VAT: DE987654321</p>
                  </div>
                  <div className="text-right">
                    <p className="text-gray-600 dark:text-gray-100">Invoice #: INV-2024-001</p>
                    <p className="text-gray-600 dark:text-gray-100">Invoice Date: 2024-03-15</p>
                    <p className="text-gray-600 dark:text-gray-100">Delivery Date: 2024-03-15</p>
                    <p className="text-gray-600 dark:text-gray-100">Due Date: 2024-04-14</p>
                  </div>
                </div>

                {/* Services/Products Table */}
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th className="text-left py-2 text-gray-900 dark:text-white">Description</th>
                      <th className="text-right py-2 text-gray-900 dark:text-white">Quantity</th>
                      <th className="text-right py-2 text-gray-900 dark:text-white">Unit Price (ex VAT)</th>
                      <th className="text-right py-2 text-gray-900 dark:text-white">Amount (ex VAT)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 text-gray-600 dark:text-gray-100">
                        Web Development Services
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        10 hours
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        €75.00
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        €750.00
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 text-gray-600 dark:text-gray-100">
                        UI/UX Design Services
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        5 hours
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        €90.00
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        €450.00
                      </td>
                    </tr>
                    {/* Totals */}
                    <tr className="border-t border-gray-200 dark:border-gray-700">
                      <td colSpan={3} className="py-2 text-right text-gray-600 dark:text-gray-100">
                        Subtotal (ex VAT):
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        €1,200.00
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="py-2 text-right text-gray-600 dark:text-gray-100">
                        VAT Amount (21%):
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-gray-100">
                        €252.00
                      </td>
                    </tr>
                    <tr className="border-t border-gray-200 dark:border-gray-700 font-semibold">
                      <td colSpan={3} className="py-2 text-right text-gray-900 dark:text-white">
                        Total Amount (inc VAT):
                      </td>
                      <td className="text-right py-2 text-gray-900 dark:text-white">
                        €1,452.00
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Payment Details */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                  <h4 className="font-semibold text-gray-900 dark:text-white mb-2">Payment Details:</h4>
                  <p className="text-gray-600 dark:text-gray-100">
                    Bank: ING Bank N.V.<br />
                    IBAN: NL91 INGB 0123 4567 89<br />
                    BIC: INGBNL2A<br />
                    Reference: INV-2024-001
                  </p>
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-100">
                    Payment Terms: 30 days<br />
                    Due Date: 2024-04-14
                  </p>
                </div>

                {/* VAT Reverse Charge Notice (for EU B2B) */}
                <div className="text-sm text-gray-600 dark:text-gray-100 italic">
                  VAT Reverse-Charged: Article 196 EU VAT Directive 2006/112/EC applies
                </div>
              </div>
            </div>
          </section>

          {/* Legal Requirements Section */}
          <section id="legal-requirements" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">4. Legal Requirements</h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
              <div className="space-y-6">
                {[
                  {
                    title: "Timing Requirements",
                    description:
                      "Invoices must be issued no later than the 15th day of the month following the month in which goods or services were delivered.",
                    icon: FileText
                  },
                  {
                    title: "Simplified Invoices",
                    description:
                      "If the total amount does not exceed €100 (VAT included), or if it's a rectification of a previously sent invoice, you can use a simplified format. However, certain transactions (e.g., intra-community supplies) always require a full invoice.",
                    icon: Info
                  },
                  {
                    title: "Record Keeping",
                    description:
                      "Keep your invoices for at least 7 years (10 years for immovable property). You must store them in a readable format, identical to how they were issued (paper or digital).",
                    icon: AlertCircle
                  }
                ].map((item, index) => (
                  <div key={index} className="flex items-start space-x-4">
                    <div className="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg">
                      <item.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                        {item.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-100">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mt-8">
                Adjusted Requirements
              </h3>
              <p className="text-gray-600 dark:text-gray-100">
                Certain industries, such as taxi and public transport or hospitality, 
                may have adjusted invoicing rules. Additionally, if you participate 
                in the Small Businesses Scheme (KOR) or are exempt from VAT, you either 
                do not charge VAT or are not required to include it on the invoice. 
                Always verify if your industry is subject to specific regulations.
              </p>
            </div>
          </section>

          {/* Sending Invoices & Payment Terms */}
          <section id="sending-invoices" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              5. Sending Invoices & Payment Terms
            </h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none space-y-6">
              <p>
                Invoices should be sent promptly, ideally right after completing the service or delivering goods. Under Dutch law, 
                you must send the invoice before the 15th day of the month following your delivery. 
              </p>
              <p>
                State a clear payment term on your invoice. Common terms are 14 or 30 days. 
                If you wait too long to issue an invoice or exceed the limitation period, your customers may no longer be obliged to pay.
              </p>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Limitation Period
              </h3>
              <ul>
                <li>
                  <strong>5 years</strong> after the payment term expires (generally for B2B invoices)
                </li>
                <li>
                  <strong>2 years</strong> for products sold to private individuals
                </li>
                <li>
                  <strong>5 years</strong> if you sell services or travel packages to private individuals
                </li>
              </ul>
              <p>
                You can interrupt (stuiten) the limitation period by sending a registered reminder letter, which restarts the limitation clock.
              </p>
            </div>
          </section>

          {/* Retention Period and Archiving */}
          <section id="record-keeping" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              6. Retention & Archiving
            </h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none space-y-4">
              <p>
                Your invoices are part of your business records and must be kept in their original format (paper or digital) for 
                <strong> 7 years</strong>. If the invoices relate to immovable property, the retention period is 
                <strong> 10 years</strong>. 
              </p>
              <p>
                During this period, the Tax Administration may audit your records. Whether you choose paper or software-based 
                storage, ensure your system is secure, organized, and compliant with the General Data Protection Regulation (GDPR).
              </p>
            </div>
          </section>

          {/* Electronic and Digital Invoicing */}
          <section id="e-invoicing" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              7. Electronic &amp; Digital Invoicing
            </h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none space-y-6">
              <p>
                You may send an invoice by post, email, or through electronic (e-invoicing) channels, as long as it contains 
                all mandatory details. However, if you supply goods or services to the Dutch central government, 
                <strong> e-invoicing is mandatory</strong>.
              </p>
              <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-4 my-4">
                <p className="text-sm text-blue-800 dark:text-blue-200">
                  <Info className="h-4 w-4 inline-block mr-2" />
                  For a comprehensive guide on e-invoicing, including implementation steps and upcoming changes, see our 
                  <Link to="/invoice_processor/e-invoicing-guide" className="text-blue-600 dark:text-blue-400 hover:underline ml-1">
                    E-Invoicing Guide for Dutch Businesses
                  </Link>.
                </p>
              </div>
              <p>
                <strong>Digital Invoice:</strong> Typically a PDF sent via email. It should mirror the same information and layout 
                as a paper invoice.
              </p>
              <p>
                <strong>Electronic Invoice (E-invoice):</strong> Sent through a secure system or accounting software. 
                This structured format automatically populates relevant fields, ensuring accuracy and quick processing. 
                Many EU member states encourage e-invoicing due to its efficiency and reliability.
              </p>
            </div>
          </section>

          {/* Best Practices Section */}
          <section id="best-practices" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">8. Best Practices</h2>
            <div className="prose dark:prose-invert dark:text-white lg:prose-lg max-w-none">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-gray-100">
                    Adopt professional formatting with clear branding and contact details.
                  </span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-gray-100">
                    Include detailed descriptions of goods or services delivered.
                  </span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-gray-100">
                    Specify clear payment terms and due dates.
                  </span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-gray-100">
                    Use invoicing or accounting software to maintain compliance and accuracy.
                  </span>
                </li>
              </ul>
            </div>
          </section>

          {/* Additional Resources */}
          <section className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Additional Resources
            </h2>
            <p className="text-gray-600 dark:text-gray-100 mb-4">
              For more detailed information, consult these official resources:
            </p>
            <ul className="space-y-3">
              <li>
                <a 
                  href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontenten/belastingdienst/business/vat/vat_in_the_netherlands/vat_administration/invoice_requirements"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>Dutch Tax Authority - Official Invoice Requirements</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 ml-6">
                  Complete overview of VAT invoice requirements from the Dutch Tax Administration
                </p>
              </li>
              <li>
                <Link 
                  to="/invoice_processor/e-invoicing-guide"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>E-Invoicing Guide for Dutch Businesses</span>
                </Link>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 ml-6">
                  Comprehensive guide to e-invoicing implementation and requirements
                </p>
              </li>
              <li>
                <a 
                  href="https://www.kvk.nl/en/starting/writing-an-invoice/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>KVK Guide - Writing an Invoice</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 ml-6">
                  Practical guide from the Netherlands Chamber of Commerce
                </p>
              </li>
              <li>
                <a 
                  href="https://business.gov.nl/regulation/invoice-requirements/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>Business.gov.nl - Invoice Requirements</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 ml-6">
                  Official government information for entrepreneurs
                </p>
              </li>
            </ul>
            <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Last updated: {new Date().toLocaleDateString()} • Information sourced from official Dutch government resources
              </p>
            </div>
          </section>

          {/* Add a floating "Back to top" button */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="fixed bottom-8 right-8 bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-200"
            aria-label="Back to top"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 10l7-7m0 0l7 7m-7-7v18" 
              />
            </svg>
          </button>
        </main>

        {/* Add a footer */}
        <footer className="bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              This guide is provided for informational purposes only and should not be considered as legal advice. 
              Always consult with a qualified tax advisor or accountant for specific guidance.
            </p>
          </div>
        </footer>

        {/* Add a section at the end of the article */}
        <section className="py-16 bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">
              Ready to Create Your Invoice?
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-100 mb-8 max-w-2xl mx-auto">
              Use our free invoice generator to create professional, compliant invoices in minutes
            </p>
            <Link 
              to="/invoice_processor/invoice-generator" 
              className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors text-lg font-semibold shadow-lg"
            >
              <FileText className="h-6 w-6 mr-3" />
              Create Your Invoice Now
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default InvoiceRulesNL;
