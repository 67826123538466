import React, { Component, ErrorInfo, ReactNode } from 'react';
import { supabase } from '../utils/database';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class AuthErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Auth error:', error, errorInfo);
    // Attempt to recover by signing out
    supabase.auth.signOut().then(() => {
      window.location.href = '/login';
    });
  }

  public render() {
    if (this.state.hasError) {
      return <div>Sorry.. there was an authentication error. Redirecting to login...</div>;
    }

    return this.props.children;
  }
}

export default AuthErrorBoundary; 