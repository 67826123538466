import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY || '';
export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    storage: window.localStorage,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});

export interface CompanyDetails {
  id?: string;
  user_id: string;
  company_name: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  kvk_number: string;
  vat_number: string;
  bank_name: string;
  iban: string;
  bic: string;
  created_at?: string;
  updated_at?: string;
}
  
export const saveCompanyDetails = async (details: Omit<CompanyDetails, 'created_at' | 'updated_at' | 'id'>) => {
  try {
    console.log('Saving company details:', details);

    // Get the current session
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError) throw sessionError;
    
    if (!session?.user) {
      throw new Error('No authenticated session');
    }

    // First, check if a record exists
    const { data: existingData, error: fetchError } = await supabase
      .from('user_company_details')
      .select('id')
      .eq('user_id', session.user.id)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') { // PGRST116 is the "not found" error code
      throw fetchError;
    }

    let result;
    if (existingData?.id) {
      // Update existing record
      result = await supabase
        .from('user_company_details')
        .update({
          ...details,
          user_id: session.user.id,
          updated_at: new Date().toISOString()
        })
        .eq('id', existingData.id)
        .select();
    } else {
      // Insert new record
      result = await supabase
        .from('user_company_details')
        .insert([{
          ...details,
          user_id: session.user.id
        }])
        .select();
    }

    if (result.error) {
      console.error('Supabase error:', result.error);
      throw result.error;
    }

    console.log('Save successful:', result.data);
    return result.data;
  } catch (error) {
    console.error('Error saving company details:', error);
    throw error;
  }
};

export const getCompanyDetails = async (): Promise<CompanyDetails | null> => {
  try {
    // Get the current session
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError) throw sessionError;
    
    if (!session?.user) {
      throw new Error('No authenticated session');
    }

    console.log('Fetching company details for user:', session.user.id);

    const { data, error } = await supabase
      .from('user_company_details')
      .select('*')
      .eq('user_id', session.user.id)
      .single();

    if (error) {
      console.error('Supabase error:', error);
      throw error;
    }

    console.log('Fetch successful:', data);
    return data;
  } catch (error) {
    console.error('Error fetching company details:', error);
    throw error;
  }
}; 