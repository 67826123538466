import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface AnalyticsContextType {
  isAnalyticsEnabled: boolean;
}

const AnalyticsContext = createContext<AnalyticsContextType>({ isAnalyticsEnabled: false });

export const useAnalytics = () => useContext(AnalyticsContext);

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

const SENSITIVE_ROUTES = ['/login', '/profile', '/invoice_processor/privacy'];

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  const location = useLocation();
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(false);

  useEffect(() => {
    // Check if current route is sensitive
    const isSensitivePage = SENSITIVE_ROUTES.some(route => location.pathname.startsWith(route));
    setIsAnalyticsEnabled(!isSensitivePage);
  }, [location]);

  // Lazy load Google Tag Manager
  useEffect(() => {
    if (!isAnalyticsEnabled) return;

    const loadGTM = async () => {
      const scriptAsync = document.createElement('script');
      scriptAsync.async = true;
      scriptAsync.src = "https://www.googletagmanager.com/gtag/js?id=AW-16676678730";

      const scriptInline = document.createElement('script');
      scriptInline.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16676678730');
      `;

      // Add scripts to head
      document.head.appendChild(scriptAsync);
      document.head.appendChild(scriptInline);

      return () => {
        document.head.removeChild(scriptAsync);
        document.head.removeChild(scriptInline);
      };
    };

    loadGTM();
  }, [isAnalyticsEnabled]);

  // Lazy load Yandex Metrika
  useEffect(() => {
    if (!isAnalyticsEnabled) return;

    const loadYandexMetrika = async () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = `
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        ym(98042431, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });
      `;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadYandexMetrika();
  }, [isAnalyticsEnabled]);

  return (
    <AnalyticsContext.Provider value={{ isAnalyticsEnabled }}>
      {children}
    </AnalyticsContext.Provider>
  );
}; 