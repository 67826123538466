import React, { useState, useRef } from 'react';
import { Mail, Shield, Lock, FileText, Database, ChartBar, AlertCircle, CheckCircle, MailIcon, FolderIcon, Icon, SettingsIcon, ShieldCheckIcon, BarChartIcon, StarIcon, LucideProps, MessageCircle, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL || '',
  process.env.REACT_APP_SUPABASE_ANON_KEY || ''
);

type SubscriptionTier = {
    name: string;
    maxCredits: number | 'Custom';
    price: number | 'Custom';
    pricePerInvoice: number | 'Custom';
    savings?: string;  // Make it optional with ?
    features: string[];
  };

const GmailIcon = () => (
  <svg viewBox="0 0 24 24" className="h-8 w-8">
    <path
      fill="#EA4335"
      d="M24 5.457v13.909c0 .904-.732 1.636-1.636 1.636h-3.819V11.73L12 16.64l-6.545-4.91v9.273H1.636A1.636 1.636 0 0 1 0 19.366V5.457c0-2.023 2.309-3.178 3.927-1.964L5.455 4.64 12 9.548l6.545-4.91 1.528-1.145C21.69 2.28 24 3.434 24 5.457z"
    />
  </svg>
);

const DriveIcon = () => (
  <img 
    src="https://fonts.gstatic.com/s/i/productlogos/drive_2020q4/v8/web-64dp/logo_drive_2020q4_color_2x_web_64dp.png"
    alt="Google Drive"
    className="h-8 w-8"
  />
);

const SheetsIcon = () => (
  <img 
    src="https://fonts.gstatic.com/s/i/productlogos/sheets_2020q4/v8/web-64dp/logo_sheets_2020q4_color_2x_web_64dp.png"
    alt="Google Sheets"
    className="h-8 w-8"
  />
);

type Feature = {
  title: string;
  description: string;
  icon: React.ComponentType<LucideProps>;
};

const features: Feature[] = [
  {
    title: "Smart Email Processing",
    description: "Automatically detect and process invoice emails using customizable keywords. Supports both PDF attachments and email body content.",
    icon: Mail,
  },
  {
    title: "Bulk Drive Processing",
    description: "Process multiple invoices from any Google Drive folder. Perfect for handling backlog or batch processing.",
    icon: FolderIcon,
  },
  {
    title: "AI-Powered Extraction",
    description: "We process even complex invoices with ease. We can extract data from invoices that are not in a standard format.",
    icon: SettingsIcon,
  },
  {
    title: "Missing Fields Detection",
    description: "Automatically identify missing or incorrect fields based on Dutch invoice requirements and your custom rules.",
    icon: AlertCircle,
  },
  {
    title: "One-Click Follow Up",
    description: "Draft and send follow-up emails to suppliers for missing information with a single click.",
    icon: Mail,
  },
  {
    title: "Error-Free Data Entry",
    description: "Eliminate manual data entry errors with AI-powered extraction, ensuring accuracy in your financial records.",
    icon: ShieldCheckIcon,
  }
];

const SUBSCRIPTION_TIERS: Record<string, SubscriptionTier> = {
  FREE: {
    name: 'Free',
    maxCredits: 20,
    price: 0,
    pricePerInvoice: 0,
    features: [
      'Process up to 20 invoices/month',
      'Gmail & Drive integration',
      'AI-powered extraction',
      'Usage analytics'
    ]
  },
  BASIC: {
    name: 'Basic',
    maxCredits: 500,
    price: 20,
    pricePerInvoice: 0.04,
    savings: '60%', // compared to manual processing
    features: [
      'Process up to 500 invoices/month',
      'Gmail & Drive integration',
      'AI-powered extraction',
      'Usage analytics',
      'Email support'
    ]
  },
  ADVANCED: {
    name: 'Advanced',
    maxCredits: 1000,
    price: 35,
    pricePerInvoice: 0.035,
    savings: '65%', // compared to manual processing
    features: [
      'Process up to 1000 invoices/month',
      'Gmail & Drive integration',
      'AI-powered extraction',
      'Usage analytics',
      'Priority support'
    ]
  },
  ENTERPRISE: {
    name: 'Enterprise',
    maxCredits: 'Custom',
    price: 'Custom',
    pricePerInvoice: 'Custom',
    savings: '70+%', // compared to manual processing
    features: [
      'Unlimited invoice processing',
      'Custom volume pricing',
      'API access',
      'Dedicated support',
      'Custom integrations',
      'SLA guarantee'
    ]
  }
};

type SubscriptionStatus = 'subscribing' | 'subscribed' | 'unsubscribing' | 'unsubscribed' | 'error' | '';

const InvoiceProcessor: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');
  const [playingVideo, setPlayingVideo] = useState<number | null>(null);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [subscribeStatus, setSubscribeStatus] = useState<string>('');
  const [isUnsubscribing, setIsUnsubscribing] = useState<boolean>(false);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const overlayRef = useRef<HTMLDivElement>(null);
  const betaFormRef = useRef<HTMLDivElement>(null);
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);

  const testDatabaseAccess = async () => {
    console.log('Testing database access...');
    
    try {
      // Test table access
      const { data: tableInfo, error: tableError } = await supabase
        .from('beta_testers')
        .select('count');
      
      console.log('Table access test:', { tableInfo, tableError });

      // Test specific email query
      const testEmail = 'ilja.golubev@yandex.ru';
      const { data: emailData, error: emailError } = await supabase
        .from('beta_testers')
        .select('*')
        .eq('email', testEmail);

      console.log('Email query test:', { emailData, emailError });

      // Test raw count
      const { count, error: countError } = await supabase
        .from('beta_testers')
        .select('*', { count: 'exact' });

      console.log('Total records:', { count, countError });

    } catch (error) {
      console.error('Database test error:', error);
    }
  };

  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubscribeStatus('Subscribing...');

    try {
      // Check if email already exists using ilike for case-insensitive match
      const { data: existingUsers, error: fetchError } = await supabase
        .from('beta_testers')
        .select('id, subscribed')
        .ilike('email', email.toLowerCase());

      if (fetchError) {
        console.error('Error checking email:', fetchError);
        throw fetchError;
      }

      const existingUser = existingUsers?.[0];

      if (existingUser) {
        if (existingUser.subscribed) {
          setSubscribeStatus('This email is already subscribed to the beta program.');
          return;
        }

        // If found but unsubscribed, resubscribe
        const { error: updateError } = await supabase
          .from('beta_testers')
          .update({ 
            subscribed: true,
            updated_at: new Date().toISOString()
          })
          .eq('id', existingUser.id); // Use ID instead of email for update

        if (updateError) throw updateError;
      } else {
        // New subscription
        const { error: insertError } = await supabase
          .from('beta_testers')
          .insert([{ 
            email: email.toLowerCase(), // Store email in lowercase
            subscribed: true,
            updated_at: new Date().toISOString()
          }]);

        if (insertError) throw insertError;
      }

      setEmail('');
      setSubscribeStatus('Thanks! You\'re on the beta testing list. We\'ll be in touch soon!');
    } catch (error) {
      console.error('Error in subscribe process:', error);
      setSubscribeStatus('An error occurred. Please try again.');
    }

    setTimeout(() => setSubscribeStatus(''), 5000);
  };

  const handleUnsubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubscribeStatus('Unsubscribing...');

    try {
      const emailToCheck = email.toLowerCase().trim();
      console.log('Searching for email:', emailToCheck);

      // Simple direct query
      const { data: users, error: queryError } = await supabase
        .from('beta_testers')
        .select('*')
        .eq('email', emailToCheck);

      console.log('Query result:', { users, queryError });

      if (queryError) {
        console.error('Error querying email:', queryError);
        setSubscribeStatus('An error occurred while checking the email.');
        return;
      }

      const existingUser = users?.[0];

      if (!existingUser) {
        setSubscribeStatus('Email not found in our system.');
        return;
      }

      if (!existingUser.subscribed) {
        setSubscribeStatus('This email is already unsubscribed.');
        return;
      }

      // Update subscription status
      const { error: updateError } = await supabase
        .from('beta_testers')
        .update({ 
          subscribed: false,
          updated_at: new Date().toISOString()
        })
        .eq('id', existingUser.id);

      if (updateError) {
        console.error('Error unsubscribing:', updateError);
        throw updateError;
      }

      setEmail('');
      setIsUnsubscribing(false);
      setSubscribeStatus('You have been successfully unsubscribed.');
    } catch (error) {
      console.error('Error in unsubscribe process:', error);
      setSubscribeStatus('An error occurred while unsubscribing. Please try again.');
    }
  };

  const scrollToBetaForm = () => {
    betaFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Close fullscreen when ESC is pressed
  React.useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsFullscreen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  React.useEffect(() => {
    testDatabaseAccess();
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Invoice Processor for Google Workspace",
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web-based",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "AI-powered invoice processing solution that extracts data from Gmail & Drive directly to Google Sheets with 80% cost savings."
  };

  return (
    <>
      <Helmet>
        <title>Invoice Processor for Google Workspace | Automate Invoice Data Extraction</title>
        <meta name="description" content="Save 80% on invoice processing costs. AI-powered solution to automatically extract data from invoices in Gmail & Drive directly to Google Sheets. Start free." />
        <meta name="keywords" content="invoice processing, invoice automation, Google Workspace, Gmail integration, invoice data extraction, AI invoice processing" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      {/* Fullscreen Video Overlay */}
      {isFullscreen && playingVideo !== null && (
        <div 
          ref={overlayRef}
          className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center"
          onClick={(e) => {
            if (e.target === overlayRef.current) {
              setIsFullscreen(false);
            }
          }}
        >
          <div className="relative w-full max-w-5xl mx-4">
            <button
              onClick={() => setIsFullscreen(false)}
              className="absolute -top-12 right-0 text-white hover:text-gray-300 transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="aspect-video bg-black rounded-lg overflow-hidden">
              <video
                key={`fullscreen-${playingVideo}`}
                className="w-full h-full"
                controls
                autoPlay
                src={loadedVideos.includes(playingVideo) ? [
                  {
                    step: 1,
                    videoUrl: "/videos/setup-demo.mp4",
                  },
                  {
                    step: 2,
                    videoUrl: "/videos/process-demo.mp4",
                  },
                  {
                    step: 3,
                    title: "Review & Share",
                    description: "Access your structured invoice data in Google Sheets, with each row linked to both the original email and the stored PDF. Your accountant can easily trace every entry back to its source, making audits and verifications effortless.",
                    videoUrl: "/videos/review-demo.mp4",
                  }
                ][playingVideo].videoUrl : undefined}
              />
            </div>
          </div>
        </div>
      )}

      <div className={`min-h-screen font-sans ${darkMode ? 'dark' : ''}`}>
        {/* Hero Section */}
        <header className="bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900 py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-end mb-4" aria-label="Secondary navigation">
              <Link 
                to={language === 'nl' ? "factuur-regels-nl" : "invoice-rules-nl"} 
                className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 flex items-center gap-2"
                aria-label={language === 'nl' ? 'Nederlandse Factuurrichtlijnen' : 'Dutch Invoice Guidelines'}
              >
                <FileText className="h-4 w-4" aria-hidden="true" />
                {language === 'nl' ? 'Nederlandse Factuurrichtlijnen' : 'Dutch Invoice Guidelines'}
              </Link>
            </nav>
            <div className="text-center">
              <div className="flex items-center justify-center gap-4 mb-6">
                <GmailIcon />
                <span className="text-2xl font-bold text-gray-600">→</span>
                <FileText className="h-8 w-8 text-blue-600" />
                <span className="text-2xl font-bold text-gray-600">→</span>
                <SheetsIcon />
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent leading-relaxed overflow-visible" style={{ height: 'auto', minHeight: '52px' }}>
                Stop searching your mailbox for invoices
              </h1>
              <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
                Get all your invoices reasy for accountant in one click - our tool will find all invoices in Gmail, extract their data, and organize it in Google Drive and prepare a report for your accountant in Google Sheets.
              </p>
              <div className="flex justify-center gap-4">
                <button 
                  onClick={scrollToBetaForm}
                  className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
                  aria-label="Process invoices from Gmail"
                >
                  Process from Gmail
                </button>
                <button 
                  onClick={scrollToBetaForm}
                  className="border border-blue-600 text-blue-600 dark:text-blue-400 px-8 py-3 rounded-lg hover:bg-blue-50 dark:hover:bg-blue-900/20 transition duration-300"
                  aria-label="Process invoices from Drive"
                >
                  Process from Drive
                </button>
                <Link 
                  to={`invoice-generator${language === 'nl' ? '?lang=nl' : ''}`}
                  className="bg-green-600 text-white px-8 py-3 rounded-lg hover:bg-green-700 transition duration-300 flex items-center"
                  aria-label={language === 'nl' ? 'Factuur Maken' : 'Create Invoice'}
                >
                  <FileText className="h-5 w-5 mr-2" aria-hidden="true" />
                  {language === 'nl' ? 'Factuur Maken' : 'Create Invoice'}
                </Link>
              </div>
            </div>
          </div>
        </header>

        <main>
          {/* Integration Section */}
          <section aria-labelledby="integration-section" className="py-16 bg-gray-50 dark:bg-gray-800">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                  Three Simple Steps to Invoice Management
                </h2>
                <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
                  Seamlessly integrated with your Google Workspace for effortless invoice processing
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Step 1: Find & Process */}
                <div className="text-center">
                  <div className="inline-flex items-center justify-center p-3 bg-blue-100 dark:bg-blue-900 rounded-xl mb-4">
                    <div className="flex items-center gap-2">
                      <GmailIcon />
                      <DriveIcon />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Find & Process Invoices</h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    One click to find and process all invoices from Gmail or Drive. No more manual searching or downloading.
                  </p>
                </div>
                {/* Step 2: Organize in Drive */}
                <div className="text-center">
                  <div className="inline-flex items-center justify-center p-3 bg-yellow-100 dark:bg-green-900 rounded-xl mb-4">
                    <DriveIcon />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Structured Storage in Drive</h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    All invoices are automatically saved and organized in your Google Drive with smart naming and folder structure.
                  </p>
                </div>
                {/* Step 3: Share with Accountant */}
                <div className="text-center">
                  <div className="inline-flex items-center justify-center p-3 bg-green-100 dark:bg-green-900 rounded-xl mb-4">
                    <SheetsIcon />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Ready for Your Accountant</h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    Share organized data in Sheets with direct links to original emails and stored files in Drive. Everything connected and traceable.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Steps Section */}
          <section aria-labelledby="steps-section" className="py-16 bg-white dark:bg-gray-900">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                  How it works?
                </h2>
                <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
                  Automate invoice data extraction from Gmail and Drive to Google Sheets
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                {[
                  {
                    step: 1,
                    title: "Install & Configure",
                    description: "Install the Invoice Processor add-on and configure your preferred settings, including the destination Google Sheet and Drive folder.",
                    icon: Database,
                    videoUrl: "/videos/setup-demo.mp4",
                    fallbackImage: "/images/setup-preview.jpg"
                  },
                  {
                    step: 2,
                    title: "Process Invoices",
                    description: "Choose to process invoices from a Gmail date range or a specific Google Drive folder.",
                    icon: SettingsIcon,
                    videoUrl: "/videos/process-demo.mp4",
                    fallbackImage: "/images/process-preview.jpg"
                  },
                  {
                    step: 3,
                    title: "Review & Share",
                    description: "Access your structured invoice data in Google Sheets, with each row linked to both the original email and the stored PDF. Your accountant can easily trace every entry back to its source, making audits and verifications effortless.",
                    icon: MailIcon,
                    videoUrl: "/videos/review-demo.mp4",
                    fallbackImage: "/images/review-preview.jpg"
                  }
                ].map((step, index) => (
                  <div key={index} className="relative">
                    <div className="bg-white dark:bg-gray-900 rounded-xl shadow-lg overflow-hidden">
                      {/* Video Preview */}
                      <div 
                        className="aspect-video bg-gray-100 dark:bg-gray-800 relative group cursor-pointer"
                        onClick={() => {
                          if (playingVideo === index) {
                            if (!isFullscreen) {
                              setIsFullscreen(true);
                            } else {
                              videoRefs.current[index]?.pause();
                              setPlayingVideo(null);
                            }
                          } else {
                            // Pause any currently playing video
                            if (playingVideo !== null && videoRefs.current[playingVideo]) {
                              videoRefs.current[playingVideo]?.pause();
                            }
                            // Load the video if not already loaded
                            if (!loadedVideos.includes(index)) {
                              setLoadedVideos(prev => [...prev, index]);
                            }
                            videoRefs.current[index]?.play();
                            setPlayingVideo(index);
                          }
                        }}
                      >
                        <video 
                          ref={el => videoRefs.current[index] = el}
                          className="w-full h-full object-cover"
                          poster={step.fallbackImage}
                          muted
                          playsInline
                          onEnded={() => {
                            setPlayingVideo(null);
                            setIsFullscreen(false);
                          }}
                        >
                          {loadedVideos.includes(index) && (
                            <source src={step.videoUrl} type="video/mp4" />
                          )}
                          Your browser does not support the video tag.
                        </video>
                        {playingVideo !== index && (
                          <div className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-20 transition-all flex items-center justify-center">
                            <div className="w-20 h-20 bg-white/90 rounded-full flex items-center justify-center group-hover:scale-110 transition-transform shadow-lg">
                              <div className="w-0 h-0 border-y-[15px] border-y-transparent border-l-[25px] border-l-blue-600 ml-2" />
                            </div>
                          </div>
                        )}
                        {playingVideo === index && !isFullscreen && (
                          <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
                            <button 
                              className="bg-white/90 p-2 rounded-lg shadow-lg hover:bg-white transition-colors"
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsFullscreen(true);
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>

                      {/* Content */}
                      <div className="p-6">
                        <div className="flex items-center mb-4">
                          <div className="bg-blue-100 dark:bg-blue-900/30 p-2 rounded-full mr-4">
                            <step.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                          </div>
                          <span className="text-2xl font-bold text-blue-600 dark:text-blue-400">Step {step.step}</span>
                        </div>
                        <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{step.title}</h3>
                        <p className="text-gray-600 dark:text-gray-300">{step.description}</p>
                      </div>
                    </div>
                    
                    {/* Connection Arrow */}
                    {index < 2 && (
                      <div className="hidden md:block absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 z-10">
                        <div className="w-12 h-12 bg-white dark:bg-gray-900 rounded-full shadow-lg flex items-center justify-center">
                          <div className="w-6 h-6 border-t-2 border-r-2 border-blue-600 dark:border-blue-400 transform rotate-45"></div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </section>

          

          {/* Features Section */}
          <section aria-labelledby="features-heading" className="py-16 bg-gray-50 dark:bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 id="features-heading" className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                  Key Features
                </h2>
                <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
                  AI-powered invoice processing built for Google Workspace
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" role="list">
                {features.map((feature, index) => (
                  <div 
                    key={index} 
                    className="text-center p-6"
                    role="listitem"
                  >
                    <feature.icon size={48} className="mx-auto mb-4 text-blue-600 dark:text-blue-400" aria-hidden="true" />
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{feature.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Missing Fields & Follow Up Section */}
          <section aria-labelledby="missing-fields-section" className="py-16 bg-white dark:bg-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                  Smart Missing Fields Detection
                </h2>
                <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
                  Automatically identify missing fields and get them resolved with one-click follow-ups
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-xl">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white flex items-center">
                    <AlertCircle className="h-6 w-6 text-yellow-500 mr-2" />
                    Missing Fields Detection
                  </h3>
                  <ul className="space-y-3">
                    {[
                      "Checks against Dutch invoice requirements",
                      "Validates VAT numbers and calculations",
                      "Verifies mandatory fields presence",
                      "Identifies incomplete supplier details",
                      "Validates dates and payment terms"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-600 dark:text-gray-300">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-xl">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white flex items-center">
                    <Mail className="h-6 w-6 text-blue-500 mr-2" />
                    Smart Email Follow-ups
                  </h3>
                  <ul className="space-y-3">
                    {[
                      "AI-generated email drafts for missing information",
                      "Customizable email templates",
                      "Batch processing for multiple suppliers",
                      "Track follow-up status and responses",
                      "Direct integration with Gmail"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-600 dark:text-gray-300">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="bg-blue-50 dark:bg-blue-900/20 p-8 rounded-xl">
                <div className="flex items-start space-x-4">
                  <div className="bg-blue-100 dark:bg-blue-800 p-3 rounded-lg">
                    <StarIcon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
                      Automated Workflow
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mb-4">
                      Our AI-powered system streamlines the entire process:
                    </p>
                    <ol className="space-y-2">
                      <li className="flex items-center">
                        <span className="bg-blue-100 dark:bg-blue-800 w-6 h-6 rounded-full flex items-center justify-center text-blue-600 dark:text-blue-400 mr-3 flex-shrink-0">1</span>
                        <span className="text-gray-600 dark:text-gray-300">Scans incoming invoices for missing or incorrect information</span>
                      </li>
                      <li className="flex items-center">
                        <span className="bg-blue-100 dark:bg-blue-800 w-6 h-6 rounded-full flex items-center justify-center text-blue-600 dark:text-blue-400 mr-3 flex-shrink-0">2</span>
                        <span className="text-gray-600 dark:text-gray-300">Generates personalized follow-up emails based on missing fields</span>
                      </li>
                      <li className="flex items-center">
                        <span className="bg-blue-100 dark:bg-blue-800 w-6 h-6 rounded-full flex items-center justify-center text-blue-600 dark:text-blue-400 mr-3 flex-shrink-0">3</span>
                        <span className="text-gray-600 dark:text-gray-300">Sends and tracks follow-up emails with one click</span>
                      </li>
                      <li className="flex items-center">
                        <span className="bg-blue-100 dark:bg-blue-800 w-6 h-6 rounded-full flex items-center justify-center text-blue-600 dark:text-blue-400 mr-3 flex-shrink-0">4</span>
                        <span className="text-gray-600 dark:text-gray-300">Updates invoice status automatically when missing information is received</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Security Section */}
          <section aria-labelledby="security-section" className="py-16 bg-gradient-to-b from-blue-50 to-white dark:from-blue-900/20 dark:to-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">
                Security & Privacy
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {[
                  {
                    icon: Shield,
                    title: "Data Security",
                    description: "Utilizes Google's secure infrastructure for processing and storing your invoice data."
                  },
                  {
                    icon: Lock,
                    title: "Google Account Integration",
                    description: "Secure authentication via your Google account – we don't store your credentials."
                  },
                  {
                    icon: AlertCircle,
                    title: "Privacy Focused",
                    description: "We never store or collect your data. All processing is done directly through Google AI or Azure's secure infrastructure, ensuring your data stays within your control."
                  },
                  {
                    icon: CheckCircle,
                    title: "Permissions",
                    description: "Requires only necessary permissions to process your invoices and store the data in your Google account."
                  }
                ].map((item, index) => (
                  <div key={index} className="flex items-start p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <div className="bg-blue-100 dark:bg-blue-900/30 p-2 rounded-lg mr-4">
                      <item.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{item.title}</h3>
                      <p className="text-gray-600 dark:text-gray-300">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Pricing Section */}
          <section aria-labelledby="pricing-heading" className="py-16 bg-gray-50 dark:bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 id="pricing-heading" className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                  Simple, Transparent Pricing
                </h2>
                <p className="text-xl text-gray-600 dark:text-gray-300">
                  Choose the plan that fits your needs
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8" role="list">
                {Object.values(SUBSCRIPTION_TIERS).map((tier) => (
                  <div 
                    key={tier.name} 
                    className={`
                      bg-white dark:bg-gray-900 rounded-lg shadow-lg p-8 flex flex-col
                      ${tier.name === 'Advanced' ? 'border-2 border-blue-500' : ''}
                    `}
                    role="listitem"
                    aria-labelledby={`tier-${tier.name}`}
                  >
                    <div className="flex flex-col h-full">
                      {/* Header */}
                      <div className="text-center mb-8">
                        <h3 id={`tier-${tier.name}`} className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                          {tier.name}
                        </h3>
                      </div>

                      {/* Pricing */}
                      <div className="text-center mb-8">
                        <div className="min-h-[65px]">
                          <span className="text-4xl font-bold text-gray-900 dark:text-white">
                            {typeof tier.price === 'number' ? `$${tier.price}` : tier.price}
                          </span>
                          {typeof tier.price === 'number' && 
                            <span className="text-gray-600 dark:text-gray-400"> / month</span>
                          }
                          {tier.pricePerInvoice !== 'Custom' && tier.price !== 0 && (
                            <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                              ${tier.pricePerInvoice} per invoice
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Features */}
                      <div className="flex-grow mb-8">
                        <ul className="text-left space-y-3" role="list" aria-label={`Features included in ${tier.name} plan`}>
                          {tier.features.map((feature, index) => (
                            <li key={index} className="flex items-center text-gray-600 dark:text-gray-300" role="listitem">
                              <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" aria-hidden="true" />
                              <span>{feature}</span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Button */}
                      <div className="mt-auto">
                        <button 
                          onClick={scrollToBetaForm}
                          className={`
                            w-full py-3 px-6 rounded-lg transition duration-300
                            ${tier.name === 'Enterprise' 
                              ? 'bg-gray-800 text-white hover:bg-gray-900'
                              : 'bg-blue-600 text-white hover:bg-blue-700'}
                          `}
                          aria-label={tier.name === 'Enterprise' ? 'Contact sales for Enterprise plan' : `Join beta testing for ${tier.name} plan`}
                        >
                          {tier.name === 'Enterprise' ? 'Contact Sales' : 'Join Beta Testing'}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          
          {/* Beta Testing Section */}
          <section 
            ref={betaFormRef}
            aria-labelledby="beta-section" 
            className="py-16 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900"
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
              <div className="inline-flex items-center justify-center p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full mb-4">
                <StarIcon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
              </div>
              <h2 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">
                Join Our Exclusive Beta Testing Program
              </h2>
              <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
                Be among the first to try our AI-powered invoice processing solution. Beta testers get:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12 max-w-3xl mx-auto">
                <div className="flex items-start">
                  <div className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-md w-full">
                    <CheckCircle className="h-8 w-8 text-green-500 mx-auto mb-2" />
                    <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Free Access</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">Extended free trial period with unlimited processing</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-md w-full">
                    <MessageCircle className="h-8 w-8 text-blue-500 mx-auto mb-2" />
                    <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Direct Support</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">Priority access to our development team</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-md w-full">
                    <Star className="h-8 w-8 text-yellow-500 mx-auto mb-2" />
                    <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Early Access</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">First to try new features and improvements</p>
                  </div>
                </div>
              </div>
              <div className="max-w-md mx-auto">
                {!isUnsubscribing ? (
                  <>
                    <form onSubmit={handleEmailSubmit} className="flex flex-col" aria-label="Beta testing signup form">
                      <div className="flex mb-2">
                        <label htmlFor="signup-email" className="sr-only">Email address</label>
                        <input
                          id="signup-email"
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="flex-grow px-4 py-3 text-gray-700 rounded-l-lg focus:outline-none border border-r-0 border-gray-300 dark:border-gray-600 dark:bg-gray-800 dark:text-white"
                          required
                          aria-required="true"
                          aria-describedby="email-hint email-status"
                        />
                        <button
                          type="submit"
                          className="bg-blue-600 text-white px-6 py-3 rounded-r-lg hover:bg-blue-700 transition duration-300 font-medium"
                          aria-label="Join beta testing program"
                        >
                          Join Beta
                        </button>
                      </div>
                      {subscribeStatus && (
                        <p 
                          id="email-status"
                          className={`text-sm mt-2 ${subscribeStatus.includes('error') ? 'text-red-500' : 'text-green-500'}`}
                          role="status"
                          aria-live="polite"
                        >
                          {subscribeStatus}
                        </p>
                      )}
                      <p id="email-hint" className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                        Limited spots available. Join now to secure your place!
                      </p>
                      <button
                        type="button"
                        onClick={() => setIsUnsubscribing(true)}
                        className="text-xs text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 mt-2 underline"
                        aria-label="Switch to unsubscribe form"
                      >
                        Want to unsubscribe?
                      </button>
                    </form>
                  </>
                ) : (
                  <>
                    <form onSubmit={handleUnsubscribe} className="flex flex-col" aria-label="Beta testing unsubscribe form">
                      <div className="flex mb-2">
                        <label htmlFor="unsubscribe-email" className="sr-only">Email address to unsubscribe</label>
                        <input
                          id="unsubscribe-email"
                          type="email"
                          placeholder="Enter email to unsubscribe"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="flex-grow px-4 py-3 text-gray-700 rounded-l-lg focus:outline-none border border-r-0 border-gray-300 dark:border-gray-600 dark:bg-gray-800 dark:text-white"
                          required
                          aria-required="true"
                          aria-describedby="unsubscribe-status"
                        />
                        <button
                          type="submit"
                          className="bg-gray-600 text-white px-6 py-3 rounded-r-lg hover:bg-gray-700 transition duration-300 font-medium"
                          aria-label="Unsubscribe from beta testing program"
                        >
                          Unsubscribe
                        </button>
                      </div>
                      {subscribeStatus && (
                        <p 
                          id="unsubscribe-status"
                          className={`text-sm mt-2 ${subscribeStatus.includes('error') ? 'text-red-500' : 'text-green-500'}`}
                          role="status"
                          aria-live="polite"
                        >
                          {subscribeStatus}
                        </p>
                      )}
                      <button
                        type="button"
                        onClick={() => setIsUnsubscribing(false)}
                        className="text-xs text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 mt-2 underline"
                        aria-label="Switch back to signup form"
                      >
                        Back to signup
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </section>
        </main>

        <footer className="bg-gray-900 text-white py-8">
          <div className="max-w-7xl mx-auto px-4">
            <p className="text-center text-sm">
              © {new Date().getFullYear()} Invoice Processor for Google Workspace. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default InvoiceProcessor;