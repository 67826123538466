import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import LandingPage from './LandingPage';
import Layout from './pages/invoice_processor/Layout';
import InvoiceProcessor from './pages/invoice_processor/InvoiceProcessor';
import Privacy from './pages/invoice_processor/Privacy';
import Terms from './pages/invoice_processor/Terms';
import InvoiceRulesNL from './pages/invoice_processor/Dutch/InvoiceRulesNL';
import FactuurRegelsNL from './pages/invoice_processor/Dutch/FactuurRegelsNL';
import InvoiceGenerator from './pages/invoice_processor/InvoiceGenerator';
import CAMT053Guide from './pages/invoice_processor/CAMT053Guide';
import MT940Guide from './pages/invoice_processor/MT940Guide';
import EInvoicingGuide from './pages/invoice_processor/EInvoicingGuideNL';
import EFacturatieGidsNL from './pages/invoice_processor/Dutch/EFacturatieGidsNL';
import Login from './pages/auth/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Profile from './pages/auth/Profile';
import AuthErrorBoundary from './components/AuthErrorBoundary';
import ScrollToTop from './components/ScrollToTop';

// Make sure this is your actual client ID from Google Cloud Console
const GOOGLE_CLIENT_ID = '748227572731-7ss2vn2p0algd8l8tmvu1emi0lmebv58.apps.googleusercontent.com';

const App: React.FC = () => {
  if (!GOOGLE_CLIENT_ID) {
    console.error('Google Client ID is not configured');
    return <div>Error: Google Client ID is not configured</div>;
  }

  return (
    <HelmetProvider>
      <GoogleOAuthProvider 
        clientId={GOOGLE_CLIENT_ID}
        onScriptLoadError={() => console.error('Google OAuth script failed to load')}
        onScriptLoadSuccess={() => console.log('Google OAuth script loaded successfully')}
      >
        <Router>
          <ScrollToTop />
          <AuthErrorBoundary>
            <AuthProvider>
              <AnalyticsProvider>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/invoice_processor" element={<Layout />}>
                    <Route index element={<InvoiceProcessor />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="tos" element={<Terms />} />
                    <Route path="invoice-rules-nl" element={<InvoiceRulesNL />} />
                    <Route path="factuur-regels-nl" element={<FactuurRegelsNL />} />
                    <Route path="invoice-generator" element={<InvoiceGenerator />} />
                    <Route path="camt053-guide" element={<CAMT053Guide />} />
                    <Route path="mt940-guide" element={<MT940Guide />} />
                    <Route path="e-invoicing-guide" element={<EInvoicingGuide />} />
                    <Route path="e-facturatie-gids-nl" element={<EFacturatieGidsNL />} />
                  </Route>
                </Routes>
              </AnalyticsProvider>
            </AuthProvider>
          </AuthErrorBoundary>
        </Router>
      </GoogleOAuthProvider>
    </HelmetProvider>
  );
};

export default App;