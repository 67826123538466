import React, { useState, useEffect } from 'react';
import { FileText, Upload, Download, Plus, Trash2, Info, HelpCircle, ExternalLink, Save, LogIn } from 'lucide-react';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import InvoicePDFTemplate from './InvoicePDFTemplate';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { invoiceTranslations } from './translations';
import type { Language, Translation } from './translations';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../contexts/AuthContext';
import { useCompanyDetails } from '../../hooks/useCompanyDetails';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';

interface InvoiceItem {
  description: string;
  quantity: number;
  unitPrice: number;
  vatRate: number;
}

interface CompanyDetails {
  name: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  kvkNumber: string;
  vatNumber: string;
}

interface ValidationErrors {
  [key: string]: string;
}

interface FormState {
  language: Language;
}

const initialFormState: FormState = {
  language: 'nl'
};

const getTranslation = (t: (key: keyof Translation) => string | string[], key: keyof Translation): string => {
  const translation = t(key);
  return Array.isArray(translation) ? translation.join(' ') : translation;
};

const Tooltip: React.FC<{
  children: React.ReactNode;
  content: string;
}> = ({ children, content }) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute z-10 invisible group-hover:visible bg-gray-900 text-white text-sm rounded py-1 px-2 -right-2 transform translate-x-full w-64">
        {content}
        <div className="absolute left-0 top-1/2 transform -translate-x-1 -translate-y-1/2 border-8 border-transparent border-r-gray-900" />
      </div>
    </div>
  );
};

const validateInvoice = (
  invoiceNumber: string,
  invoiceDate: string,
  deliveryDate: string,
  dueDate: string,
  supplierDetails: CompanyDetails,
  customerDetails: CompanyDetails,
  items: InvoiceItem[],
  bankDetails: { bankName: string; iban: string; bic: string },
  t: (key: keyof Translation) => string | string[]
): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!invoiceNumber) errors.invoiceNumber = getTranslation(t, 'invoiceNumberRequired');
  if (!invoiceDate) errors.invoiceDate = getTranslation(t, 'invoiceDateRequired');
  if (!deliveryDate) errors.deliveryDate = getTranslation(t, 'deliveryDateRequired');
  if (!dueDate) errors.dueDate = getTranslation(t, 'dueDateRequired');

  if (!supplierDetails.name) errors.supplierName = getTranslation(t, 'companyNameRequired');
  if (!supplierDetails.vatNumber) errors.supplierVat = getTranslation(t, 'companyVatRequired');

  if (!customerDetails.name) errors.customerName = getTranslation(t, 'customerNameRequired');

  items.forEach((item, index) => {
    if (!item.description) {
      errors[`item${index}Description`] = getTranslation(t, 'itemDescriptionRequired').replace('{0}', (index + 1).toString());
    }
    if (!item.quantity || item.quantity < 1) {
      errors[`item${index}Quantity`] = getTranslation(t, 'itemQuantityRequired').replace('{0}', (index + 1).toString());
    }
    if (item.unitPrice < 0) {
      errors[`item${index}Price`] = getTranslation(t, 'itemPriceNegative').replace('{0}', (index + 1).toString());
    }
  });

  if (!bankDetails.bankName) errors.bankName = getTranslation(t, 'bankNameRequired');
  if (!bankDetails.iban) errors.iban = getTranslation(t, 'ibanRequired');

  return errors;
};

const getMetaDescription = (t: (key: keyof Translation) => string | string[]) => {
  return getTranslation(t, 'metaDescription');
};

const getBreadcrumbStructuredData = (t: (key: keyof Translation) => string | string[]) => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": getTranslation(t, 'siteName'),
      "item": window.location.origin
    }, {
      "@type": "ListItem",
      "position": 2,
      "name": getTranslation(t, 'invoiceGenerator'),
      "item": window.location.href
    }]
  };
};

const getFAQStructuredData = (t: (key: keyof Translation) => string | string[]) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": getTranslation(t, 'faqWhat'),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": getTranslation(t, 'faqWhatAnswer')
        }
      },
      {
        "@type": "Question",
        "name": getTranslation(t, 'faqHow'),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": getTranslation(t, 'faqHowAnswer')
        }
      }
    ]
  };
};

const getStructuredData = (t: (key: keyof Translation) => string | string[]) => {
  return {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": getTranslation(t, 'invoiceGenerator'),
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "EUR"
    },
    "description": getMetaDescription(t),
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "156"
    }
  };
};

export const InvoiceGenerator = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { companyDetails, isLoading: isLoadingCompanyDetails, error: companyDetailsError, updateCompanyDetails } = useCompanyDetails();
  const [saveStatus, setSaveStatus] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState<Language>(
    searchParams.get('lang') === 'nl' ? 'nl' : 'en'
  );
  const t = (key: keyof Translation) => invoiceTranslations[language][key];

  const [logo, setLogo] = useState<string | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  
  const [supplierDetails, setSupplierDetails] = useState<CompanyDetails>({
    name: '',
    address: '',
    postcode: '',
    city: '',
    country: 'Netherlands',
    kvkNumber: '',
    vatNumber: '',
  });

  const [customerDetails, setCustomerDetails] = useState<CompanyDetails>({
    name: '',
    address: '',
    postcode: '',
    city: '',
    country: '',
    kvkNumber: '',
    vatNumber: '',
  });

  const [items, setItems] = useState<InvoiceItem[]>([{
    description: '',
    quantity: 1,
    unitPrice: 0,
    vatRate: 21,
  }]);

  const [bankDetails, setBankDetails] = useState({
    bankName: '',
    iban: '',
    bic: '',
  });

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  // Load saved company details when component mounts
  useEffect(() => {
    if (companyDetails) {
      setSupplierDetails({
        name: companyDetails.company_name,
        address: companyDetails.address,
        postcode: companyDetails.postcode,
        city: companyDetails.city,
        country: companyDetails.country,
        kvkNumber: companyDetails.kvk_number,
        vatNumber: companyDetails.vat_number,
      });
      setBankDetails({
        bankName: companyDetails.bank_name,
        iban: companyDetails.iban,
        bic: companyDetails.bic,
      });
    }
  }, [companyDetails]);

  // Save company details
  const handleSaveCompanyDetails = async () => {
    if (!user) return;

    try {
      await updateCompanyDetails({
        company_name: supplierDetails.name,
        address: supplierDetails.address,
        postcode: supplierDetails.postcode,
        city: supplierDetails.city,
        country: supplierDetails.country,
        kvk_number: supplierDetails.kvkNumber,
        vat_number: supplierDetails.vatNumber,
        bank_name: bankDetails.bankName,
        iban: bankDetails.iban,
        bic: bankDetails.bic,
      });
      setSaveStatus('Company details saved successfully!');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      setSaveStatus('Failed to save company details. Please try again.');
      setTimeout(() => setSaveStatus(''), 3000);
    }
  };

  useEffect(() => {
    const errors = validateInvoice(
      invoiceNumber,
      invoiceDate,
      deliveryDate,
      dueDate,
      supplierDetails,
      customerDetails,
      items,
      bankDetails,
      t
    );
    setValidationErrors(errors);
  }, [invoiceNumber, invoiceDate, deliveryDate, dueDate, supplierDetails, customerDetails, items, bankDetails, language]);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const addItem = () => {
    setItems([...items, {
      description: '',
      quantity: 1,
      unitPrice: 0,
      vatRate: 21,
    }]);
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const updateItem = (index: number, field: keyof InvoiceItem, value: number | string) => {
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [field]: value,
    };
    setItems(newItems);
  };

  const calculateTotals = () => {
    return items.reduce((acc, item) => {
      const subtotal = item.quantity * item.unitPrice;
      const vat = subtotal * (item.vatRate / 100);
      return {
        subtotal: acc.subtotal + subtotal,
        vat: acc.vat + vat,
        total: acc.total + subtotal + vat,
      };
    }, { subtotal: 0, vat: 0, total: 0 });
  };

  const generatePDF = async () => {
    const blob = await pdf(
      <InvoicePDFTemplate
        logo={logo}
        invoiceNumber={invoiceNumber}
        invoiceDate={invoiceDate}
        deliveryDate={deliveryDate}
        dueDate={dueDate}
        supplierDetails={supplierDetails}
        customerDetails={customerDetails}
        items={items}
        bankDetails={bankDetails}
        totals={calculateTotals()}
        language={language}
      />
    ).toBlob();
    saveAs(blob, `invoice-${invoiceNumber}.pdf`);
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 py-12">
      <Helmet>
        <html lang={language} />
        <title>{`${getTranslation(t, 'invoiceGenerator')} - ${getTranslation(t, 'siteName')}`}</title>
        <meta name="description" content={getMetaDescription(t)} />
        <meta name="keywords" content={getTranslation(t, 'metaKeywords')} />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={`${getTranslation(t, 'invoiceGenerator')} - ${getTranslation(t, 'siteName')}`} />
        <meta property="og:description" content={getMetaDescription(t)} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={getTranslation(t, 'siteName')} />
        <meta property="og:locale" content={language === 'nl' ? 'nl_NL' : 'en_US'} />
        <meta property="og:image" content="/invoice-generator-preview.jpg" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${getTranslation(t, 'invoiceGenerator')} - ${getTranslation(t, 'siteName')}`} />
        <meta name="twitter:description" content={getMetaDescription(t)} />
        <meta name="twitter:image" content="/invoice-generator-preview.jpg" />

        {/* Canonical URL */}
        <link rel="canonical" href={`${window.location.origin}/${language}/invoice-generator`} />

        {/* Language alternates */}
        <link rel="alternate" href={`${window.location.origin}/en/invoice-generator`} hrefLang="en" />
        <link rel="alternate" href={`${window.location.origin}/nl/factuur-generator`} hrefLang="nl" />
        <link rel="alternate" href={`${window.location.origin}/nl/factuur-generator`} hrefLang="x-default" />

        {/* Additional meta tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="author" content={getTranslation(t, 'siteName')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(getStructuredData(t))}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(getBreadcrumbStructuredData(t))}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(getFAQStructuredData(t))}
        </script>
      </Helmet>

      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <nav aria-label="Language selection">
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value as Language)}
                className="px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="en">English</option>
                <option value="nl">Nederlands</option>
              </select>
            </nav>
            {!user && (
              <MuiAlert 
                severity="info"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    startIcon={<LogIn className="h-4 w-4" />}
                    onClick={() => navigate('/login')}
                  >
                    Login
                  </Button>
                }
                sx={{ mb: 0 }}
              >
                Want to save your company details for future invoices? Login or create an account!
              </MuiAlert>
            )}
          </div>

          {user && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveCompanyDetails}
              disabled={isSaving}
              startIcon={<Save />}
              sx={{ mt: 2 }}
            >
              {isSaving ? 'Saving...' : 'Save Company Details'}
            </Button>
          )}

          {saveStatus && (
            <MuiAlert 
              severity={saveStatus.includes('success') ? 'success' : 'error'}
              sx={{ mt: 2 }}
            >
              {saveStatus}
            </MuiAlert>
          )}

          {isLoadingCompanyDetails && (
            <div className="mb-4 p-4 bg-blue-50 text-blue-800 border border-blue-200 rounded-md">
              Loading your company details...
            </div>
          )}

          {companyDetailsError && (
            <div className="mb-4 p-4 bg-red-50 text-red-800 border border-red-200 rounded-md">
              Error loading company details: {companyDetailsError}
            </div>
          )}

          <div className="flex items-center justify-between">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              {t('invoiceGenerator')}
            </h1>
            <Link
              to="/invoice_processor/invoice-rules-nl"
              className="inline-flex items-center text-sm text-blue-600 dark:text-blue-400 hover:underline"
            >
              <HelpCircle className="h-4 w-4 mr-1" />
              {t('viewDutchInvoiceRules')}
            </Link>
          </div>
          <p className="mt-2 text-gray-600 dark:text-gray-200">
            {t('createProfessionalInvoices')}
          </p>
        </header>

        <article className="space-y-8">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {t('companyLogo')}
              </h2>
              <Tooltip content={t('logoTooltip') as string}>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <HelpCircle className="h-5 w-5" />
                </button>
              </Tooltip>
            </div>
            <div className="flex items-center space-x-4">
              {logo && (
                <img src={logo} alt="Company logo" className="h-16 w-16 object-contain" />
              )}
              <label className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 cursor-pointer">
                <Upload className="h-5 w-5 mr-2" />
                {t('uploadLogo')}
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleLogoUpload}
                />
              </label>
            </div>
          </div>

          <section className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {t('invoiceDetails')}
              </h2>
              <Tooltip content={getTranslation(t, 'datesRequired')}>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <HelpCircle className="h-5 w-5" />
                </button>
              </Tooltip>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  {t('invoiceNumberLabel')}
                </label>
                <input
                  type="text"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  {t('invoiceDateLabel')}
                </label>
                <input
                  type="date"
                  placeholder={getTranslation(t, 'dateFormat')}
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  {t('deliveryDateLabel')}
                </label>
                <input
                  type="date"
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  {t('dueDateLabel')}
                </label>
                <input
                  type="date"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>
            </div>
          </section>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {t('yourCompanyDetails')}
                </h2>
                <Tooltip content={getTranslation(t, 'vatRequired')}>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HelpCircle className="h-5 w-5" />
                  </button>
                </Tooltip>
              </div>
              <div className="space-y-4">
                {Object.entries(supplierDetails).map(([key, value]) => (
                  <div key={key}>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                      {t(key as keyof Translation)} 
                      {(key === 'name' || key === 'vatNumber') ? t('requiredField') : t('optionalField')}
                    </label>
                    <input
                      type="text"
                      value={value}
                      onChange={(e) => setSupplierDetails({
                        ...supplierDetails,
                        [key]: e.target.value,
                      })}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required={key === 'name' || key === 'vatNumber'}
                    />
                  </div>
                ))}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                    {t('countryLabel')}
                  </label>
                  <input
                    type="text"
                    value={supplierDetails.country}
                    onChange={(e) => setSupplierDetails({
                      ...supplierDetails,
                      country: e.target.value,
                    })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    placeholder={getTranslation(t, 'netherlands')}
                  />
                </div>
              </div>
            </div>

            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {t('customerDetails')}
                </h2>
                <Tooltip content={getTranslation(t, 'customerVatTooltip')}>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HelpCircle className="h-5 w-5" />
                  </button>
                </Tooltip>
              </div>
              <div className="space-y-4">
                {Object.entries(customerDetails).map(([key, value]) => (
                  <div key={key}>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                      {t(key as keyof Translation)} 
                      {key === 'name' ? t('requiredField') : t('optionalField')}
                    </label>
                    <input
                      type="text"
                      value={value}
                      onChange={(e) => setCustomerDetails({
                        ...customerDetails,
                        [key]: e.target.value,
                      })}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required={key === 'name'}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {t('invoiceItems')}
              </h2>
              <Tooltip content={getTranslation(t, 'itemsTooltip')}>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <HelpCircle className="h-5 w-5" />
                </button>
              </Tooltip>
            </div>
            <div className="space-y-4">
              {items.map((item, index) => (
                <div key={index} className="flex items-start space-x-4">
                  <div className="flex-grow grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                        {t('descriptionLabel')}
                      </label>
                      <input
                        type="text"
                        value={item.description}
                        onChange={(e) => updateItem(index, 'description', e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                        {t('quantityLabel')}
                      </label>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) => updateItem(index, 'quantity', Number(e.target.value))}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        required
                        min="1"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                        {t('unitPriceLabel')}
                      </label>
                      <input
                        type="number"
                        value={item.unitPrice}
                        onChange={(e) => updateItem(index, 'unitPrice', Number(e.target.value))}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        required
                        min="0"
                        step="0.01"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                        {t('vatRateLabel')}
                      </label>
                      <select
                        value={item.vatRate}
                        onChange={(e) => updateItem(index, 'vatRate', Number(e.target.value))}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        required
                      >
                        <option value={0}>{t('vatRate0')}</option>
                        <option value={9}>{t('vatRate9')}</option>
                        <option value={21}>{t('vatRate21')}</option>
                      </select>
                    </div>
                  </div>
                  {items.length > 1 && (
                    <button
                      onClick={() => removeItem(index)}
                      className="mt-6 p-2 text-red-600 hover:text-red-700"
                      title={getTranslation(t, 'removeItem')}
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  )}
                </div>
              ))}
              <button
                onClick={addItem}
                className="flex items-center px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600"
                aria-label={getTranslation(t, 'addItem')}
              >
                <Plus className="h-5 w-5 mr-2" />
                {t('addItem')}
              </button>
            </div>

            <div className="mt-6 border-t border-gray-200 dark:border-gray-700 pt-4">
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600 dark:text-gray-200">{t('subtotalExVat')}:</span>
                  <span className="font-medium dark:text-white">€{calculateTotals().subtotal.toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600 dark:text-gray-200">{t('vat')}:</span>
                  <span className="font-medium dark:text-white">€{calculateTotals().vat.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-lg font-bold">
                  <span className="dark:text-white">{t('totalIncVat')}:</span>
                  <span className="dark:text-white">€{calculateTotals().total.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {t('bankDetails')}
              </h2>
              <Tooltip content={getTranslation(t, 'bankTooltip')}>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <HelpCircle className="h-5 w-5" />
                </button>
              </Tooltip>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  {t('bankNameLabel')}
                </label>
                <input
                  type="text"
                  value={bankDetails.bankName}
                  onChange={(e) => setBankDetails({ ...bankDetails, bankName: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  {t('ibanLabel')}
                </label>
                <input
                  type="text"
                  value={bankDetails.iban}
                  onChange={(e) => setBankDetails({ ...bankDetails, iban: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                  {t('bicSwiftLabel')}
                </label>
                <input
                  type="text"
                  value={bankDetails.bic}
                  onChange={(e) => setBankDetails({ ...bankDetails, bic: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
            </div>
          </div>

          <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-6">
            <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4 text-sm text-gray-600 dark:text-gray-200">
              <div className="flex items-start space-x-2">
                <Info className="h-5 w-5 text-gray-400 flex-shrink-0 mt-0.5" />
                <div>
                  <p className="font-medium mb-2">{t('disclaimer')}</p>
                  <p className="mb-2">
                    {t('disclaimerText')}
                  </p>
                  <p className="mb-4">
                    {t('consultText')}
                  </p>
                  <div className="space-y-2">
                    <a
                      href="https://business.gov.nl/regulation/invoice-requirements/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-blue-600 dark:text-blue-400 hover:underline"
                    >
                      <ExternalLink className="h-4 w-4 mr-1" />
                      {t('dutchGuide')}
                    </a>
                    <Link
                      to="/invoice_processor/invoice-rules-nl"
                      className="flex items-center text-blue-600 dark:text-blue-400 hover:underline"
                    >
                      <FileText className="h-4 w-4 mr-1" />
                      {t('completeGuide')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {Object.keys(validationErrors).length > 0 && (
            <div className="mb-6 bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded-lg p-4">
              <div className="flex items-start">
                <Info className="h-5 w-5 text-yellow-500 mr-3 flex-shrink-0 mt-0.5" />
                <div>
                  <h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-200 mb-2">
                    {t('missingFields')}
                  </h3>
                  <ul className="text-sm text-yellow-700 dark:text-yellow-300 space-y-1">
                    {Object.values(validationErrors).map((error, index) => (
                      <li key={index}>• {error}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end">
            <button
              onClick={generatePDF}
              className={`flex items-center px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
                Object.keys(validationErrors).length > 0 ? 'opacity-90' : ''
              }`}
            >
              <FileText className="h-5 w-5 mr-2" />
              {t('downloadPDF')}
              {Object.keys(validationErrors).length > 0 && (
                <span className="ml-2 text-sm">
                  ({Object.keys(validationErrors).length} {t('warnings')})
                </span>
              )}
            </button>
          </div>
        </article>

        <footer className="mt-12 border-t border-gray-200 dark:border-gray-700 pt-8">
          <nav aria-label="Related resources" className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">{t('resourcesTitle')}</h2>
              <ul className="space-y-2">
                <li>
                  <Link to="/invoice_processor/invoice-rules-nl" className="text-blue-600 dark:text-blue-400 hover:underline">
                    {t('invoiceRulesLink')}
                  </Link>
                </li>
                <li>
                  <Link to="/invoice_processor/tos" className="text-blue-600 dark:text-blue-400 hover:underline">
                    {t('termsLink')}
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </footer>

        <section className="mt-12 bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">{t('frequentlyAskedQuestions')}</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">{t('faqWhat')}</h3>
              <p className="text-gray-600 dark:text-gray-200">{t('faqWhatAnswer')}</p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">{t('faqHow')}</h3>
              <p className="text-gray-600 dark:text-gray-200">{t('faqHowAnswer')}</p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default InvoiceGenerator; 