// First, let's define the translation type
export interface Translation {
  invoice: string;
  invoiceNumber: string;
  invoiceDate: string;
  deliveryDate: string;
  dueDate: string;
  from: string;
  billTo: string;
  description: string;
  quantity: string;
  unitPrice: string;
  vatRate: string;
  amount: string;
  subtotalExVat: string;
  vat: string;
  totalIncVat: string;
  paymentDetails: string;
  bank: string;
  paymentTerms: string;
  paymentTermsDays: string;
  invoiceGenerator: string;
  createProfessionalInvoices: string;
  viewDutchInvoiceRules: string;
  quickTips: string;
  tipSequentialNumbers: string;
  tipVatNumbers: string;
  tipKeepCopies: string;
  tipSimplifiedInvoices: string;
  companyLogo: string;
  uploadLogo: string;
  invoiceDetails: string;
  yourCompanyDetails: string;
  customerDetails: string;
  invoiceItems: string;
  addItem: string;
  bankDetails: string;
  disclaimer: string;
  disclaimerText: string;
  consultText: string;
  dutchGuide: string;
  completeGuide: string;
  missingFields: string;
  downloadPDF: string;
  warnings: string;
  logoTooltip: string;
  datesRequired: string;
  vatRequired: string;
  customerVatTooltip: string;
  itemsTooltip: string;
  bankTooltip: string;
  kvkNumber: string;
  vatNumber: string;
  name: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  bankName: string;
  iban: string;
  bicSwift: string;
  required: string;
  invoiceNumberRequired: string;
  invoiceDateRequired: string;
  deliveryDateRequired: string;
  dueDateRequired: string;
  companyNameRequired: string;
  companyVatRequired: string;
  customerNameRequired: string;
  itemDescriptionRequired: string;
  itemQuantityRequired: string;
  itemPriceNegative: string;
  bankNameRequired: string;
  ibanRequired: string;
  quickTipsTitle: string;
  requiredField: string;
  optionalField: string;
  netherlands: string;
  quickTipsSection: string;
  dateFormat: string;
  vatRate21: string;
  tipsList: string[];
  invoiceNumberLabel: string;
  invoiceDateLabel: string;
  deliveryDateLabel: string;
  dueDateLabel: string;
  descriptionLabel: string;
  quantityLabel: string;
  unitPriceLabel: string;
  vatRateLabel: string;
  bankNameLabel: string;
  ibanLabel: string;
  bicSwiftLabel: string;
  countryLabel: string;
  removeItem: string;
  generateInvoice: string;
  selectVatRate: string;
  vatRate0: string;
  vatRate9: string;
  siteName: string;
  metaDescription: string;
  metaKeywords: string;
  resourcesTitle: string;
  invoiceRulesLink: string;
  termsLink: string;
  home: string;
  frequentlyAskedQuestions: string;
  faqWhat: string;
  faqWhatAnswer: string;
  faqHow: string;
  faqHowAnswer: string;
}

export type Language = 'en' | 'nl';

export const invoiceTranslations: Record<Language, Translation> = {
  en: {
    invoice: 'INVOICE',
    invoiceNumber: 'Invoice Number',
    invoiceDate: 'Invoice Date',
    deliveryDate: 'Delivery Date',
    dueDate: 'Due Date',
    from: 'From:',
    billTo: 'Bill To:',
    description: 'Description',
    quantity: 'Quantity',
    unitPrice: 'Unit Price (€)',
    vatRate: 'VAT Rate (%)',
    amount: 'Amount',
    subtotalExVat: 'Subtotal (ex VAT)',
    vat: 'VAT',
    totalIncVat: 'Total (inc VAT)',
    paymentDetails: 'Payment Details',
    bank: 'Bank',
    paymentTerms: 'Payment Terms',
    paymentTermsDays: '30 days',
    invoiceGenerator: 'Invoice Generator',
    createProfessionalInvoices: 'Create professional, tax-compliant invoices for your Dutch business. Fields marked with * are required by Dutch law.',
    viewDutchInvoiceRules: 'View Dutch Invoice Rules',
    quickTips: 'Quick Tips',
    tipSequentialNumbers: 'Invoice numbers should be sequential and unique',
    tipVatNumbers: 'VAT numbers are required for B2B transactions',
    tipKeepCopies: 'Keep copies of all invoices for at least 7 years',
    tipSimplifiedInvoices: 'For amounts under €100, you can use simplified invoices',
    companyLogo: 'Company Logo',
    uploadLogo: 'Upload Logo',
    invoiceDetails: 'Invoice Details',
    yourCompanyDetails: 'Your Company Details',
    customerDetails: 'Customer Details',
    invoiceItems: 'Invoice Items',
    addItem: 'Add Item',
    bankDetails: 'Bank Details',
    disclaimer: 'Disclaimer',
    disclaimerText: 'This invoice generator is provided as a tool to help create invoices based on common Dutch business practices. While we strive to maintain accuracy, this is not a substitute for professional advice.',
    consultText: 'For the most up-to-date requirements, please consult with a qualified accountant or visit:',
    dutchGuide: 'Dutch Government Business Guide',
    completeGuide: 'View Our Complete Dutch Invoice Guide',
    missingFields: 'Missing Required Fields',
    downloadPDF: 'Download PDF',
    warnings: 'warnings',
    logoTooltip: 'While optional, a logo helps create professional-looking invoices',
    datesRequired: 'These dates are required by Dutch tax law',
    vatRequired: 'VAT number is required for all Dutch businesses',
    customerVatTooltip: 'VAT number is required for EU B2B transactions',
    itemsTooltip: 'Clear descriptions and correct VAT rates are required by law',
    bankTooltip: 'Including bank details helps ensure timely payment',
    kvkNumber: 'KVK Number',
    vatNumber: 'VAT Number',
    name: 'Name',
    address: 'Address',
    postcode: 'Postcode',
    city: 'City',
    country: 'Country',
    bankName: 'Bank Name',
    iban: 'IBAN',
    bicSwift: 'BIC/SWIFT',
    required: 'is required',
    invoiceNumberRequired: 'Invoice number is required',
    invoiceDateRequired: 'Invoice date is required',
    deliveryDateRequired: 'Delivery date is required',
    dueDateRequired: 'Due date is required',
    companyNameRequired: 'Your company name is required',
    companyVatRequired: 'Your VAT number is required',
    customerNameRequired: 'Customer name is required',
    itemDescriptionRequired: 'Item {0} description is required',
    itemQuantityRequired: 'Item {0} quantity must be at least 1',
    itemPriceNegative: 'Item {0} price cannot be negative',
    bankNameRequired: 'Bank name is required',
    ibanRequired: 'IBAN is required',
    quickTipsTitle: 'Quick Tips',
    requiredField: '*',
    optionalField: '',
    netherlands: 'Netherlands',
    quickTipsSection: 'Quick Tips:',
    dateFormat: 'dd/mm/yyyy',
    vatRate21: '21%',
    tipsList: [
      'Invoice numbers should be sequential and unique',
      'VAT numbers are required for B2B transactions',
      'Keep copies of all invoices for at least 7 years',
      'For amounts under €100, you can use simplified invoices'
    ],
    invoiceNumberLabel: 'Invoice Number *',
    invoiceDateLabel: 'Invoice Date *',
    deliveryDateLabel: 'Delivery Date *',
    dueDateLabel: 'Due Date *',
    descriptionLabel: 'Description *',
    quantityLabel: 'Quantity *',
    unitPriceLabel: 'Unit Price (€) *',
    vatRateLabel: 'VAT Rate (%) *',
    bankNameLabel: 'Bank Name *',
    ibanLabel: 'IBAN *',
    bicSwiftLabel: 'BIC/SWIFT',
    countryLabel: 'Country *',
    removeItem: 'Remove Item',
    generateInvoice: 'Generate Invoice',
    selectVatRate: 'Select VAT rate',
    vatRate0: '0%',
    vatRate9: '9%',
    siteName: "Invoice Generator Pro",
    metaDescription: "Free professional invoice generator for Dutch businesses. Create compliant invoices with automatic VAT calculation, multiple languages, and PDF export.",
    metaKeywords: "invoice generator, Dutch invoice, BTW invoice, business invoice, PDF invoice, free invoice generator, VAT calculator, Netherlands invoice",
    resourcesTitle: 'Related Resources',
    invoiceRulesLink: 'Invoice Rules',
    termsLink: 'Terms of Service',
    home: 'Home',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    faqWhat: 'What is the Invoice Generator?',
    faqWhatAnswer: 'The Invoice Generator is a free online tool that helps businesses create professional invoices that comply with Dutch and EU regulations. It includes all required fields and automatically calculates VAT.',
    faqHow: 'How do I use the Invoice Generator?',
    faqHowAnswer: 'Simply fill in your company details, customer information, and invoice items. The generator will automatically calculate totals and VAT. You can then download your invoice as a PDF.',
  },
  nl: {
    invoice: 'FACTUUR',
    invoiceNumber: 'Factuurnummer',
    invoiceDate: 'Factuurdatum',
    deliveryDate: 'Leverdatum',
    dueDate: 'Vervaldatum',
    from: 'Van:',
    billTo: 'Factuur voor:',
    description: 'Omschrijving',
    quantity: 'Aantal',
    unitPrice: 'Prijs (€)',
    vatRate: 'BTW tarief (%)',
    amount: 'Bedrag',
    subtotalExVat: 'Subtotaal (excl. BTW)',
    vat: 'BTW',
    totalIncVat: 'Totaal (incl. BTW)',
    paymentDetails: 'Betalingsgegevens',
    bank: 'Bank',
    paymentTerms: 'Betalingstermijn',
    paymentTermsDays: '30 dagen',
    invoiceGenerator: 'Factuur Generator',
    createProfessionalInvoices: 'Maak professionele, fiscaal conforme facturen voor uw Nederlandse bedrijf. Velden gemarkeerd met * zijn wettelijk verplicht.',
    viewDutchInvoiceRules: 'Bekijk Nederlandse Factuurregels',
    quickTips: 'Snelle Tips',
    tipSequentialNumbers: 'Factuurnummers moeten opeenvolgend en uniek zijn',
    tipVatNumbers: 'BTW-nummers zijn verplicht voor B2B-transacties',
    tipKeepCopies: 'Bewaar kopieën van alle facturen minimaal 7 jaar',
    tipSimplifiedInvoices: 'Voor bedragen onder €100 kunt u vereenvoudigde facturen gebruiken',
    companyLogo: 'Bedrijfslogo',
    uploadLogo: 'Logo Uploaden',
    invoiceDetails: 'Factuurgegevens',
    yourCompanyDetails: 'Uw Bedrijfsgegevens',
    customerDetails: 'Klantgegevens',
    invoiceItems: 'Factuuritems',
    addItem: 'Item Toevoegen',
    bankDetails: 'Bankgegevens',
    disclaimer: 'Disclaimer',
    disclaimerText: 'Deze factuur generator is een hulpmiddel voor het maken van facturen volgens Nederlandse zakelijke praktijken. Hoewel we streven naar nauwkeurigheid, is dit geen vervanging voor professioneel advies.',
    consultText: 'Raadpleeg voor de meest actuele vereisten een gekwalificeerde accountant of bezoek:',
    dutchGuide: 'Nederlandse Overheid Bedrijvengids',
    completeGuide: 'Bekijk Onze Complete Nederlandse Factuurgids',
    missingFields: 'Ontbrekende Verplichte Velden',
    downloadPDF: 'PDF Downloaden',
    warnings: 'waarschuwingen',
    logoTooltip: 'Hoewel optioneel, helpt een logo bij het creëren van professioneel ogende facturen',
    datesRequired: 'Deze datums zijn vereist volgens de Nederlandse wetgeving',
    vatRequired: 'BTW-nummer is verplicht voor alle Nederlandse bedrijven',
    customerVatTooltip: 'BTW-nummer is verplicht voor EU B2B-transacties',
    itemsTooltip: 'Duidelijke omschrijvingen en correcte BTW-tarieven zijn wettelijk verplicht',
    bankTooltip: 'Het toevoegen van bankgegevens zorgt voor tijdige betaling',
    kvkNumber: 'KVK-nummer',
    vatNumber: 'BTW-nummer',
    name: 'Naam',
    address: 'Adres',
    postcode: 'Postcode',
    city: 'Stad',
    country: 'Land',
    bankName: 'Naam Bank',
    iban: 'IBAN',
    bicSwift: 'BIC/SWIFT',
    required: 'is verplicht',
    invoiceNumberRequired: 'Factuurnummer is verplicht',
    invoiceDateRequired: 'Factuurdatum is verplicht',
    deliveryDateRequired: 'Leverdatum is verplicht',
    dueDateRequired: 'Vervaldatum is verplicht',
    companyNameRequired: 'Uw bedrijfsnaam is verplicht',
    companyVatRequired: 'Uw BTW-nummer is verplicht',
    customerNameRequired: 'Klantnaam is verplicht',
    itemDescriptionRequired: 'Item {0} omschrijving is verplicht',
    itemQuantityRequired: 'Item {0} aantal moet minimaal 1 zijn',
    itemPriceNegative: 'Item {0} prijs kan niet negatief zijn',
    bankNameRequired: 'Naam bank is verplicht',
    ibanRequired: 'IBAN is verplicht',
    quickTipsTitle: 'Snelle Tips',
    requiredField: '*',
    optionalField: '',
    netherlands: 'Nederland',
    quickTipsSection: 'Snelle Tips:',
    dateFormat: 'dd/mm/jjjj',
    vatRate21: '21%',
    tipsList: [
      'Factuurnummers moeten opeenvolgend en uniek zijn',
      'BTW-nummers zijn verplicht voor B2B-transacties',
      'Bewaar kopieën van alle facturen minimaal 7 jaar',
      'Voor bedragen onder €100 kunt u vereenvoudigde facturen gebruiken'
    ],
    invoiceNumberLabel: 'Factuurnummer *',
    invoiceDateLabel: 'Factuurdatum *',
    deliveryDateLabel: 'Leverdatum *',
    dueDateLabel: 'Vervaldatum *',
    descriptionLabel: 'Omschrijving *',
    quantityLabel: 'Aantal *',
    unitPriceLabel: 'Prijs (€) *',
    vatRateLabel: 'BTW tarief (%) *',
    bankNameLabel: 'Naam Bank *',
    ibanLabel: 'IBAN *',
    bicSwiftLabel: 'BIC/SWIFT',
    countryLabel: 'Land *',
    removeItem: 'Item Verwijderen',
    generateInvoice: 'Factuur Genereren',
    selectVatRate: 'Selecteer BTW tarief',
    vatRate0: '0%',
    vatRate9: '9%',
    siteName: "Factuur Generator Pro",
    metaDescription: "Gratis professionele factuur generator voor Nederlandse bedrijven. Maak conforme facturen met automatische BTW-berekening, meerdere talen en PDF-export.",
    metaKeywords: "factuur generator, Nederlandse factuur, BTW factuur, zakelijke factuur, PDF factuur, gratis factuur generator, BTW calculator, Nederland factuur",
    resourcesTitle: 'Related Resources',
    invoiceRulesLink: 'Invoice Rules',
    termsLink: 'Terms of Service',
    home: 'Home',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    faqWhat: 'What is the Invoice Generator?',
    faqWhatAnswer: 'The Invoice Generator is a free online tool that helps businesses create professional invoices that comply with Dutch and EU regulations. It includes all required fields and automatically calculates VAT.',
    faqHow: 'How do I use the Invoice Generator?',
    faqHowAnswer: 'Simply fill in your company details, customer information, and invoice items. The generator will automatically calculate totals and VAT. You can then download your invoice as a PDF.',
  }
}; 