import React from 'react';
import { FileText, Info, AlertCircle, CheckCircle, Euro, Building2, Receipt, Calculator } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const FactuurRegelsNL: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Gids voor Nederlandse Factuurvereisten - Hermes BI</title>
        <meta name="description" content="Uitgebreide gids voor Nederlandse factuurvereisten. Leer over wettelijke vereisten, BTW-regels en beste praktijken voor zakelijke facturering in Nederland." />
        <meta name="keywords" content="Nederlandse factuurregels, factuurvereisten Nederland, BTW-regels, BTW-vereisten, zakelijke facturering Nederland" />
        
        <meta property="og:title" content="Gids voor Nederlandse Factuurvereisten" />
        <meta property="og:description" content="Uitgebreide gids voor Nederlandse factuurvereisten" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Artikel Header */}
        <header className="pt-24 pb-12 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between mb-4">
              {/* Language switcher */}
              <Link 
                to="/invoice_processor/invoice-generator?lang=nl" 
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                <FileText className="h-4 w-4 mr-2" />
                Factuur Maken
              </Link>
              {/* Language toggle */}
              <div className="flex items-center">
                <span className="text-gray-600">Nederlands</span>
                <span className="mx-2 text-gray-400">|</span>
                <Link 
                  to="/invoice_processor/invoice-rules-nl" 
                  className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  English
                </Link>
              </div>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Hoe Maak Je een Factuur in Nederland
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              Een professionele gids voor het maken van compliant facturen die voldoen aan de Nederlandse belastingregels en zakelijke standaarden.
            </p>
          </div>
        </header>

        {/* Nieuwe geanimeerde iconen sectie direct na de header */}
        <div className="bg-white dark:bg-gray-900 py-8 border-b border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                {
                  icon: FileText,
                  text: "Compliant Facturen",
                  animation: "animate-float-1"
                },
                {
                  icon: Euro,
                  text: "Nederlandse BTW-regels",
                  animation: "animate-float-2"
                },
                {
                  icon: Building2,
                  text: "Zakelijke Standaarden",
                  animation: "animate-float-3"
                },
                {
                  icon: Receipt,
                  text: "Archivering",
                  animation: "animate-float-4"
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`flex flex-col items-center ${item.animation}`}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-400/20 rounded-full blur-xl transform scale-150 animate-pulse" />
                    <div className="relative bg-white dark:bg-gray-800 p-4 rounded-full border border-gray-200 dark:border-gray-700 shadow-lg">
                      <item.icon className="h-8 w-8 text-blue-500 dark:text-blue-400" />
                    </div>
                  </div>
                  <span className="mt-4 text-sm font-medium text-gray-600 dark:text-gray-300 text-center">
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Artikel Inhoud */}
        <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Introductie */}
          <section className="prose dark:prose-invert lg:prose-lg max-w-none mb-12">
            <p className="lead text-gray-600 dark:text-white">
              Als ondernemer in Nederland is het correct uitgeven van facturen essentieel. Of u nu goederen of diensten levert aan andere bedrijven, 
              rechtspersonen of particulieren, u moet voldoen aan de Nederlandse belastingwetgeving en factureringsstandaarden die zijn vastgesteld door de Belastingdienst 
              en de Rijksdienst voor Ondernemend Nederland (RVO).
            </p>
            <p className="text-gray-600 dark:text-white">
              Deze gids bundelt de officiële vereisten en beste praktijken zodat u elke keer professionele, compliant facturen kunt versturen.
            </p>
          </section>

          {/* Inhoudsopgave */}
          <nav className="mb-12 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Inhoud</h2>
            <ul className="space-y-2">
              <li>
                <a href="#wie-heeft-een-factuur-nodig" className="text-blue-600 dark:text-blue-400 hover:underline">
                  1. Wie Heeft een Factuur Nodig
                </a>
              </li>
              <li>
                <a href="#verplichte-velden" className="text-blue-600 dark:text-blue-400 hover:underline">
                  2. Verplichte Velden
                </a>
              </li>
              <li>
                <a href="#voorbeeld" className="text-blue-600 dark:text-blue-400 hover:underline">
                  3. Voorbeeld Factuur
                </a>
              </li>
              <li>
                <a href="#wettelijke-vereisten" className="text-blue-600 dark:text-blue-400 hover:underline">
                  4. Wettelijke Vereisten
                </a>
              </li>
              <li>
                <a href="#facturen-versturen" className="text-blue-600 dark:text-blue-400 hover:underline">
                  5. Facturen Versturen & Betalingstermijnen
                </a>
              </li>
              <li>
                <a href="#archivering" className="text-blue-600 dark:text-blue-400 hover:underline">
                  6. Bewaarplicht & Archivering
                </a>
              </li>
              <li>
                <a href="#e-facturatie" className="text-blue-600 dark:text-blue-400 hover:underline">
                  7. Elektronische & Digitale Facturatie
                </a>
              </li>
              <li>
                <a href="#beste-praktijken" className="text-blue-600 dark:text-blue-400 hover:underline">
                  8. Beste Praktijken
                </a>
              </li>
            </ul>
          </nav>

          {/* Wie Heeft een Factuur Nodig */}
          <section id="wie-heeft-een-factuur-nodig" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">1. Wie Heeft een Factuur Nodig</h2>
            <div className="space-y-6">
              <div className="prose dark:prose-invert lg:prose-lg max-w-none">
                <p className="text-gray-600 dark:text-white">
                  Volgens de Nederlandse belastingwetgeving bent u verplicht facturen uit te geven voor zakelijke transacties. Deze verplichting geldt 
                  in de volgende situaties:
                </p>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Verplichte Ontvangers
                  </h3>
                  <ul className="space-y-3">
                    {[
                      {
                        title: "Business-to-Business (B2B)",
                        description: "Alle transacties met andere ondernemers en bedrijven"
                      },
                      {
                        title: "Rechtspersonen",
                        description: "Niet-zakelijke entiteiten zoals stichtingen, verenigingen en non-profits"
                      },
                      {
                        title: "Overheidsinstanties",
                        description: "Moet als e-factuur worden verstuurd bij transacties met de Nederlandse centrale overheid"
                      },
                      {
                        title: "Particulieren (Specifieke Gevallen)",
                        description: "Verplicht voor bepaalde transacties zoals nieuwe voertuigen verkocht aan EU-klanten"
                      }
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                        <div>
                          <span className="font-medium text-gray-900 dark:text-white">{item.title}</span>
                          <p className="text-sm text-gray-600 dark:text-white">{item.description}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    Speciale Overwegingen
                  </h3>
                  <div className="space-y-4">
                    <div className="flex items-start">
                      <Info className="h-5 w-5 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">E-facturatie Vereisten</span>
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          Verplichte elektronische facturatie voor alle leveranciers van de Nederlandse overheid sinds 2017.{' '}
                          <Link 
                            to="/invoice_processor/e-facturatie-gids-nl" 
                            className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                          >
                            Lees meer over e-facturatie
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-yellow-500 mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <span className="font-medium text-gray-900 dark:text-white">Termijnvereisten</span>
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          Facturen moeten uiterlijk 15 dagen na het einde van de maand waarin de goederen/diensten zijn geleverd worden verstuurd
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Verplichte Velden Sectie */}
          <section id="verplichte-velden" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">2. Verplichte Velden</h2>
            <div className="space-y-8">
              <div className="prose dark:prose-invert lg:prose-lg max-w-none">
                <p className="text-gray-600 dark:text-white">
                  De Nederlandse belastingwetgeving schrijft specifieke informatie voor op facturen voor BTW-compliance en correcte boekhouding. 
                  Ontbrekende of onjuiste verplichte informatie kan leiden tot afgewezen BTW-aangiften of administratieve boetes.
                </p>
              </div>

              {/* Vereenvoudigde Factuurregels Waarschuwing */}
              <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-6">
                <div className="flex items-start">
                  <Info className="h-6 w-6 text-blue-500 mr-3 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Vereenvoudigde Factuurregels
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mb-3">
                      Voor facturen onder €100 (inclusief BTW) of voor factuurcorrecties zijn alleen deze gegevens vereist:
                    </p>
                    <ul className="space-y-2">
                      {[
                        "Uw naam en adres",
                        "BTW-bedrag",
                        "Factuurdatum",
                        "Omschrijving van geleverde goederen/diensten",
                        "Voor correcties: verwijzing naar de oorspronkelijke factuur"
                      ].map((item, index) => (
                        <li key={index} className="flex items-start">
                          <CheckCircle className="h-4 w-4 text-green-500 mr-2 flex-shrink-0 mt-1" />
                          <span className="text-gray-600 dark:text-gray-300 text-sm">{item}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-4 bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-800 rounded p-3">
                      <p className="text-sm text-yellow-800 dark:text-yellow-200">
                        <strong>Let op:</strong> Vereenvoudigde facturen kunnen niet worden gebruikt voor:
                        <br />• Goederen geleverd aan een ander EU-land (intracommunautaire leveringen)
                        <br />• Afstandsverkopen
                        <br />• Goederen en diensten in een EU-land met omgekeerde BTW-heffing
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Standaard Factuurvereisten */}
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {[
                  {
                    title: "Verplichte Informatie",
                    icon: FileText,
                    items: [
                      {
                        text: "Factuurnummer",
                        mandatory: true
                      },
                      {
                        text: "Factuurdatum",
                        mandatory: true
                      },
                      {
                        text: "Datum van levering",
                        mandatory: true
                      },
                      {
                        text: "Prijs per stuk/eenheid (exclusief BTW)",
                        mandatory: true
                      },
                      {
                        text: "Eventuele prijsverminderingen niet in de eenheidsprijs",
                        mandatory: true
                      },
                      {
                        text: "Toegepaste BTW-tarief",
                        mandatory: true
                      },
                      {
                        text: "Totale prijs exclusief BTW",
                        mandatory: true
                      },
                      {
                        text: "BTW-bedrag",
                        mandatory: true
                      },
                      {
                        text: "Datum van vooruitbetaling (indien anders dan factuurdatum)",
                        mandatory: false,
                        condition: "Vereist voor vooruitbetalingen"
                      }
                    ]
                  },
                  {
                    title: "Product/Dienst Details",
                    icon: FileText,
                    items: [
                      {
                        text: "Hoeveelheid en type geleverde goederen",
                        mandatory: true
                      },
                      {
                        text: "Aard en type geleverde diensten",
                        mandatory: true
                      }
                    ]
                  },
                  {
                    title: "Bedrijfsgegevens",
                    icon: Info,
                    items: [
                      {
                        text: "Naam en adres van leverancier",
                        mandatory: true
                      },
                      {
                        text: "BTW-identificatienummer van leverancier",
                        mandatory: true
                      },
                      {
                        text: "Uw KVK-nummer",
                        mandatory: true,
                        condition: "Indien geregistreerd bij KVK"
                      }
                    ]
                  },
                  {
                    title: "Klantinformatie",
                    icon: Info,
                    items: [
                      {
                        text: "Naam en adres van klant",
                        mandatory: true
                      },
                      {
                        text: "BTW-nummer van klant",
                        mandatory: false,
                        condition: "Vereist voor EU-transacties en omgekeerde BTW-heffing"
                      }
                    ]
                  },
                  {
                    title: "Speciale Gevallen",
                    icon: AlertCircle,
                    items: [
                      {
                        text: "Notatie van speciale BTW-regeling",
                        mandatory: false,
                        condition: "Bij toepassing van margeregeling, BTW-vrijstelling of omgekeerde BTW-heffing"
                      },
                      {
                        text: "Notatie van intracommunautaire levering",
                        mandatory: false,
                        condition: "Voor EU-leveringen"
                      }
                    ]
                  }
                ].map((section, index) => (
                  <div
                    key={index}
                    className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6"
                  >
                    <div className="flex items-center mb-4">
                      <section.icon className="h-5 w-5 text-blue-500 mr-2" />
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        {section.title}
                      </h3>
                    </div>
                    <ul className="space-y-3">
                      {section.items.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className="flex items-start text-sm"
                        >
                          {item.mandatory ? (
                            <span className="text-red-500 mr-2 font-bold">*</span>
                          ) : (
                            <span className="text-gray-400 mr-2">○</span>
                          )}
                          <div>
                            <span className={`${item.mandatory ? 'font-medium' : ''} text-gray-600 dark:text-gray-300`}>
                              {item.text}
                            </span>
                            {item.condition && (
                              <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                                {item.condition}
                              </p>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="text-sm text-gray-500 dark:text-gray-400">
                <p className="flex items-center">
                  <span className="text-red-500 mr-2 font-bold">*</span>
                  Verplicht veld - moet op alle standaardfacturen worden vermeld
                </p>
                <p className="flex items-center mt-1">
                  <span className="text-gray-400 mr-2">○</span>
                  Optioneel veld - aanbevolen voor professionele facturering
                </p>
              </div>
            </div>
          </section>

          {/* Voorbeeld Factuur Sectie */}
          <section id="voorbeeld" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">3. Voorbeeld Factuur</h2>
            <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6 shadow-sm">
              <div className="space-y-6">
                {/* Leveranciersgegevens */}
                <div className="border-b border-gray-200 dark:border-gray-700 pb-4">
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">ABC Bedrijf B.V.</h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    123 Zakelijkestraat<br />
                    1234 AB Amsterdam<br />
                    Nederland
                  </p>
                  <div className="mt-2 text-gray-600 dark:text-gray-300">
                    <p>KVK: 12345678</p>
                    <p>BTW: NL123456789B01</p>
                  </div>
                </div>

                {/* Factuurgegevens en Klantinfo */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h4 className="font-semibold text-gray-900 dark:text-white mb-2">Factuur Aan:</h4>
                    <p className="text-gray-600 dark:text-gray-300">XYZ GmbH</p>
                    <p className="text-gray-600 dark:text-gray-300">456 Klantenlaan</p>
                    <p className="text-gray-600 dark:text-gray-300">10115 Berlijn</p>
                    <p className="text-gray-600 dark:text-gray-300">Duitsland</p>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">BTW: DE987654321</p>
                  </div>
                  <div className="text-right">
                    <p className="text-gray-600 dark:text-gray-300">Factuur #: INV-2024-001</p>
                    <p className="text-gray-600 dark:text-gray-300">Factuurdatum: 2024-03-15</p>
                    <p className="text-gray-600 dark:text-gray-300">Leveringsdatum: 2024-03-15</p>
                    <p className="text-gray-600 dark:text-gray-300">Vervaldatum: 2024-04-14</p>
                  </div>
                </div>

                {/* Diensten/Producten Tabel */}
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th className="text-left py-2 text-gray-900 dark:text-white">Omschrijving</th>
                      <th className="text-right py-2 text-gray-900 dark:text-white">Hoeveelheid</th>
                      <th className="text-right py-2 text-gray-900 dark:text-white">Eenheidsprijs (ex BTW)</th>
                      <th className="text-right py-2 text-gray-900 dark:text-white">Bedrag (ex BTW)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 text-gray-600 dark:text-white">
                        Webontwikkeling Diensten
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        10 uur
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        €75.00
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        €750.00
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 text-gray-600 dark:text-white">
                        UI/UX Ontwerp Diensten
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        5 uur
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        €90.00
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        €450.00
                      </td>
                    </tr>
                    {/* Totalen */}
                    <tr className="border-t border-gray-200 dark:border-gray-700">
                      <td colSpan={3} className="py-2 text-right text-gray-600 dark:text-white">
                        Subtotaal (ex BTW):
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        €1,200.00
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="py-2 text-right text-gray-600 dark:text-white">
                        BTW-bedrag (21%):
                      </td>
                      <td className="text-right py-2 text-gray-600 dark:text-white">
                        €252.00
                      </td>
                    </tr>
                    <tr className="border-t border-gray-200 dark:border-gray-700 font-semibold">
                      <td colSpan={3} className="py-2 text-right text-gray-900 dark:text-white">
                        Totaalbedrag (incl BTW):
                      </td>
                      <td className="text-right py-2 text-gray-900 dark:text-white">
                        €1,452.00
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Betalingsgegevens */}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                  <h4 className="font-semibold text-gray-900 dark:text-white mb-2">Betalingsgegevens:</h4>
                  <p className="text-gray-600 dark:text-gray-300">
                    Bank: ING Bank N.V.<br />
                    IBAN: NL91 INGB 0123 4567 89<br />
                    BIC: INGBNL2A<br />
                    Referentie: INV-2024-001
                  </p>
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                    Betalingstermijn: 30 dagen<br />
                    Vervaldatum: 2024-04-14
                  </p>
                </div>

                {/* BTW Omgekeerde Heffing Notitie (voor EU B2B) */}
                <div className="text-sm text-gray-600 dark:text-gray-300 italic">
                  BTW omgekeerde heffing: Artikel 196 EU BTW-richtlijn 2006/112/EG is van toepassing
                </div>
              </div>
            </div>
          </section>

          {/* Wettelijke Vereisten Sectie */}
          <section id="wettelijke-vereisten" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">4. Wettelijke Vereisten</h2>
            <div className="prose dark:prose-invert lg:prose-lg max-w-none">
              <div className="space-y-6">
                {[
                  {
                    title: "Termijnvereisten",
                    description:
                      "Facturen moeten uiterlijk de 15e dag van de maand volgend op de maand waarin de goederen of diensten zijn geleverd worden verstuurd.",
                    icon: FileText
                  },
                  {
                    title: "Vereenvoudigde Facturen",
                    description:
                      "Als het totaalbedrag niet meer dan €100 (inclusief BTW) bedraagt, of als het een correctie is van een eerder verstuurde factuur, kunt u een vereenvoudigd formaat gebruiken. Bepaalde transacties (bijv. intracommunautaire leveringen) vereisen echter altijd een volledige factuur.",
                    icon: Info
                  },
                  {
                    title: "Bewaarplicht",
                    description:
                      "Bewaar uw facturen minimaal 7 jaar (10 jaar voor onroerend goed). U moet ze opslaan in een leesbaar formaat, identiek aan hoe ze zijn uitgegeven (papier of digitaal).",
                    icon: AlertCircle
                  }
                ].map((item, index) => (
                  <div key={index} className="flex items-start space-x-4">
                    <div className="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg">
                      <item.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                        {item.title}
                      </h3>
                      <p className="text-gray-600 dark:text-white">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mt-8">
                Aangepaste Vereisten
              </h3>
              <p className="text-gray-600 dark:text-white">
                Bepaalde sectoren, zoals taxi en openbaar vervoer of horeca, 
                kunnen aangepaste factureringsregels hebben. Daarnaast, als u deelneemt 
                aan de Kleineondernemersregeling (KOR) of vrijgesteld bent van BTW, hoeft u 
                geen BTW in rekening te brengen of op de factuur te vermelden. 
                Controleer altijd of uw sector onder specifieke regels valt.
              </p>
            </div>
          </section>

          {/* Facturen Versturen & Betalingstermijnen */}
          <section id="facturen-versturen" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              5. Facturen Versturen & Betalingstermijnen
            </h2>
            <div className="prose dark:prose-invert lg:prose-lg max-w-none space-y-6">
              <p className="text-gray-600 dark:text-white">
                Facturen moeten tijdig worden verstuurd, bij voorkeur direct na het voltooien van de dienst of levering van goederen. Volgens de Nederlandse wet 
                moet u de factuur uiterlijk de 15e dag van de maand volgend op uw levering versturen. 
              </p>
              <p className="text-gray-600 dark:text-white">
                Vermeld een duidelijke betalingstermijn op uw factuur. Gebruikelijke termijnen zijn 14 of 30 dagen. 
                Als u te lang wacht met het uitgeven van een factuur of de verjaringstermijn overschrijdt, zijn uw klanten mogelijk niet meer verplicht te betalen.
              </p>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Verjaringstermijn
              </h3>
              <ul>
                <li>
                  <strong>5 jaar</strong> na het verstrijken van de betalingstermijn (algemeen voor B2B-facturen)
                </li>
                <li>
                  <strong>2 jaar</strong> voor producten verkocht aan particulieren
                </li>
                <li>
                  <strong>5 jaar</strong> als u diensten of reisarrangementen verkoopt aan particulieren
                </li>
              </ul>
              <p className="text-gray-600 dark:text-white">
                U kunt de verjaringstermijn onderbreken (stuiten) door een aangetekende herinneringsbrief te sturen, waarmee de verjaringstermijn opnieuw start.
              </p>
            </div>
          </section>

          {/* Bewaarplicht en Archivering */}
          <section id="archivering" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              6. Bewaarplicht & Archivering
            </h2>
            <div className="prose dark:prose-invert lg:prose-lg max-w-none space-y-4">
              <p className="text-gray-600 dark:text-white">
                Uw facturen maken deel uit van uw bedrijfsadministratie en moeten in hun oorspronkelijke formaat (papier of digitaal) worden bewaard voor 
                <strong> 7 jaar</strong>. Als de facturen betrekking hebben op onroerend goed, is de bewaartermijn 
                <strong> 10 jaar</strong>. 
              </p>
              <p className="text-gray-600 dark:text-white">
                Gedurende deze periode kan de Belastingdienst uw administratie controleren. Of u nu kiest voor papieren of softwarematige 
                opslag, zorg ervoor dat uw systeem veilig, georganiseerd en compliant is met de Algemene Verordening Gegevensbescherming (AVG).
              </p>
            </div>
          </section>

          {/* Elektronische en Digitale Facturatie */}
          <section id="e-facturatie" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              7. Elektronische &amp; Digitale Facturatie
            </h2>
            <div className="prose dark:prose-invert lg:prose-lg max-w-none space-y-6">
              <p className="text-gray-600 dark:text-white">
                U kunt een factuur per post, e-mail of via elektronische (e-facturatie) kanalen versturen, zolang deze alle verplichte gegevens bevat. Echter, als u goederen of diensten levert aan de Nederlandse centrale overheid, 
                <strong> is e-facturatie verplicht</strong>.
              </p>
              <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-4 my-4">
                <p className="text-sm text-blue-800 dark:text-blue-200">
                  <Info className="h-4 w-4 inline-block mr-2" />
                  Voor een uitgebreide handleiding over e-facturatie, inclusief implementatiestappen en aankomende wijzigingen, zie onze 
                  <Link to="/invoice_processor/e-facturatie-gids-nl" className="text-blue-600 dark:text-blue-400 hover:underline ml-1">
                    E-facturatie Gids voor Nederlandse Bedrijven
                  </Link>.
                </p>
              </div>
              <p className="text-gray-600 dark:text-white">
                <strong>Digitale Factuur:</strong> Meestal een PDF die per e-mail wordt verstuurd. Deze moet dezelfde informatie en opmaak bevatten als een papieren factuur.
              </p>
              <p className="text-gray-600 dark:text-white">
                <strong>Elektronische Factuur (E-factuur):</strong> Verstuurd via een beveiligd systeem of boekhoudsoftware. 
                Dit gestructureerde formaat vult automatisch de relevante velden in, wat zorgt voor nauwkeurigheid en snelle verwerking. 
                Veel EU-lidstaten stimuleren e-facturatie vanwege de efficiëntie en betrouwbaarheid.
              </p>
            </div>
          </section>

          {/* Beste Praktijken Sectie */}
          <section id="beste-praktijken" className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">8. Beste Praktijken</h2>
            <div className="prose dark:prose-invert lg:prose-lg max-w-none">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-white">
                    Gebruik professionele opmaak met duidelijke branding en contactgegevens.
                  </span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-white">
                    Vermeld gedetailleerde omschrijvingen van geleverde goederen of diensten.
                  </span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-white">
                    Specificeer duidelijke betalingstermijnen en vervaldatums.
                  </span>
                </li>
                <li className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-1" />
                  <span className="text-gray-600 dark:text-white">
                    Gebruik facturatie- of boekhoudsoftware om compliantie en nauwkeurigheid te waarborgen.
                  </span>
                </li>
              </ul>
            </div>
          </section>

          {/* Aanvullende Bronnen */}
          <section className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Aanvullende Bronnen
            </h2>
            <p className="text-gray-600 dark:text-white mb-4">
              Voor meer gedetailleerde informatie, raadpleeg deze officiële bronnen:
            </p>
            <ul className="space-y-3">
              <li>
                <a 
                  href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontenten/belastingdienst/business/vat/vat_in_the_netherlands/vat_administration/invoice_requirements"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>Belastingdienst - Officiële Factuurvereisten</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 ml-6">
                  Volledig overzicht van BTW-factuurvereisten van de Belastingdienst
                </p>
              </li>
              <li>
                <a 
                  href="https://www.kvk.nl/starten/factuur-maken/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>KVK Gids - Factuur Maken</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 ml-6">
                  Praktische gids van de Kamer van Koophandel
                </p>
              </li>
              <li>
                <a 
                  href="https://business.gov.nl/regelgeving/factuurvereisten/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 dark:text-blue-400 hover:underline flex items-center group"
                >
                  <FileText className="h-4 w-4 mr-2 group-hover:text-blue-500" />
                  <span>Business.gov.nl - Factuurvereisten</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 ml-6">
                  Officiële overheidsinformatie voor ondernemers
                </p>
              </li>
            </ul>
            <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Laatst bijgewerkt: {new Date().toLocaleDateString()} • Informatie afkomstig van officiële Nederlandse overheidsbronnen
              </p>
            </div>
          </section>

          {/* Voeg een zwevende "Terug naar boven" knop toe */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="fixed bottom-8 right-8 bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-200"
            aria-label="Terug naar boven"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 10l7-7m0 0l7 7m-7-7v18" 
              />
            </svg>
          </button>
        </main>

        {/* Voeg een footer toe */}
        <footer className="bg-gray-50 dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <p className="text-center text-sm text-gray-500 dark:text-white">
              Deze gids is uitsluitend bedoeld voor informatieve doeleinden en mag niet worden beschouwd als juridisch advies. 
              Raadpleeg altijd een gekwalificeerde belastingadviseur of accountant voor specifieke begeleiding.
            </p>
          </div>
        </footer>

        {/* Voeg een footer toe */}
        <section className="py-16 bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">
              Klaar om Je Factuur te Maken?
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
              Gebruik onze gratis factuur generator om in enkele minuten professionele, compliant facturen te maken
            </p>
            <Link 
              to="/invoice_processor/invoice-generator?lang=nl" 
              className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors text-lg font-semibold shadow-lg"
            >
              <FileText className="h-6 w-6 mr-3" />
              Maak Nu Je Factuur
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default FactuurRegelsNL;